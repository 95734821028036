import React, { useEffect, useState, useRef } from 'react'

import './Chiclet.css'


const Chiclet = ( { portfolioData, index } ) => {


    return (
        <div
            className='chiclet-target'
        >

            <div className="chiclet-container">
                <div
                    className="chiclet"
                >
                    <div
                        className="chiclet-img-div"
                        style={ {
                            backgroundImage: `  linear-gradient(313deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.08) 59.90%, rgba(0, 0, 0, 0.00) 100%), url(${ portfolioData[ index ].roleHeaderImage })`,
                        } }
                    >

                        <div className="chiclet-title-container">
                            <div text-content={ portfolioData[ index ].roleTitle } className="chiclet-text">{ portfolioData[ index ].roleTitle }</div>
                        </div>

                    </div>
                    <div className="chiclet-desc-container">
                        <div className="desc-role">{ portfolioData[ index ].roleRole }</div>

                        <div className="desc-dates">{ portfolioData[ index ].roleTime }</div>
                    </div>
                </div>


            </div>

        </div>
    )
}

export default Chiclet

import { React, useRef } from 'react'
import SectionHeader from './SectionHeader'

import StaticCarousel from './StaticCarousel'

import './ContentSection.css'

import Carousel from './Carousel'

const ContentSection = ( { sectionData, documentScrollState, sectionPosRefArray, index, pinchBlocker } ) => {
  // console.log(sectionData.roleContent[0])

  // const contentDivRef = useRef( null )

  // sectionPosRefArray.current[ index ] = contentDivRef

  return (
    // <div className='content-section-container'>
    <div className='content-section-container' ref={ ( element ) => ( sectionPosRefArray.current[ index ] = element ) }>

      <SectionHeader sectionData={ sectionData } sectionPosRefArray={ sectionPosRefArray } index={ index } pinchBlocker={ pinchBlocker } />

      { sectionData.roleContent.map( ( _, index ) => {

        return (

          <div >

            { sectionData.roleContent[ index ].content.length <= 1 ?
              <StaticCarousel carouselContent={ sectionData.roleContent[ index ] } documentScrollState={ documentScrollState } pinchBlocker={ pinchBlocker } index={index} length={sectionData.roleContent.length}/>
              // <span></span>
              :
              <Carousel carouselContent={ sectionData.roleContent[ index ] } documentScrollState={ documentScrollState } pinchBlocker={ pinchBlocker } index={index} length={sectionData.roleContent.length}/> }

            { index !== sectionData.roleContent.length - 1 && (
              // <div className="divider"></div>
              <div className="sub-divider"></div>
              // <div className="sub-divider"></div>
              // <div></div>
            ) }

          </div>

        )
      } ) }


    


    </div>
  )
}

export default ContentSection
import React, { useEffect, useRef, useState } from 'react'

// import './font/Inter Web/inter.css'

import './App.css'

import Carousel from './components/Carousel'

//sbc
import characterdesign1 from './temp_images/sbc/characterdesign_1.jpg'
import characterdesign2 from './temp_images/sbc/characterdesign_2.jpg'
import characterdesign3 from './temp_images/sbc/characterdesign_3.jpg'

import characterdesign1Thumb from './temp_images/sbc/characterdesign_1_thumb.jpg'
import characterdesign2Thumb from './temp_images/sbc/characterdesign_2_thumb.jpg'
import characterdesign3Thumb from './temp_images/sbc/characterdesign_3_thumb.jpg'

import assets1 from './temp_images/sbc/assets_1.jpg'
import assets2 from './temp_images/sbc/assets_2.jpg'
import assets3 from './temp_images/sbc/assets_3.jpg'

import assets1Thumb from './temp_images/sbc/assets_1_thumb.jpg'
import assets2Thumb from './temp_images/sbc/assets_2_thumb.jpg'
import assets3Thumb from './temp_images/sbc/assets_3_thumb.jpg'

import ui1 from './temp_images/sbc/ui_1.jpg'
import ui2 from './temp_images/sbc/ui_2.jpg'
// import ui3 from './temp_images/sbc/ui_3.png'
import ui3 from './temp_images/sbc/ui_3.jpg'
import ui4 from './temp_images/sbc/ui_4.jpg'

import ui1Thumb from './temp_images/sbc/ui_1_thumb.jpg'
import ui2Thumb from './temp_images/sbc/ui_2_thumb.jpg'
import ui3Thumb from './temp_images/sbc/ui_3_thumb.jpg'
import ui4Thumb from './temp_images/sbc/ui_4_thumb.jpg'

//ludere
// import img1 from './temp_images/img-1'
// import img2 from './temp_images/img-2'
// import img3 from './temp_images/img-3'
// import img4 from './temp_images/img-4'
// import img5 from './temp_images/img-5'

import miscpiece1 from './temp_images/ludere/miscpieces_1.jpg'
import miscpiece2 from './temp_images/ludere/miscpieces_2.jpg'
import miscpiece3 from './temp_images/ludere/miscpieces_3.jpg'
import miscpiece4 from './temp_images/ludere/miscpieces_4.jpg'
import miscpiece5 from './temp_images/ludere/miscpieces_5.jpg'

import miscpiece1Thumb from './temp_images/ludere/miscpieces_1_thumb.jpg'
import miscpiece2Thumb from './temp_images/ludere/miscpieces_2_thumb.jpg'
import miscpiece3Thumb from './temp_images/ludere/miscpieces_3_thumb.jpg'
import miscpiece4Thumb from './temp_images/ludere/miscpieces_4_thumb.jpg'
import miscpiece5Thumb from './temp_images/ludere/miscpieces_5_thumb.jpg'

import trim1 from './temp_images/ludere/trim_1.jpg'
import trim2 from './temp_images/ludere/trim_2.jpg'
// import trim3 from './temp_images/ludere/trim_3.jpg'
import trim3 from './temp_images/ludere/trim_3.png'

import trim1Thumb from './temp_images/ludere/trim_1_thumb.jpg'
import trim2Thumb from './temp_images/ludere/trim_2_thumb.jpg'
import trim3Thumb from './temp_images/ludere/trim_3_thumb.jpg'

import ludereHeader from './temp_images/headers/ludere_header.png'
import hscHeader from './temp_images/headers/HSC_header.jpg'
import inyHeader from './temp_images/headers/INY_header.jpg'
import sbmHeader from './temp_images/headers/SBM_header.jpg'
import personalHeader from './temp_images/headers/personal_header.png'

import sbcIcon from './temp_images/section_icons/SBC_icon.png'
import ludereIcon from './temp_images/section_icons/ludere_icon.png'
import uscIcon from './temp_images/section_icons/USC_icon.png'
import chainscapeIcon from './temp_images/section_icons/chainscape_icon.png'
import personalIcon from './temp_images/section_icons/personal_icon.png'

//usc
import design1 from './temp_images/usc/design_1.jpg'
// import design2 from './temp_images/usc/design_2.jpg'
import design3 from './temp_images/usc/design_3.jpg'
// import design4 from './temp_images/usc/design_4.jpg'
// import design4 from './temp_images/usc/design_4.png'

import design1Thumb from './temp_images/usc/design_1_thumb.jpg'
import design2Thumb from './temp_images/usc/design_2_thumb.jpg'
import design3Thumb from './temp_images/usc/design_3_thumb.jpg'
import design4Thumb from './temp_images/usc/design_4_thumb.jpg'

//hsc
import firstlaunch1 from './temp_images/hsc/firstlaunch_1.jpg'
import firstlaunch2 from './temp_images/hsc/firstlaunch_2.jpg'
// import firstlaunch3 from './temp_images/hsc/firstlaunch_3.jpg'
import firstlaunch3 from './temp_images/hsc/firstlaunch_3.png'
// import firstlaunch4 from './temp_images/hsc/firstlaunch_4.jpg'

import firstlaunch1Thumb from './temp_images/hsc/firstlaunch_1_thumb.jpg'
import firstlaunch2Thumb from './temp_images/hsc/firstlaunch_2_thumb.jpg'
import firstlaunch3Thumb from './temp_images/hsc/firstlaunch_3_thumb.jpg'
import firstlaunch4Thumb from './temp_images/hsc/firstlaunch_4_thumb.jpg'
import firstlaunch5Thumb from './temp_images/hsc/firstlaunch_5_thumb.jpg'

import nft1 from './temp_images/hsc/nft2_1.jpg'
// import nft1 from './temp_images/hsc/nft2_1anim2.gif'
import nft2 from './temp_images/hsc/nft2_2.jpg'
// import nft3 from './temp_images/hsc/nft2_3.jpg'
// import nft4 from './temp_images/hsc/nft2_4.jpg'
import nftVid from './temp_images/hsc/nftcard_test.mp4'
import testVid from './assets/vidtest.mp4'

import nft1Thumb from './temp_images/hsc/nft2_1_thumb.jpg'
import nft2Thumb from './temp_images/hsc/nft2_2_thumb.jpg'
import nft3Thumb from './temp_images/hsc/nft2_3_thumb.jpg'
import nft4Thumb from './temp_images/hsc/nft2_4_thumb.jpg'

import tempThumb from './temp_images/ludere/trim_2_thumb.jpg'

import char1 from './temp_images/personal_work/char1.jpg'
import char2 from './temp_images/personal_work/char2.jpg'
import char3 from './temp_images/personal_work/char3.mp4'
import char4 from './temp_images/personal_work/char4.jpg'

import char1Thumb from './temp_images/personal_work/char1_thumb.jpg'
import char2Thumb from './temp_images/personal_work/char2_thumb.jpg'
import char3Thumb from './temp_images/personal_work/char3_thumb.jpg'
import char4Thumb from './temp_images/personal_work/char4_thumb.jpg'

import study1 from './temp_images/personal_work/study1.jpg'
import study2 from './temp_images/personal_work/study2.jpg'
import study3 from './temp_images/personal_work/study3.jpg'
import study4 from './temp_images/personal_work/study4.jpg'
import study5 from './temp_images/personal_work/study5.jpg'
import study6 from './temp_images/personal_work/study6.jpg'
import study7 from './temp_images/personal_work/study7.jpg'
import study8 from './temp_images/personal_work/study8.jpg'
import study9 from './temp_images/personal_work/study9.jpg'
import study10 from './temp_images/personal_work/study10.jpg'
import study11 from './temp_images/personal_work/study11.jpg'
import study12 from './temp_images/personal_work/study12.jpg'
import study13 from './temp_images/personal_work/study13.jpg'

import study1Thumb from './temp_images/personal_work/study1_thumb.jpg'
import study2Thumb from './temp_images/personal_work/study2_thumb.jpg'
import study3Thumb from './temp_images/personal_work/study3_thumb.jpg'
import study4Thumb from './temp_images/personal_work/study4_thumb.jpg'
import study5Thumb from './temp_images/personal_work/study5_thumb.jpg'
import study6Thumb from './temp_images/personal_work/study6_thumb.jpg'
import study7Thumb from './temp_images/personal_work/study7_thumb.jpg'
import study8Thumb from './temp_images/personal_work/study8_thumb.jpg'
import study9Thumb from './temp_images/personal_work/study9_thumb.jpg'
import study10Thumb from './temp_images/personal_work/study10_thumb.jpg'
import study11Thumb from './temp_images/personal_work/study11_thumb.jpg'
import study12Thumb from './temp_images/personal_work/study12_thumb.jpg'
import study13Thumb from './temp_images/personal_work/study13_thumb.jpg'






// import Chiclet from './components/Chiclet'

import Heading from './components/Heading'

import MainNav from './components/MainNav'

import ContentSection from './components/ContentSection'
import Chiclet from './components/Chiclet'
import ReturnArrow from './components/ReturnArrow'
import Unlocker from './components/Unlocker'
import Resume from './components/Resume'
import ContentView from './components/ContentView'

import sig from './temp_images/sig1.png'

// import launchVid from './temp_images/hsc/launchvid.mkv'
import launchVid from './temp_images/hsc/launchvid.mp4'


// const images = { img1, img2, img3, img4, img5 }

// NEXT
// ✅ return button
// fill out portfolioData
// style? (also, carousel width?)
// footer?
// resume?

// NEW
// try alt return button (pop in)
// bake down all scaling info

// const nighthareFigma = 'https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FqVPHBE0lGv3CCzmFxOJIDE%2FA9-Accomodations-App---fin%3Fpage-id%3D0%253A1%26node-id%3D204-887%26starting-point-node-id%3D204%253A887%26scaling%3Dcontain%26mode%3Ddesign%26t%3DSnPGwJnRdhiUCDaH-1&hide-ui=1'
// const nighthareFigma = 'https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FqVPHBE0lGv3CCzmFxOJIDE%2FA9-Accomodations-App---fin%3Fpage-id%3D0%253A1%26node-id%3D204-887%26starting-point-node-id%3D204%253A887%26scaling%3Dcontain%26mode%3Ddesign%26t%3DSnPGwJnRdhiUCDaH-1?device=touch'
const nighthareFigma =
	'https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2Fd3F7UgZvKhR5A4EZj1mzsC%2FA9-Accomodations-App---exp%3Fpage-id%3D0%253A1%26node-id%3D204-887%26starting-point-node-id%3D204%253A887%26scaling%3Dcontain%26mode%3Ddesign%26t%3DFEuC1QgtIALznXOb-1?device=touch&hide-ui=1'
const hscOutfitsFigma =
	'https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FVhJ2L3EudmH3ySx3PgoxZk%2FHSC---Infinity-Card----Outfits%3Fpage-id%3D102%253A2%26type%3Ddesign%26node-id%3D102-554%26viewport%3D106%252C265%252C0.03%26t%3DgqCLu7dvWzFk99ZI-1%26scaling%3Dscale-down-width%26starting-point-node-id%3D102%253A346%26mode%3Ddesign&hide-ui=1'
// const hscOutfitsFigma = 'https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FVhJ2L3EudmH3ySx3PgoxZk%2FHSC---Infinity-Card----Outfits%3Fpage-id%3D102%253A2%26type%3Ddesign%26node-id%3D102-554%26viewport%3D106%252C265%252C0.03%26t%3DgqCLu7dvWzFk99ZI-1%26scaling%3Dscale-down-width%26starting-point-node-id%3D102%253A346%26mode%3Ddesign&hide-ui=0'
// const ludereWebsiteFigma = 'https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FAsx4wHuPmZzFLXSpRRnkCo%2Fludere-website-rebuild-2%3Fpage-id%3D0%253A1%26node-id%3D28-169%26starting-point-node-id%3D27%253A52%26scaling%3Dscale-down%26mode%3Ddesign%26t%3Djf661WzUCriveugC-1'
const ludereWebsiteFigma =
	'https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FAsx4wHuPmZzFLXSpRRnkCo%2Fludere-website-rebuild-2%3Fpage-id%3D0%253A1%26node-id%3D28-169%26starting-point-node-id%3D27%253A52%26scaling%3Dscale-down-width%26mode%3Ddesign%26t%3Djf661WzUCriveugC-1&hide-ui=1'

const hscInteractionProtoFigma =
	`https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2F6sD2nZonUS75IJaPM19qaS%2Fhatswap2-altbar%3Fpage-id%3D0%253A1%26node-id%3D5-16%26starting-point-node-id%3D5%253A16%26scaling%3Dscale-down-width%26mode%3Ddesign%26t%3DhacGpf3UgseQvxwk-1&hide-ui=1`

const uscAngelinaProtoFigma =
	// `https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FN2RMyUZEzPL5mwlTytTlFO%2FAi-assistant-proto%3Fpage-id%3D0%253A1%26type%3Ddesign%26node-id%3D13-3210%26viewport%3D-2037%252C-3211%252C0.38%26t%3DjGCkjrsDwGgC28kv-1%26scaling%3Dscale-down-width%26starting-point-node-id%3D13%253A3210%26show-proto-sidebar%3D1%26mode%3Ddesign&hide-ui=1`
	`https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FN2RMyUZEzPL5mwlTytTlFO%2FAi-assistant-proto%3Ftype%3Ddesign%26node-id%3D25-5019%26t%3DMkFhfBVqon9zW9BB-1%26scaling%3Dscale-down-width%26page-id%3D0%253A1%26starting-point-node-id%3D25%253A5019%26show-proto-sidebar%3D1%26mode%3Ddesign&hide-ui=1`

	

	
const hscVimeo =
	// `www.youtube.com/embed/ObHKvS2qSp8?list=PLF8tTShmRC6uppiZ_v-Xj-E1EtR3QCTox&autoplay=1&controls=1&loop=1&mute=1`
	// `https://www.youtube.com/embed/oPBQJOpRiDk?si=y6tMYa3Sz-8RaEDU&autoplay=1&controls=1&loop=1&mute=1"`
	// `https://player.vimeo.com/video/482533185?h=02aefdbd92&autoplay=1&loop=1&color=ff9933&title=0&byline=0&portrait=0`
	// `https://player.vimeo.com/video/482533185?h=02aefdbd92&autoplay=1&loop=1&color=C6E3F7&title=0&byline=0&portrait=0`
	// `https://player.vimeo.com/video/482533185?h=02aefdbd92&autoplay=1&loop=1&color=F5F5F5&title=0&byline=0&portrait=0`
	// `https://player.vimeo.com/video/884402988?h=3232940d80&autoplay=1&loop=1&color=F5F5F5&title=0&byline=0&portrait=0`
	// `https://player.vimeo.com/video/884402988?h=3232940d80&autoplay=1&loop=1&color=C6E3F7&title=0&byline=0&portrait=0`
	// `https://player.vimeo.com/video/884402988?h=3232940d80&autoplay=1&loop=1&color=FFFFFF&title=0&byline=0&portrait=0`
	// `https://player.vimeo.com/video/900074267?h=3232940d80&autoplay=1&loop=1&color=C6E3F7&title=0&byline=0&portrait=0`
	// `https://player.vimeo.com/video/900074267?h=ecc100af36&color=FFFFFF&title=0&byline=0&portrait=0`
	`https://player.vimeo.com/video/900074267?h=ecc100af36&autoplay=1&loop=1&color=FFFFFF&title=0&byline=0&portrait=0`
// `https://www.youtube-nocookie.com/embed/lwJurL2-f0c?si=zhMA8lbFv9rJcvPA&autoplay=1&controls=1&loop=1&mute=1`

// const hscYoutube =`https://www.youtube-nocookie.com/embed/YRzxiy026GE?si=u8r9_7ropHl1wymk&autoplay=1&controls=1&loop=1&mute=1`


// `https://player.vimeo.com/video/482533185?h=02aefdbd92&autoplay=1&loop=1&color=FFFFFF&title=0&byline=0&portrait=0`
// `https://www.youtube.com/embed/3JWTaaS7LdU?&loop=1&playlist=3JWTaaS7LdU;controls=0&amp;autoplay=1&amp;mute=1&amp;start=0`

// `https://www.youtube.com/embed/tgbNymZ7vqY?autoplay=1&mute=1"`



const portfolioData = [



	{
		// roleTitle: `Hat Swap City`,
		roleTitle: `Chainscape Games`,
		roleHeaderImage: hscHeader,
		sectionIcon: chainscapeIcon,
		iconSize: '32px',
		roleRole: `art director + ux lead`,
		roleDesc:
			'led art team and worked closely with the dev team and upper management to ensure all deadlines were met',
		roleTime: `2021-2022`,
		roleContent: [
	
			{
				// sectionTitle: `NFT Clothing Collection`,
				sectionTitle: `NFT Clothing Collection`,
				sectionCategory: `Art Direction, Clothing Design`,
				sectionDesc: `collection of game-linked NFT hats and clothing`,
				content: [
					{
						payloadItem: nftVid,
						thumb: nft4Thumb,
						type: 'video',
						desc: '5 of the final NFT cards displayed in ascending rarity',
						videoHeight: '500px',
						videoWidth: '2500px',
						ratio: '.5625',
					},
					{
						payloadItem: hscOutfitsFigma,
						thumb: nft3Thumb,
						type: 'figmaSlideshow',
						desc: 'mock-up of proposed marketing content to show value through item interchangeability',
						frameHeight: '450',
						ratio: '0.3900744019',
						frameWidth: '100%',
					},
					{
						payloadItem: nft1,
						thumb: nft1Thumb,
						type: 'image',
						desc: 'progression of outfit sets -- intitial thumbnails (game view) -> sketches for NFT cards -> pixel NFT illustrations by Katty Orellana -> in game sprites by Katty Orellana',
					},
					{
						payloadItem: nft2,
						thumb: nft2Thumb,
						type: 'image',
						// desc: 'reference sheet sample -- \'Enforcer Helmet\'',
						// desc: 'reference sheet sample -- Enforcer Helmet',
						desc: 'reference sheet sample (Enforcer Helmet)',
					},
					// { payloadItem: testVid, thumb: tempThumb, type: 'video', desc: 'description here 4' },
				],
			},


			{
				sectionTitle: `First Launch`,
				sectionCategory: `Art Direction, UX/UI`,
				// sectionDesc: `elements contributing to the initial launch of Hat Swap City`,
				sectionDesc: [
					[ '', `elements contributing to the initial launch of ` ],
					[ 'italic', `Hat Swap City` ],
				],
				content: [
					{
						payloadItem: hscVimeo,
						thumb: firstlaunch4Thumb,
						type: 'youtubeEmbed',
						// desc: 'video of intial launch product, NOTE: having the characters be naked was not my decision',
						desc: 'video of intial launch product running in a browser -- note: having naked characters was not my decision',
						ratio: '0.5625'
						// videoHeight: '1920px',
						// videoWidth: '1080px',
					},
					// {
					// 	payloadItem: hscYoutube,
					// 	thumb: firstlaunch4Thumb,
					// 	type: 'youtubeEmbed',
					// 	desc: 'video of intial launch product, NOTE: having the characters be naked was not my decision',
					// 	ratio: '0.5625'
					// 	// videoHeight: '1920px',
					// 	// videoWidth: '1080px',
					// },
					// {
					// 	payloadItem: launchVid,
					// 	thumb: nft4Thumb,
					// 	type: 'video',
					// 	desc: 'video of intial launch product, NOTE: having the characters be naked was not my decision',
					// 	videoHeight: '1920px',
					// 	videoWidth: '1080px',
					// },
					// {
					// 	payloadItem: firstlaunch4,
					// 	thumb: firstlaunch4Thumb,
					// 	type: 'image',
					// 	desc: 'video of initial launch product',
					// },
					{
						payloadItem: firstlaunch2,
						thumb: firstlaunch2Thumb,
						type: 'image',
						desc: 'progress paintover for plaza',
					},
					{
						payloadItem: firstlaunch1,
						thumb: firstlaunch1Thumb,
						type: 'image',
						desc: 'original sketch of plaza',
					},
					{
						payloadItem: firstlaunch3,
						thumb: firstlaunch3Thumb,
						type: 'image',
						desc: 'early ideation for player character and NFT gear',
					},
					// {
					// 	payloadItem: hscInteractionProtoFigma,
					// 	thumb: firstlaunch5Thumb,
					// 	type: 'figmaInteractive',
					// 	desc: 'original mock-up of conversation flow',
					// 	frameHeight: '800',
					// 	ratio: '0.7551467367',
					// 	frameWidth: '100%',

					// },
				],
			}, 


		],
	},


	{
		roleTitle: `MS @ USC Iovine & Young Academy`,
		roleHeaderImage: inyHeader,
		sectionIcon: uscIcon,
		iconSize: '42px',
		roleRole: `graduate`,
		roleDesc:
			'worked in multi-disciplinary teams to develop design, business, and teamwork skills ',
		roleTime: `2020-2021`,
		roleContent: [
			{
				// sectionTitle: `Misc. Work`,
				sectionTitle: `Student Work`,
				sectionCategory: `UI/UX, Product Design`,
				// sectionDesc: `assorted student work done in the Iovine & Young program`,
				sectionDesc: `excerpts from projects created in the Iovine & Young program`,
				content: [

					{
						payloadItem: design3,
						thumb: design3Thumb,
						type: 'image',
						desc: 'advert (and UI) mock-up for UX class',
					},
					{
						payloadItem: nighthareFigma,
						thumb: design2Thumb,
						type: 'figmaInteractive',
						desc: 'Figma mock-up of an imaginary transportation app for UX class -- my idea was mixing LA Metro/Greyhound with European sleeper trains',
						desc: [
							[ '', `Figma mock-up of an imaginary transportation app for UX class -- my idea was` ],
							[ 'italic', `NightHare, ` ],
							[ '', `which mixed LA Metro/Greyhound with European sleeper trains` ],

						],
						frameWidth: '50%',
						frameHeight: '900px',
						ratio: '1.778588808',
					},

					{
						payloadItem: design1,
						thumb: design1Thumb,
						type: 'image',
						desc: 'advert (and UI) mock-up for UX class',
					},
					// {
					// 	payloadItem: design4,
					// 	thumb: design4Thumb,
					// 	type: 'image',
					// 	desc: 'Angelina one sheet and website designs -- proposal to the city of LA for an initiative to support small businesses (impacted by COVID and into the future) -- proposal was well recieved and we were asked if we could build it for them but our team did not have the technical skills',
					// },
					{
						payloadItem: uscAngelinaProtoFigma,
						thumb: design4Thumb,
						type: 'figmaInteractive',
						// desc: 'proposal to the city of LA for an initiative to support small businesses (impacted by COVID and into the future) -- pitch was well recieved and we were asked if we could build it for them but our team did not have the technical skills',
						// desc: 'proposal to the city of LA for an initiative to support small businesses impacted by COVID and into the future, pitch was well recieved',
						// desc: 'proposal to the city of LA for an initiative to support small businesses impacted by COVID and into the future -- our pitch was Angelina, an AI powered dashboard that would allow Los Angeles based busineses to easily see what resources were avialble to them, the pitch was well recieved',
						// desc: 'AI benefits assistant proposal to the city of LA for an initiative to support small businesses impacted by COVID and into the future, pitch was well recieved',
						// desc: 'mock-ups from proposal for an AI benefits assistant to address initiative by the city of LA that looked to support small businesses impacted by COVID and into the future, pitch was well recieved',
						// desc: 'mock-ups from proposal for an AI benefits assistant to address tasked initiative by the city of LA that looked to support small businesses impacted by COVID and into the future, pitch was well recieved',
						// desc: 'mock-ups of AI benefits assistant "Angelina," proposed the city of LA to address their initiative that looked to support small businesses impacted by COVID and into the future, pitch was well recieved',
						desc: [
							// [ '', `mock-ups of AI benefits system` ],
							[ '', `mock-ups of` ],
							[ 'italic', `Angelina,` ],
							[ '', `an AI benefits system` ],
							// [ '', `proposed the city of LA to address their initiative which looked to support small businesses impacted by COVID and into the future, pitch was well recieved` ],
							[ '', `proposed to the city of LA for their initiative to support small businesses impacted by COVID and into the future -- pitch was well received` ],
						],
						// proposal for an AI benefits assistant to address initiative by the city of LA that looked to support small businesses impacted by COVID and into the future, pitch was well recieved
						// frameWidth: '100%',
						// frameHeight: '960px',
						frameHeight: '1020px',
						// ratio: '0.572',
						// ratio: '0.6067816776',
						ratio: '0.6067816776',
					},

					// In what way can the city of LA best support small businesses who have been impacted by the COVID-era, or best prepare for future challenges?
					// { payloadItem: design1, thumb: design1Thumb, type: 'image', desc: 'desc' },
				],
			},
		],
	},



	
	{
		roleTitle: `Ludere Clothing`,
		roleHeaderImage: ludereHeader,
		sectionIcon: ludereIcon,
		iconSize: '36px',
		roleRole: `co-founder`,
		roleDesc: 'oversaw design process and production',
		roleTime: `2016-2020`,
		roleContent: [
			{
				sectionTitle: `Trim`,
				sectionCategory: `Design`,
				sectionDesc: `development of trim package for Ludere`,
				content: [
					{
						payloadItem: trim2,
						thumb: trim2Thumb,
						type: 'image',
						desc: 'our vector designs -> production run with Zabin Group',
					},
					{
						payloadItem: trim1,
						thumb: trim1Thumb,
						type: 'image',
						desc: 'early sketches and ideation for trim',
					},
					// { payloadItem: trim3, thumb: trim3Thumb, type: 'image', desc: 'labels' },
					{
						payloadItem: trim3,
						thumb: trim3Thumb,
						type: 'image',
						// desc: 'label designs for would-be debut collection (due to factors beyond our control, releasing a collection based on the idea of going out to an intimate nightclub in LA for spring 2020 proved to be untenable)',
						// desc: 'label designs for what was to be our debut collection in spring 2020, we had to cancel the launch when the pandemic made it untenable',
						// desc: 'label designs for our spring 2020 debut collection, the pandemic ultimately made the launch untenable',
						// desc: 'label designs for our debut collection slated for spring 2020, the pandemic ultimately made the launch untenable',
						desc: 'label designs for our debut collection slated for spring 2020 -- the pandemic ultimately made the launch untenable',
					},
				],
			},
			{
				sectionTitle: `Sketches`,
				sectionCategory: `Clothing Design`,
				sectionDesc: `assorted design sketches for Ludere`,
				content: [
					{
						payloadItem: miscpiece1,
						thumb: miscpiece1Thumb,
						type: 'image',
						desc: 'Concepts sketch and Photoshop render for a belted jacket',
					},
					{
						payloadItem: miscpiece2,
						thumb: miscpiece2Thumb,
						type: 'image',
						desc: 'Photoshop render for a leather jacket, 2 colorways',
					},
					{
						payloadItem: miscpiece3,
						thumb: miscpiece3Thumb,
						type: 'image',
						desc: 'Concepts and Procreate sketches (iPad)',
					},
					{
						payloadItem: miscpiece4,
						thumb: miscpiece4Thumb,
						type: 'image',
						desc: 'Photoshop images',
					},
					{
						payloadItem: miscpiece5,
						thumb: miscpiece5Thumb,
						type: 'image',
						desc: 'rough digital sketches',
					},
				],
			},
			{
				sectionTitle: `Ludere Website Design`,
				sectionCategory: `UI/UX`,
				sectionDesc: `Figma prototype for Ludere website`,
				content: [
					// { payloadItem: ludereWebsiteFigma, thumb: tempThumb, type: 'figmaInteractive', desc: 'make this a dynamic value to match the ratio + fix fonts', frameWidth:'100%', ratio:'.751'},
					{
						payloadItem: ludereWebsiteFigma,
						thumb: tempThumb,
						type: 'figmaInteractive',
						// desc: 'note: product and collection images are mainly gathered/temporary -- which is of course standard but ironic here because we were also making the product',
						desc: 'note: images are temp',
						frameWidth: '100',
						ratio: '.751',
						// ratio: '.75',
					},
				],
			},
		],
	},


	{
		roleTitle: `Silicon Beach Media`,
		roleHeaderImage: sbmHeader,
		sectionIcon: sbcIcon,
		iconSize: '36px',
		roleRole: `artist`,
		roleDesc: 'illustrated game assets and created UX/UI',
		roleTime: `2013-2014`,
		roleContent: [
			{
				sectionTitle: `Sports Aliens`,
				sectionCategory: `Character Design`,
				// sectionDesc: `football and tennis alien designs for game '''Space Sports'''`,
				sectionDesc: [
					[ '', `football and tennis alien designs for` ],
					[ 'italic', `Space Sports` ],
					,
					[ '', `game` ],
				],
				content: [
					{
						payloadItem: characterdesign1,
						thumb: characterdesign1Thumb,
						type: 'image',
						desc: 'three-quarter views',
					},
					{
						payloadItem: characterdesign2,
						thumb: characterdesign2Thumb,
						type: 'image',
						desc: 'orthographic modeling sheets',
					},
					{
						payloadItem: characterdesign3,
						thumb: characterdesign3Thumb,
						type: 'image',
						desc: 'color variations',
					},
				],
			},
			{
				sectionTitle: `Game Assets`,
				sectionCategory: `Illustration`,
				// sectionDesc: `pre-fab rooms and props for game 'Noah's Ark'`,
				sectionDesc: [
					[ '', `pre-fab rooms and props for` ],
					[ 'italic', `Noah's Ark ` ],
					[ '', `game` ],
				],
				content: [
					{
						payloadItem: assets1,
						thumb: assets1Thumb,
						type: 'image',
						// desc: `top: standard 1-unit 'farm' room, bottom: luxury 4-unit 'beach' room (scaled down here to match the single unit room) `,
						desc: `top: standard 1-unit 'farm' room, bottom: special 4-unit 'beach' room (standard 1-unit 'farm' room scaled up here to match the size of the special 4-unit room) `,
					},
					{
						payloadItem: assets2,
						thumb: assets2Thumb,
						type: 'image',
						desc: 'mock-up of decoration assets placed in rooms',
					},
					{
						payloadItem: assets3,
						thumb: assets3Thumb,
						type: 'image',
						desc: 'collection of decoration assets',
					},
				],
			},
			{
				sectionTitle: `Misc. UI`,
				sectionCategory: `UX/UI`,
				sectionDesc: `UI screens and elements created across multiple properties`,
				content: [
					// { payloadItem: ui1, thumb: ui1Thumb, type: 'image', desc: 'top: grid of 4 Ballbuster screens, bottom: miscellaneous elements ' },
					{
						payloadItem: ui1,
						thumb: ui1Thumb,
						type: 'image',
						desc: [
							[ '', `grid of 4` ],
							[ 'italic', `Ballbuster ` ],
							[ '', `screens with some` ],
							[ 'italic', `Ballbuster ` ],
							[ '', `UI elements along the bottom` ],
						],
						// desc: [
						// 	[ '', `grid of 4` ],
						// 	[ 'italic', `Ballbuster ` ],
						// 	[ '', `screens, bottom: miscellaneous` ],
						// 	[ 'italic', `Ballbuster ` ],
						// 	[ '', `UI elements` ],
						// ],
					},
					// { payloadItem: ui2, thumb: ui2Thumb, type: 'image', desc: `3 screens for Noah's Ark` },
					{
						payloadItem: ui2,
						thumb: ui2Thumb,
						type: 'image',
						desc: [
							[ '', `3 screens for` ],
							[ 'italic', `Noah's Ark` ],
						],
					},
					{
						payloadItem: ui3,
						thumb: ui3Thumb,
						type: 'image',
						// desc: `miscellaneous UI elements and screens from the development of Noah's Ark`
						desc: [
							[
								'',
								`miscellaneous UI elements and screens from the development of`,
							],
							[ 'italic', `Noah's Ark` ],
						],
					},
					{
						payloadItem: ui4,
						thumb: ui4Thumb,
						type: 'image',
						desc: 'visual direction proposal for Biblical-themed match-3 game',
					},
				],
			},
		],
	},




	// need to update carousel view if i want to use a figma file first

	{
		roleTitle: `Personal Work`,
		roleHeaderImage: personalHeader,
		sectionIcon: personalIcon,
		iconSize: '36px',
		// roleDesc:'co-founded, worked with contractors, created and executed designs',
		// roleRole: `student`,
		// roleTime: `1993-Present`,
		roleTime: `b. 1993`,
		// roleTime: `1993 - `,
		roleContent: [
			{
				sectionTitle: `Characters`,
				// sectionCategory: ``,
				// sectionDesc: `2012-2013`,
				// sectionDesc: `misc`,
				content: [
					{
						payloadItem: char1,
						thumb: char1Thumb,
						type: 'image',
						desc: '2012, Photoshop',
					},
					{
						payloadItem: char2,
						thumb: char2Thumb,
						type: 'image',
						desc: 'gargolye - 2012, Zbrush',
					},
					{
						payloadItem: char3,
						thumb: char3Thumb,
						type: 'video',
						desc: 'gargolye turntable - 2012, Zbrush',
						videoHeight: '1232px',
						videoWidth: '1664px',
					},
					{
						payloadItem: char4,
						thumb: char4Thumb,
						type: 'image',
						desc: 'alien - 2013, Zbrush',
					},
				],
			},


			{
				sectionTitle: `Environmental Studies`,
				// sectionTitle: `Environments`,
				// sectionCategory: `Photoshop`,
				// sectionCategory: `Studies`,
				sectionCategory: `Digital Painting`,
				sectionDesc: `c. 2016`,
				// sectionDesc: `Environment Studies c. 2016`,
				content: [


					{
						payloadItem: study1,
						thumb: study1Thumb,
						type: 'image',
						desc: '  ',
					},
					{
						payloadItem: study2,
						thumb: study2Thumb,
						type: 'image',
						desc: '  ',
					},
					{
						payloadItem: study3,
						thumb: study3Thumb,
						type: 'image',
						desc: '  ',
					},
					{
						payloadItem: study4,
						thumb: study4Thumb,
						type: 'image',
						desc: '  ',
					},
					{
						payloadItem: study5,
						thumb: study5Thumb,
						type: 'image',
						desc: '  ',
					},
					{
						payloadItem: study6,
						thumb: study6Thumb,
						type: 'image',
						desc: '  ',
					},
					{
						payloadItem: study8,
						thumb: study8Thumb,
						type: 'image',
						desc: '  ',
					},







					// { payloadItem: testVid, thumb: tempThumb, type: 'video', desc: 'description here 4' },
				],
			},



			{
				sectionTitle: `Figurative Studies`,
				sectionCategory: `Digital Painting`,
				// sectionCategory: ``,
				sectionDesc: `c. 2016`,
				content: [



					{
						payloadItem: study7,
						thumb: study7Thumb,
						type: 'image',
						desc: '  ',
					},
					{
						payloadItem: study9,
						thumb: study9Thumb,
						type: 'image',
						desc: '  ',
					},
					{
						payloadItem: study10,
						thumb: study10Thumb,
						type: 'image',
						desc: '  ',
					},
					{
						payloadItem: study11,
						thumb: study11Thumb,
						type: 'image',
						desc: '  ',
					},
					{
						payloadItem: study12,
						thumb: study12Thumb,
						type: 'image',
						desc: '  ',
					},
					{
						payloadItem: study13,
						thumb: study13Thumb,
						type: 'image',
						desc: '					',
					},






					// { payloadItem: testVid, thumb: tempThumb, type: 'video', desc: 'description here 4' },
				],
			},




		],
	},
]

// const section = {
// 	sectionTitle: `Misc. Sketches`,
// 	sectionCategory: `Clothing Design`,
// 	sectionDesc: `assorted designs for Ludere`,
// 	content: [

// 		{payloadItem: img1, thumb: tempThumb, type: 'image', desc: 'these were early sketches and ideation for different trim pieces' },
// 		{payloadItem: img2, thumb: tempThumb, type: 'image', desc: 'description here 2' },
// 		{payloadItem: img3, thumb: tempThumb, type: 'image', desc: 'description here 3' },
// 		{payloadItem: img4, thumb: tempThumb, type: 'image', desc: 'description here 4' },
// 		{payloadItem: img5, thumb: tempThumb, type: 'image', desc: 'description here 5' },
// 	]
// }

// handle touch end but also touch cancel?

function App() {
	const appDivRef = useRef( null )

	const [ resumeMode, setResumeMode ] = useState( false )
	const [ lockMode, setLockMode ] = useState( true )
	// const [ lockMode, setLockMode ] = useState( false )

	const [ bgColor, setBgColor ] = useState( 'white' )
	const [ resumeOpacity, setResumeOpacity ] = useState( 0 )
	const [ contentOpacity, setContentOpacity ] = useState( 1 )


	const pinchBlocker = useRef( false )


	// const VIEW_TOGGLE_ANIM_TIME = 650
	// const VIEW_TOGGLE_ANIM_TIME_OUT = 350
	// const VIEW_TOGGLE_ANIM_TIME = 350
	// const VIEW_TOGGLE_ANIM_TIME_OUT = 350
	// const VIEW_TOGGLE_ANIM_TIME = 300
	// const VIEW_TOGGLE_ANIM_TIME_OUT = 300
	// const VIEW_TOGGLE_ANIM_TIME = 2500
	// const VIEW_TOGGLE_ANIM_TIME_OUT = 2500
	const VIEW_TOGGLE_ANIM_TIME = 250
	const VIEW_TOGGLE_ANIM_TIME_OUT = 250

	// const [ headerTextFading, setHeaderTextFading ] = useState( false )
	const [ headerTextFading, setHeaderTextFading ] = useState( true)


	// const VIEW_TOGGLE_ANIM_TIME = 1000
	// const VIEW_TOGGLE_ANIM_TIME_OUT = 1000

	// const [resumeModeViewToggle, setResumeModeViewToggle] = useState(false)
	// useEffect(() => {
	// 	setResumeModeViewToggle(!resumeMode)

	// }, [resumeMode])

	const [ resumeModeViewToggle, setResumeModeViewToggle ] = useState( false )

	const [ showContent, setShowContent ] = useState( false )
	const [ showResume, setShowResume ] = useState( true )
	const timeoutRef = React.useRef( null )

	const [ fadeInContent, setFadeInContent ] = useState( false )
	const [ fadeInResume, setFadeInResume ] = useState( true )

	const [ compenstationTest, setCompenstationTest ] = useState( false )




	useEffect(() => {
		const searchParams = new URLSearchParams(window.location.search);
		const suppliedPassword = searchParams.get('password');
	
		if (suppliedPassword) {
		//   setPassword(suppliedPassword);
		if (suppliedPassword == 'tapir') {
			setLockMode(false)
		}
		}
	  }, []);


	useEffect( () => {
		// document.body.style.transition = 'background-color 0.3s'; // Adjust transition time here
		document.body.style.transition = `background-color ${ 2 * ( VIEW_TOGGLE_ANIM_TIME + VIEW_TOGGLE_ANIM_TIME_OUT ) }ms`
		document.body.style.backgroundColor = bgColor
	}, [ bgColor ] )

	useEffect( () => {

		if ( timeoutRef.current ) {
			clearTimeout( timeoutRef.current )
		}

		// setResumeModeViewToggle(!resumeMode)
		if ( resumeMode ) {
			// setHeaderTextFading(true)
			// setBgColor( 'white' )
			// setBgColor('#7D7D7D')
			setCompenstationTest( false )
			// setBgColor('lightgrey')
			// setBgColor( '#D6D6D6' )
			// setBgColor( '#DDDDDD' )
			// setBgColor( '#DFDFDF' )
			// setBgColor( '#d2d2d2' ) // 210
			setBgColor( ' hsl(0, 0%, 92%)' ) // ground color
			// setBgColor( ' hsl(0, 0%, 84%)' ) // - 8
			// setBgColor( ' hsl(0, 0%, 76%)' ) // - 8 * 2
			// setBgColor( ' hsl(0, 0%, 68%)' ) // - 8 * 3  -- too dark
			
			setBgColor( ' hsl(0, 0%, 80%)' ) // halfway? -- good1

			// setBgColor( ' hsl(0, 0%, 81%)' ) // halfway?
			// setBgColor( ' hsl(0, 0%, 80.5%)' ) // halfway?
			// setBgColor( ' hsl(0, 0%, 81%)' ) // halfway?
			// setBgColor( ' hsl(0, 0%, 82%)' ) // halfway + 2
			// setBgColor( ' hsl(0, 0%, 83%)' ) // halfway + 3 -- good 2?
			// setBgColor( ' hsl(0, 0%, 78%)' ) // halfway -2 too dark?
			
			// setBgColor( ' hsl(0, 0%, 85%)' ) // above bounds
			// setBgColor( ' hsl(0, 0%, 75%)' ) // below bounds
			// setBgColor( ' hsl(0, 0%, 70%)' ) // below bounds
			
			// setBgColor( ' hsl(0, 0%, 78%)' ) // color find 1
			// setBgColor( ' hsl(0, 0%, 73%)' ) // color find 1
			// setBgColor( ' hsl(0, 0%, 74%)' ) // color find n - pretty good !
			// setBgColor( ' hsl(0, 0%, 75%)' ) // color find 1 -- also pretty good.. better?
			// setBgColor( ' hsl(0, 0%, 75%)' ) // both 75 or both 7
			
			setBgColor( ' hsl(0, 0%, 78%)' ) // figma determination 5/22/24 after button change  -- I like it -- halfway 56-100
			// setBgColor( ' hsl(0, 0%, 77%)' ) // fiddling meh -- 77 ALSO GOOD

			
			// setBgColor( ' hsl(0, 0%, 74%)' ) // fiddling -- not bad this was the straight one
			// setBgColor( ' hsl(0, 0%, 75%)' ) // orig
			
			// setBgColor( ' hsl(0, 0%, 79%)' ) // fiddling meh 2  --- was left on this one?
			
			// setBgColor( ' hsl(0, 0%, 66%)' ) // figma determination 5/22/24 after button change -- ALT
			
			// setBgColor( ' hsl(0, 0%, 56%)' ) // color find 3 (somewhere 75 to 80?)
			// setBgColor( ' hsl(0, 0%, 69%)' ) // color find 3 (somewhere 75 to 80?)
			// setBgColor( ' hsl(0, 0%, 76%)' ) // color find 3 (somewhere 75 to 80?) ---- nice

			// setBgColor( ' hsl(0, 0%, 76%)' ) // color find 3 (somewhere 75 to 80?)
			// setBgColor( ' hsl(0, 0%, 76%)' ) // color find 3 (somewhere 75 to 80?)
			// setBgColor( ' hsl(0, 0%, 80%)' ) // color find 3 (somewhere 75 to 80?)
			// setBgColor( `#1E1E1E` ) // ORIGINAL FROM FIGMA DOC
			
			// setBgColor( ' hsl(0, 0%, 0%)' ) // color find 3 (somewhere 75 to 80?)


			// setBgColor( ' hsl(0, 0%, 50%)' )
			// setBgColor( ' hsl(0, 0%, 46%)' )
			// setBgColor( ' hsl(0, 0%, 35%)' )
			// setBgColor( '#E3E3E3' )
			// setBgColor( '#DDDDDD' )
			// setBgColor( '#BED2D9' )  // interesting blue
			// setBgColor( ' hsl(0, 0%, 70%)' )
			// setBgColor( ' hsl(0, 0%, 87%)' )
			// setBgColor( ' hsl(0, 0%, 61%)' ) // main grey
			// setBgColor( ' hsl(0, 0%, 80%)' ) // halfway main grey to white
			// setBgColor( ' hsl(0, 0%, 30%)' ) // halfway main grey to black



			// setBgColor( ' hsl(0, 0%, 8%)' )
			// setBgColor( ' hsl(0, 0%, 16%)' )
			// setBgColor( ' hsl(0, 0%, 24%)' )
			// setBgColor( ' hsl(0, 0%, 32%)' )
			// setBgColor( ' hsl(0, 0%, 40%)' )
			// setBgColor( ' hsl(0, 0%, 48%)' )
			// setBgColor( ' hsl(0, 0%, 50%)' )
			// setBgColor( ' hsl(0, 0%, 56%)' ) // light halfway??
			// setBgColor( ' hsl(0, 0%, 64%)' )
			// setBgColor( ' hsl(0, 0%, 72%)' )
			// setBgColor( ' hsl(0, 0%, 80%)' )
			// setBgColor( ' hsl(0, 0%, 88%)' ) // too bright
 

			// rando
			// rando
			// rando
			// setBgColor( ' hsl(0, 0%, 40%)' )
			// setBgColor( ' hsl(0, 0%, 41%)' )
			// setBgColor( ' hsl(0, 0%, 37.5%)' )
			// setBgColor( ' hsl(0, 0%, 50%)' )



			// setBgColor( ' hsl(0, 0%, 0%)' )
			// setBgColor( '#1E1E1E' )  // from figma doc?
			setShowResume( true )
			setContentOpacity( 0 )
			setFadeInResume( true )


			
			// setResumeOpacity( 1 )

			// setResumeModeViewToggle( resumeMode )
			// setTimeout( () => {
			timeoutRef.current = setTimeout( () => {
				// 	setResumeModeViewToggle( resumeMode )
				// 	// setResumeOpacity(1)
				setShowContent( false )
				setFadeInResume( false )
				setCompenstationTest( true )

				// setHeaderTextFading(false)


				setTimeout(() => {
					setHeaderTextFading(false)
					
				}, (VIEW_TOGGLE_ANIM_TIME_OUT*2));

			}, VIEW_TOGGLE_ANIM_TIME_OUT )
		}

		if ( !resumeMode ) {
			// setHeaderTextFading(true)
			setBgColor( 'white' )
			// setBgColor( ' hsl(0, 0%, 37.5%)' )
			// setBgColor( ' hsl(0, 0%, 0%)' )

			// setBgColor( '#3C3C3C' )
			// setShowResume( false )
			setCompenstationTest( false )
			setShowContent( true )
			setResumeOpacity( 0 )
			setFadeInContent( true )




			
			// setBgColor( '#e7e7e7' )
			// setBgColor( ' hsl(0, 0%, 80%)' )
			// setContentOpacity( 1 )


			// setBgColor('#E3E3E3')
			// setResumeOpacity( 0 )
			// setTimeout( () => {
			timeoutRef.current = setTimeout( () => {
				setShowResume( false )
				setFadeInContent( false )
				// 	setResumeModeViewToggle( resumeMode )
				// 	// setContentOpacity(1)
				
				setTimeout(() => {
					setHeaderTextFading(false)
					
				}, (VIEW_TOGGLE_ANIM_TIME_OUT*2));



			}, VIEW_TOGGLE_ANIM_TIME_OUT )
		}

		return () => {
			if ( timeoutRef.current ) {
				clearTimeout( timeoutRef.current )
			}
		}

	}, [ resumeMode ] )

	const sectionPosRefArray = useRef( [] )

	const [ documentScrollState, setDocumentScrollState ] = useState( false )

	// const scrollToSection = ( index ) => {
	// 	// console.log(`section pos ref array ->${sectionPosRefArray.current[0]}`)
	// 	sectionPosRefArray.current[ index ].scrollIntoView( {
	// 		behavior: 'smooth',
	// 	} )
	// }

	// function globalExListener(event) {
	// console.log( 'global' )
	// console.log(event.pageX)

	// ref={ pageExTest }
	// onMouseMove={ ( event ) => globalListener( event ) }



	// useEffect(() => {
	// 	// Function to handle touch start events
	// 	const handleTouchStart = (event) => {
	// 		if ( event.touches ) {

	// 			if ( event.touches.length > 1 ) {
	// 				pinchBlocker.current = true
	// 				// console.log(`----------> INTECEPTED`)
	// 				// return
	// 			}


	// 		}


	// 	};

	// 	// Function to handle touch end events
	// 	const handleTouchEnd = (event) => {

	// 		if ( pinchBlocker.current == true ) {
	// 			// pinchBlocker.current = false

	// 			setTimeout( () => {

	// 				pinchBlocker.current = false
	// 			}, 1 )
	// 			// pinchBlocker.current = false
	// 			return
	// 		}



	// 	};

	// 	// Add touch event listeners when the component mounts
	// 	document.addEventListener('touchstart', handleTouchStart);
	// 	document.addEventListener('touchend', handleTouchEnd);

	// 	// Clean up by removing the event listeners when the component unmounts
	// 	return () => {
	// 	  document.removeEventListener('touchstart', handleTouchStart);
	// 	  document.removeEventListener('touchend', handleTouchEnd);
	// 	};
	//   }, []); // Empty dependency array ensures this effect runs only once



	return (
		<div className="return-arrow-grid">
			<div className="outer">
				<div
					className="App"
					ref={ appDivRef }
					style={ {
						backgroundColor: bgColor,
						// transition: `background-color ${ 3 * ( VIEW_TOGGLE_ANIM_TIME + VIEW_TOGGLE_ANIM_TIME_OUT ) }ms`,
						transition: `background-color ${ 2 * ( VIEW_TOGGLE_ANIM_TIME + VIEW_TOGGLE_ANIM_TIME_OUT ) }ms`,
					} }
				>
					{/* <div className="main-container"> */ }

					<Heading
						resumeMode={ resumeMode }
						setResumeMode={ setResumeMode }
						lockMode={ lockMode }
						contentOpacity={ contentOpacity }
						resumeModeViewToggle={ resumeModeViewToggle }
						VIEW_TOGGLE_ANIM_TIME={ VIEW_TOGGLE_ANIM_TIME }
						headerTextFading={headerTextFading}
						setHeaderTextFading={setHeaderTextFading}
					/>


					{ showResume && <div>
						<Resume
							resumeMode={ resumeMode }
							resumeOpacity={ resumeOpacity }
							setResumeOpacity={ setResumeOpacity }
							VIEW_TOGGLE_ANIM_TIME={ VIEW_TOGGLE_ANIM_TIME }
							VIEW_TOGGLE_ANIM_TIME_OUT={ VIEW_TOGGLE_ANIM_TIME_OUT }
							fadeInResume={ fadeInResume }
							compensationTest={ compenstationTest }
							setResumeMode={ setResumeMode }
						/>
					</div> }

					{ showContent &&
						<div style={ { zIndex: '2' } }>

							<ContentView
								setLockMode={ setLockMode }
								resumeModeViewToggle={ resumeModeViewToggle }
								contentOpacity={ contentOpacity }
								setContentOpacity={ setContentOpacity }
								VIEW_TOGGLE_ANIM_TIME={ VIEW_TOGGLE_ANIM_TIME }
								VIEW_TOGGLE_ANIM_TIME_OUT={ VIEW_TOGGLE_ANIM_TIME_OUT }
								portfolioData={ portfolioData }
								sectionPosRefArray={ sectionPosRefArray }
								setDocumentScrollState={ setDocumentScrollState }
								documentScrollState={ documentScrollState }
								lockMode={ lockMode }
								resumeMode={ resumeMode }
								fadeInContent={ fadeInContent }
								pinchBlocker={ pinchBlocker }

							/>

							{/* <div className='buffer-div'> </div> */ }

						</div>
					}

					{/* <div style={ { width: `100%`, height: '200px', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative', zIndex: '2', userSelect: 'none', fontWeight: 'normal' } }> © Michael Glovin 2023 */ }
					<div
						style={ {
							// width: `100%`,
							// // height: '250px',
							// height: '200px',
							// // height: '225px',
							// marginTop: '64px',
							// // marginTop: '100px',
							// display: 'flex',
							// alignItems: 'center',
							// justifyContent: 'center',
							// position: 'relative',
							// zIndex: '2',
							// userSelect: 'none',
							// fontWeight: 'normal',
							// flexDirection: 'column',
							// // opacity:'50%',
							// // gap:'32px',
							// gap: '32px',
						} }
						className='signature-div'
					>
						{/* <span>--</span> */ }
						{/* <img className="signature" src={ sig } /> */ }

						{/* <div
						style={{
							width:'25%',
							height:'1px',
							backgroundColor:'#cdcdcd',
						}}
						>
						</div> */}

						{/* { ' ' } */ }


						<div
							style={ {
								// opacity: '80%'
								// backgroundColor:'red',
								// backgroundColor:'white',
								// backgroundColor:'rgba(0, 0, 0, 0.15)',
								// backgroundColor:'rgb(215, 215, 215)',
								// backgroundColor:'lightgrey',

								// backgroundColor:'rgba(0, 0, 0, 0.05)',

								// color:'white',
								// fontWeight:'500',
								// color:'rgb(125, 125, 125)',
								// color: '#cdcdcd',
								// border:'1px #cdcdcd solid',
								// padding:'4px 8px 4px 8px',
								// padding: '5px 10px 5px 10px',
								// padding: '8px 12px 8px 12px',
								// filter: 'drop-shadow(0px 2px 1.4px rgba(0, 0, 0, 0.15))',

								// color: 'rgba(0, 0, 0, 0.4)',
								
								
								color: 'rgba(0, 0, 0, 0.5)',  // prev
								
								color: 'rgba(0, 0, 0, 0.39)', //  main grey (-10?)

								
								// color: 'rgba(0, 0, 0, 0.26)', //  main grey (-fix but worse maybe??)
								// color:`hsl(0, 0%, 55%)`,
								// color:`hsl(0, 0%, 50%)`,

								color: 'rgba(0, 0, 0, 0.33)', //  50%
								
								// color: 'rgba(0, 0, 0, 0.28)', //  matching
								fontWeight: '400',
								fontWeight: '500',
								borderRadius: '4px',
								// border: '1px rgba(0,0,0,.2) solid',
								// borderTop: '1px rgba(0,0,0,.2) solid',
								// borderBottom: '1px rgba(0,0,0,.2) solid',
								// borderRadius: '0px',
								// padding:`12px`,
								// paddingLeft:`2px`,
								// paddingRight:`2px`,
							} }
							className='copyright-tag'
						>© 2024 Michael Glovin</div>
					</div>
					{ !resumeMode && <div className='buffer-div'> </div> }
					{/* <div className='buffer-div'> </div> */ }
				</div>
			</div>
			<ReturnArrow appDivRef={ appDivRef } resumeMode={resumeMode} />
		</div>
	)
}

export default App

import React from 'react'

import './TestSkills.css'

const TestSkills = () => {
  return (
    <div className='skills-wrapper'>
      
      <div className="quarter-inch"></div>
      <div className="skill-content-wrapper">
      <div className="skill-list">
        <div className="skill-item">
          <li>3D modeling</li>
        </div>
        <div className="skill-item">
          <li>digital sculpting</li>
        </div>
        <div className="skill-item">
          <li>anatomy</li>
        </div>
    
      </div>
      <div className="skill-list">
        <div className="skill-item">
          <li>sketching</li>
        </div>
        <div className="skill-item">
          <li>digital painting</li>
        </div>
        <div className="skill-item">
          <li>perspective drawing</li>
        </div>
    
      </div>
      <div className="skill-list">
          <div className="skill-item">
            <li>graphic design</li>
          </div>
          <div className="skill-item">
            <li>strategy</li>
          </div>
          <div className="skill-item">
            <li>product development</li>
          </div>
   
        </div>
        <div className="skill-list">
          <div className="skill-item">
            <li>ux/ui design</li>
          </div>
          <div className="skill-item">
            <li>branding</li>
          </div>
          <div className="skill-item">
            <li>human-centered design</li>
          </div>
         
        </div>
        </div>
      <div className="quarter-inch"></div>
      
    </div>
  )
}

export default TestSkills






import { useMemo } from "react";
import "./Property116Inch.css";
const Property116Inch = ({
  positionNumber,
  property116InchWidth,
  property116InchHeight,
  property116InchAlignSelf,
  property116InchPosition,
  property116InchTransform,
  property116InchTransformOrigin,
  frameDivBackgroundColor,
  inchTop,
  inchLeft,
}) => {
  const property116InchStyle = useMemo(() => {
    return {
      width: property116InchWidth,
      height: property116InchHeight,
      alignSelf: property116InchAlignSelf,
      position: property116InchPosition,
      transform: property116InchTransform,
      transformOrigin: property116InchTransformOrigin,
    };
  }, [
    property116InchWidth,
    property116InchHeight,
    property116InchAlignSelf,
    property116InchPosition,
    property116InchTransform,
    property116InchTransformOrigin,
  ]);

  const frameDivStyle = useMemo(() => {
    return {
      backgroundColor: frameDivBackgroundColor,
    };
  }, [frameDivBackgroundColor]);

  const inchStyle = useMemo(() => {
    return {
      top: inchTop,
      left: inchLeft,
    };
  }, [inchTop, inchLeft]);

  return (
    <div className="property-116-inch" style={property116InchStyle}>
      <div className="inch-wrapper" style={frameDivStyle}>
        <div className="inch" style={inchStyle}>
          {positionNumber}
        </div>
      </div>
    </div>
  );
};

export default Property116Inch;

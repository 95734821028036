import React, { useRef, useEffect, useState } from 'react'



const ThumbPosIndicator = ( {
    content,
    carouselPosition,
    carouselLength,
    posChangeOriginator
} ) => {




    const indicatorRef = useRef( null )

    const activeIndicatorCount = useRef( 1 )

    const initStatus = useRef( false )




    const prevCarouselPos = useRef( carouselPosition )

    const moveType = useRef( 'init' )


    const [ indicatorPos, setIndicatorPos ] = useState( null )

    const [ indicatorFullOffset, setIndicatorFullOffset ] = useState( null )


    const indicatorOffsetModes = [ -indicatorFullOffset, 0, indicatorFullOffset ]

    const indicatorModesLength = indicatorOffsetModes.length


    // height fns
    // height fns
    // height fns

    // init?



    function findHeight( ) {
        const heightUnit = indicatorRef.current.offsetHeight
        return heightUnit
    }


    function indicatorUpdate( position ) {

        moveType.current = null



        const offsetHeight = findHeight() // finds offset height

        updateIndicatorFullOffset( offsetHeight )

        if ( carouselPosition == carouselLength - 1 && prevCarouselPos.current == 0 && posChangeOriginator.current == `button` ) {

            // activeIndicatorCount.current = ( activeIndicatorCount.current - 1 + carouselLength ) % carouselLength
            activeIndicatorCount.current = ( activeIndicatorCount.current - 1 + indicatorModesLength ) % indicatorModesLength

            moveType.current = `dec`

        }

        if ( carouselPosition == 0 && prevCarouselPos.current == carouselLength - 1 && posChangeOriginator.current == `button` ) {

            activeIndicatorCount.current = ( activeIndicatorCount.current + 1 ) % indicatorModesLength

            moveType.current = `inc`

        }

        prevCarouselPos.current = carouselPosition

        updateIndicatorPos( offsetHeight, position ) // moves indicator based on found height



    }


    function updateIndicatorPos( offsetHeight, position ) {
        setIndicatorPos(
            ( offsetHeight + 16 ) * position
        )
    }

    function updateIndicatorFullOffset( offsetHeight ) {
        setIndicatorFullOffset(
            ( offsetHeight + 16 ) * carouselLength
        )
    }

    // height use effect
    // height use effect
    // height use effect

    useEffect( () => {

        indicatorUpdate( carouselPosition )







        return () => {

        }
    }, [ carouselPosition ] )


    useEffect( () => {
        setTimeout( () => {
            initStatus.current = true
        }, 1 )

        return () => {

        }
    }, [] )



    useEffect( () => {
        const handleResize = () => {
            // console.log( 'Window resized' )
            indicatorUpdate( carouselPosition )
        }

        window.addEventListener( 'resize', handleResize )

        return () => {
            window.removeEventListener( 'resize', handleResize )
        }
    }, [] ) 




    function getTransitionStyle( arrayPosOffset ) {

        // if init return instant, if thumbOver return ``, otherwise:..?

        // console.log( `move type is`, moveType.current )

        if ( initStatus.current == false ) {
            return `top 0ms`
        }

        if ( ( moveType.current === 'dec' && ( activeIndicatorCount.current + arrayPosOffset ) % indicatorModesLength === 2 ) ||
            ( moveType.current === 'inc' && ( activeIndicatorCount.current + arrayPosOffset ) % indicatorModesLength === 0 )
        ) {
            // || moveType.current == `init` ) {

            // not sure why this is 2,0 and not the other way around.. maybe it's looking at what the next one will be?
            return `top 0ms`
        } else {
            return ``
        }
    }


    return (
        <div className='thumb-pos-indicator-container'>

            <div
                className="thumb-pos-active"
                style={ {

                    top: `${ indicatorPos + indicatorOffsetModes[ ( activeIndicatorCount.current ) % indicatorModesLength ] }px`,
                    // backgroundColor: 'purple',

                    transition: getTransitionStyle( 0 ),
                    // transition: `${ initStatus.current == false ? `0ms all` : `` }`,
                } }
            >
                {/* <div className='indicator-text'> {activeIndicatorCount.current}</div>  */}
                {/* <div className='indicator-text'> {carouselPosition+1}</div>  */}
                {/* <div className='indicator-text'> {indicatorModesLength}</div>  */ }


            </div>

            <div
                ref={ indicatorRef }
                className="thumb-pos-active"
                style={ {
                    top: `${ indicatorPos + indicatorOffsetModes[ ( ( activeIndicatorCount.current + 1 ) % indicatorModesLength ) ] }px`,

                    transition: getTransitionStyle( 1 ),
                    // transition: `${ initStatus.current == false ? `0ms all` : `` }`,
                } }
            >
{/* <div className='indicator-text'> {carouselPosition+1}</div>  */}
            </div>

            <div
                className="thumb-pos-active"
                style={ {


                    // top: `${ indicatorPos + indicatorOffsetModes[ ( ( activeIndicatorCount.current + 2 ) % carouselLength ) ] }px`,

                    top: `${ indicatorPos + indicatorOffsetModes[ ( ( activeIndicatorCount.current + 2 ) % indicatorModesLength ) ] }px`,

                    // top: `${ indicatorPos + indicatorOffsetModes[0]}px`,


                    // backgroundColor: 'orange',

                    transition: getTransitionStyle( 2 ),
                    // transition: `${ initStatus.current == false ? `0ms all` : `` }`,
                } }
            >
{/* <div className='indicator-text'> {carouselPosition+1}</div>  */}
            </div>


            { content.map( ( item, index ) => {
                return (
                    <div>
                        <div className="thumb-pos-filler"></div>
                    </div>
                )
            } ) }


        </div>
    )
}

export default ThumbPosIndicator

{/* <div className='indicator-text'> {carouselPosition+1}</div>  */ }
/* eslint-disable no-restricted-globals */


import React, { useState, useRef, useEffect } from 'react'



import './Carousel.css'
import './StaticCarousel.css'

// import CarouselView from './CarouselComponents/CarouselView'
// import CarouselThumbs from './CarouselComponents/CarouselThumbs'
// import ArrowContainer from './CarouselComponents/ArrowContainer'
// import SlideIndicator from './CarouselComponents/SlideIndicator'

import CarouselSingleView from './CarouselComponents/CarouselSingleView'

// import { CarouselItem } from './CarouselComponents/CarouselItem'
// import CarouselNavButton from './CarouselComponents/CarouselNavButton'


function renderText( data ) {
    if ( typeof data === 'string' ) {
        return data
    } else if ( typeof data === 'object' && Array.isArray( data ) ) {
        return data.map( ( tuple, index, array ) => {
            const isLast = index === array.length - 1

            if ( tuple[ 0 ] === 'italic' ) {
                return (
                    <span key={ index } className="italic">
                        { tuple[ 1 ] }
                        { !isLast && ' ' }
                    </span>
                )
            } else {
                return (
                    <span key={ index }>
                        { tuple[ 1 ] }
                        { !isLast && ' ' }
                    </span>
                )
            }
        } )
    }
}

const Carousel = ( { carouselContent, documentScrollState, pinchBlocker, index, length } ) => {




    const sliderRef = useRef()
    const itemRefArray = useRef( [] )

    const [ carouselPosition, setCarouselPosition ] = useState( 0 )



    // new vars



    const MARGIN_WIDTH = 64

    // const vwFullDesk = 45
    // const vwSmallDesk = 60
    // // const vwSmallDesk = 65
    // const vwPortraitMobile = 100
    // // const vwLandscapeMobile = 93
    // // const vwLandscapeMobile = 90
    // // const vwLandscapeMobile = 93
    // const vwLandscapeMobile = 95
    // // const mobileThumbSize = 128


    const vwFullDesk = 43.5
    // const vwSmallDesk = 60
    const vwSmallDesk = 58
    const vwSmallTablet = 60
    // const vwSmallTablet = 62.5
    // const vwSmallDesk = 65
    const vwPortraitMobile = 100
    // const vwLandscapeMobile = 93
    // const vwLandscapeMobile = 90
    // const vwLandscapeMobile = 93
    // const vwLandscapeMobile = 95
    const vwLandscapeMobile = 78.5




    const THUMB_WIDTH = 160
    // const THUMB_WIDTH_MOBILE = 128
    const THUMB_WIDTH_TABLET = 152

    // const frameWidth = useRef( 0 )

    // function staticFrameWidthGetter() {
    //     return window.innerWidth <= 1024
    //         ? window.innerHeight > window.innerWidth ? ( vwPortraitMobile * window.innerWidth / 100 ) : ( ( ( vwLandscapeMobile + 2 ) / 100 * window.innerWidth ) + THUMB_WIDTH_MOBILE )
    //         : window.innerWidth > 1920 ? ( ( window.innerWidth * ( vwFullDesk + 2 ) / 100 ) + THUMB_WIDTH + 96 ) : ( ( window.innerWidth * ( vwSmallDesk + 2 ) / 100 ) + THUMB_WIDTH + 96 )
    // }


    function staticFrameWidthGetter() {

        //  window.innerWidth <= 1024 ?
        // window.innerHeight > window.innerWidth ?
        // console.log(`mobile portrait`)
        //     :
        //     console.log(`mobile landscape`)
        // :
        // window.innerWidth > 1920 ?
        // console.log(`full desk`)
        //     :

        //     window.innerWidth >= 1280 ?
        //     console.log(`small desk`)
        //         :

        //         console.log(`tablet`)


        // THIS IS ONLY FOR SETTING DESC WIDTH
        // THIS IS ONLY FOR SETTING DESC WIDTH
        // THIS IS ONLY FOR SETTING DESC WIDTH


        return window.innerWidth <= 1024 ?
            window.innerHeight > window.innerWidth ?
                ( vwPortraitMobile * window.innerWidth / 100 )
                :
                ( ( vwLandscapeMobile + 2 + 15 ) / 100 * window.innerWidth )
            :
            window.innerWidth > 1920 ?
                ( ( window.innerWidth * ( vwFullDesk + 2 ) / 100 ) + THUMB_WIDTH + 96 )
                :

                window.innerWidth >= 1280 ?
                    ( ( window.innerWidth * ( vwSmallDesk + 2 ) / 100 ) + THUMB_WIDTH + 96 )
                    :
                    ( ( window.innerWidth * ( vwSmallTablet + 4 ) / 100 ) + THUMB_WIDTH_TABLET )
        // ( window.innerWidth * vwSmallTablet / 100 ) 
        // 10





    }


    const frameWidth = useRef( staticFrameWidthGetter() )


    function descWidthSetter( params ) {

        const _frameWidth = staticFrameWidthGetter()
        // return _frameWidth - ( 10 / 100 * window.innerWidth )
        return _frameWidth - ( 20 / 100 * window.innerWidth )


    }

    const [ slideDescWidth, setSlideDescWidth ] = useState( descWidthSetter() )

    const [ slideInfoWidthState, setSlideInfoWidthState ] = useState( 0 )


    // new handlers






    useEffect( () => {

        const updateDynamicWidth = () => {
            // const frameWidthTemp = frameWidth.current
            const windowWidth = window.innerWidth
            // const windowHeight = window.innerHeight
            // const baseWidth = staticFrameWidthGetter( 0 )
            const baseWidth = staticFrameWidthGetter()
            const calculatedWidth = baseWidth - ( .1 * windowWidth )

            setSlideInfoWidthState( calculatedWidth )

        }

        let timeoutId = null

        const handleResize = () => {

            if ( pinchBlocker == true ) {
                return
            }

            if ( timeoutId ) {
                clearTimeout( timeoutId )
            }


            frameWidth.current = staticFrameWidthGetter()

            timeoutId = setTimeout( () => {


                setTimeout( () => {


                    sliderRef.current.style.height = `${ itemRefArray.current[ 0 ].offsetHeight }px`

                    updateDynamicWidth()

                    setSlideDescWidth( descWidthSetter() )

                }, 1 )

            }, 250 )



        }


        const handleOrientationChange = () => {



            // frameWidth.current = staticFrameWidthGetter()

            // timeoutId = setTimeout( () => {


            //     setTimeout( () => {


            //         sliderRef.current.style.height = `${ itemRefArray.current[ 0 ].offsetHeight }px`

            //         /// HANDLE EVERYTHING DECLARATIVELY WITH USESTATE?
            //         /// HANDLE EVERYTHING DECLARATIVELY WITH USESTATE?
            //         /// HANDLE EVERYTHING DECLARATIVELY WITH USESTATE?
            //         /// HANDLE EVERYTHING DECLARATIVELY WITH USESTATE?
            //         /// HANDLE EVERYTHING DECLARATIVELY WITH USESTATE?
            //         /// HANDLE EVERYTHING DECLARATIVELY WITH USESTATE?
            //         /// HANDLE EVERYTHING DECLARATIVELY WITH USESTATE?
            //         /// HANDLE EVERYTHING DECLARATIVELY WITH USESTATE?
            //         /// HANDLE EVERYTHING DECLARATIVELY WITH USESTATE?

            //         updateDynamicWidth()

            //         setSlideDescWidth( descWidthSetter() )


            //     }, 1 )

            // }, 250 )


            frameWidth.current = staticFrameWidthGetter()




            sliderRef.current.style.height = `${ itemRefArray.current[ 0 ].offsetHeight }px`

            /// HANDLE EVERYTHING DECLARATIVELY WITH USESTATE?
            /// HANDLE EVERYTHING DECLARATIVELY WITH USESTATE?
            /// HANDLE EVERYTHING DECLARATIVELY WITH USESTATE?
            /// HANDLE EVERYTHING DECLARATIVELY WITH USESTATE?
            /// HANDLE EVERYTHING DECLARATIVELY WITH USESTATE?
            /// HANDLE EVERYTHING DECLARATIVELY WITH USESTATE?
            /// HANDLE EVERYTHING DECLARATIVELY WITH USESTATE?
            /// HANDLE EVERYTHING DECLARATIVELY WITH USESTATE?
            /// HANDLE EVERYTHING DECLARATIVELY WITH USESTATE?

            updateDynamicWidth()

            setSlideDescWidth( descWidthSetter() )



        }

        window.addEventListener( 'resize', handleResize )
        // window.addEventListener( 'orientationchange', handleOrientationChange )
        screen.orientation.addEventListener( 'change', handleOrientationChange )

        return () => {

            if ( timeoutId ) {
                clearTimeout( timeoutId )
            }
            window.removeEventListener( 'resize', handleResize )
            // window.removeEventListener( 'orientationchange', handleOrientationChange )
            screen.orientation.removeEventListener( 'change', handleOrientationChange )
        }
    }, [] )



    // observer

    const carouselContainerRef = useRef()
    const [ carouselVisible, setCarouselVisible ] = useState()
    // console.log( `carouselVis for ${ carouselContent.sectionCategory } || ${ carouselContent.sectionTitle }`, carouselVisible )
    useEffect( () => {
        // console.log( `container ref`, carouselContainerRef.current )

        const observer = new IntersectionObserver( ( entries ) => {
            const entry = entries[ 0 ]
            // console.log(`entry`, entry);
            setCarouselVisible( entry.isIntersecting )

        }

        )
        observer.observe( carouselContainerRef.current )

    }, [] )





    // OUTPUT ----------------------------------------------

    return (
        <div
            ref={ carouselContainerRef }
            // className='carousel-container'
            className={ index === 0 ? 'carousel-container first-carousel-container' : index === length - 1 ? 'carousel-container last-carousel-container' : 'carousel-container' }
        >

            <div className="pillars">
                <div className="left-side">

                    <div className="carousel-information carousel-information-static">

     


                            <span className="mobile-content">


                                <div className='mobile-theme-div'>
    
                                    <p style={ { margin: '0px' } }>


                                        { carouselContent.sectionTitle && (
                                            <span className="carousel-title">{ carouselContent.sectionTitle }</span>
                                        ) }

                                        { carouselContent.sectionCategory && (
                                            <span>
                                                {/* <span className='mobile-info-divider'> &nbsp;|&nbs p; </span> */}
                                                {/* <span className='mobile-info-divider'> | </span> */}
                                                <span className='mobile-info-divider'>|</span>
                                                {/* <span> </span> */}
                                            </span>
                                        ) }

                                        { carouselContent.sectionCategory && (
                                            <
                                                span className="carousel-category"
                                                // style={{lineHeight:'1.4', margin:'0px'}}
                                                style={ { lineHeight: '1.6', margin: '0px' } }
                                            >{ carouselContent.sectionCategory }</span>
                                        ) }




                                    </p>

                                    { carouselContent.sectionDesc && (
                                        <div
                                            className="carousel-desc"
                                            // style={{lineHeight:'1.4', margin:'0px'}}
                                            style={ { lineHeight: '1.6', margin: '0px' } }
                                        >{ renderText( carouselContent.sectionDesc ) }</div>
                                    ) }

                                </div>





                            </span>




                            <span className="desktop-content">

                                <div className='mobile-theme-div'>


                                    { carouselContent.sectionTitle && (
                                        <div className="carousel-title">{ carouselContent.sectionTitle }</div>
                                    ) }


               

                                    {/* <div className="carousel-category">{ carouselContent.sectionCategory }</div> */ }
                                    { carouselContent.sectionCategory && (
                                        <div className="carousel-category">{ carouselContent.sectionCategory }</div>
                                    ) }

                                    { carouselContent.sectionDesc && (
                                        <div className="carousel-desc">{ renderText( carouselContent.sectionDesc ) }</div>
                                    ) }


                                </div>
                            </span>

                     

















                        {/* <div className='mobile-theme-div'>
                            <div className="carousel-title">{ carouselContent.sectionTitle }</div>
                            <span className='mobile-info-divider'> | </span>
                            <div className="carousel-category">{ carouselContent.sectionCategory }</div>
                        </div> */}
                        {/* <div className="carousel-desc">{ renderText( carouselContent.sectionDesc ) }</div> */}


                    </div>

                    <div className="carousel-and-arrows">

                        <CarouselSingleView
                            content={ carouselContent.content }
                            setCarouselPosition={ setCarouselPosition }
                            carouselPosition={ carouselPosition }
                            // posTransition={ posTransition }
                            // setActiveHoverState={ setActiveHoverState }
                            itemRefArray={ itemRefArray }
                            sliderRef={ sliderRef }
                            carouselVisible={ carouselVisible }
                        />

                    </div>

                    <div className="slide-information"
                        //  style={ { width: `${ slideDescWidth.current }px`, backgroundColor:'pink'} }

                        style={ { width: `${ slideDescWidth }px`, } }
                    >


                        <p
                            className='carousel-information-p carousel-information-p-single-mobile'
                        >
                            { ( carouselContent.content[ carouselPosition ].type === 'figmaInteractive' ) &&
                                <p className='figma-instructions'>
                                    Interactive Instructions: press 'f' to toggle fullscreen, click anywhere to see interactable areas
                                </p> }
                            { carouselContent.content[ carouselPosition ].desc }
                        </p>

                    </div>

                </div>

            </div>
        </div>
    )
}

export default Carousel

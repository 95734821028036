/* eslint-disable no-restricted-globals */

import React, { forwardRef, useEffect, useState, useLayoutEffect } from 'react'

import './CarouselItem.css'

import pointerSVG from '../../assets/pointer.svg'

export const CarouselItem = forwardRef( function CarouselItem( { item, carouselVisible }, ref ) {

    const [ interactiveHovered, setInteractiveHovered ] = useState( false )
    const [ displayOverlay, setDisplayOverlay ] = useState( true )
    const [ fadeTimer, setFadeTimer ] = useState( null )
    const [ touchableStatus, setTouchableStatus ] = useState( true )

    // WIDTH HANDLING   

    // const vwFullDesk = 45
    // const vwSmallDesk = 60
    // const vwPortraitMobile = 100
    // const vwLandscapeMobile = 95

    // const vwFullDesk = 45
    // const vwFullDesk = 42.5
    // const vwFullDesk = 43
    // const vwFullDesk = 44
    const vwFullDesk = 43.5
    // const vwSmallDesk = 60
    const vwSmallDesk = 58
    const vwSmallTablet = 60
    // const vwSmallTablet = 62.5
    // const vwSmallDesk = 65
    const vwPortraitMobile = 100
    // const vwLandscapeMobile = 93
    // const vwLandscapeMobile = 90
    // const vwLandscapeMobile = 93
    // const vwLandscapeMobile = 95
    const vwLandscapeMobile = 78.5
    // const mobileThumbSize = 128

    const maxHeight = 75.5


    // const cat = false

    const widthSetter = () => {

        // const determinedWidth = window.innerWidth <= 1024
        //     ? window.innerHeight > window.innerWidth ? vwPortraitMobile : vwLandscapeMobile
        //     : window.innerWidth > 1920 ? vwFullDesk : vwSmallDesk

        const determinedWidth = window.innerWidth <= 1024
            ? window.innerHeight > window.innerWidth ? vwPortraitMobile : vwLandscapeMobile
            : window.innerWidth > 1920 ? vwFullDesk :
                window.innerWidth >= 1280 ? vwSmallDesk : vwSmallTablet
        return determinedWidth
    }

    const getDynamicFrameHeight = () => {
        const _viewWidth = widthSetter()


        const calcedHeight = `${ ( ( item.ratio * _viewWidth ) / 100 ) * window.innerWidth >=
            ( maxHeight / 100 ) * window.innerHeight
            ? `${ maxHeight }vh`
            : `${ item.ratio * viewWidth }vw`
            }`

        return calcedHeight
    }

    const getDynamicFrameWidth = () => {

        // ratio is height/width

        // const calcedWidth =  `${ ( ( item.ratio * _viewWidth ) / 100 ) * window.innerWidth >=
        // ( maxHeight / 100 ) * window.innerHeight
        // ? `${ maxHeight }vh`
        // : `${ item.ratio * viewWidth }vw`
        // }`

        const _viewWidth = widthSetter()

        const calcedHeight = ( ( item.ratio * _viewWidth ) / 100 ) * window.innerWidth >=
            ( maxHeight / 100 ) * window.innerHeight
            ? maxHeight * ( window.innerHeight / 100 )
            : item.ratio * viewWidth * ( window.innerWidth / 100 )
        // :  item.ratio * _viewWidth * (window.innerWidth/100)

        // console.log(`(1/item.ratio) * calcedHeight || 1/${item.ratio}*${calcedHeight}`);        

        // const calcedWidth =  (1/item.ratio) * _viewWidth
        // const calcedWidth =  (1/item.ratio) * (calcedHeight/100 * window.innerWidth)
        const calcedWidth = ( 1 / item.ratio ) * calcedHeight
        return calcedWidth
    }


    // const [modeTester, setModeTester] = useState('none')


    const [ viewWidth, setViewWidth ] = useState( widthSetter() )
    // const viewWidth = 45
    // const viewWidth = 60
    // const maxHeight = 70
    // const maxHeight = 77.5


    const [ dynamicFrameHeight, setDynamicFrameHeight ] = useState( getDynamicFrameHeight() )
    const [ dynamicFrameWidth, setDynamicFrameWidth ] = useState( getDynamicFrameWidth() )



    useEffect( () => {
    // useLayoutEffect( () => {




        let timeoutId = null

        const handleResize = () => {


            
            setDynamicFrameHeight( getDynamicFrameHeight() )
            setDynamicFrameWidth( getDynamicFrameWidth() )


            // if ( timeoutId ) {
            //     clearTimeout( timeoutId )
            // }




            // timeoutId = setTimeout( () => {

            //     setTimeout( () => {

                 

            //         setDynamicFrameHeight( getDynamicFrameHeight() )
            //         setDynamicFrameWidth( getDynamicFrameWidth() )






            //     }, 1 )
                

            // }, 250 )



        }


        const handleOrientationChange = () => {



            // if (window.innerHeight < window.innerWidth) {
            //     setModeTester('landscape')
                
            // }

            // else{
            //     setModeTester('portrait')
            // }

                            setDynamicFrameHeight( getDynamicFrameHeight() )
                    setDynamicFrameWidth( getDynamicFrameWidth() )


            // if ( timeoutId ) {
            //     clearTimeout( timeoutId )
            // }



            // timeoutId = setTimeout( () => {

            //     setTimeout( () => {


            //         setDynamicFrameHeight( getDynamicFrameHeight() )
            //         setDynamicFrameWidth( getDynamicFrameWidth() )

               
         


            //     }, 1 )

            // }, 250 )

        }

        window.addEventListener( 'resize', handleResize )
        // window.addEventListener( 'orientationchange', handleOrientationChange )
        // screen.orientation.addEventListener("change", handleOrientationChange)
        screen.orientation.addEventListener('change', handleOrientationChange);



        return () => {

            if ( timeoutId ) {
                clearTimeout( timeoutId )
            }
            window.removeEventListener( 'resize', handleResize )
            // window.removeEventListener( 'orientationchange', handleOrientationChange )
            screen.orientation.removeEventListener('change', handleOrientationChange);

        }
    }, [] )


    /////////////////////


    useEffect( () => {
        return () => {
            if ( fadeTimer ) {
                clearTimeout( fadeTimer )
            }
        }
    }, [ fadeTimer ] )


    const handleMouseEnter = () => {

        setTouchableStatus( false )

        if ( fadeTimer ) {
            clearTimeout( fadeTimer )

        }

        setDisplayOverlay( false )

        const timerID = setTimeout( () => {
            setInteractiveHovered( true )
            // console.log(`timer fired!`)
        }, 600 )


        setFadeTimer( timerID )
        // setTimeout( () => {
        //     setInteractiveHovered( true )
        // }, 300 )
    }

    // const handleTouch = () => {

    //     if ( fadeTimer ) {
    //         clearTimeout( fadeTimer )

    //     }

    //     // setDisplayOverlay( false )
    //     setInteractiveHovered(false)

    //     const timerID = setTimeout( () => {
    //         setInteractiveHovered( true )
    //         // console.log(`timer fired!`)
    //     }, 600 )


    //     setFadeTimer( timerID )
    //     // setTimeout( () => {
    //         //     setInteractiveHovered( true )
    //         // }, 300 )
    // }

    const handleMouseLeave = () => {

        setTouchableStatus( true )

        if ( fadeTimer ) {
            clearTimeout( fadeTimer )
            // console.log(`timer cancelled!`)
        }

        setInteractiveHovered( false )
        // setDisplayOverlay( true )


        setTimeout( () => {
            setDisplayOverlay( true )

        }, 1 )
    }





    return (
        <div>
            { item.type === 'image' && (
                <div className="img-container">
                    <img className="carousel-img" src={ item.payloadItem } ref={ ref } />
                </div>
            ) }
            {/* { item.type === 'video' && (
                <div className="img-container">
                    <video width="320" height="240" loop autoPlay muted ref={ ref }>
                        <source src={ item.payloadItem } type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            ) } */}
            { item.type === 'video' && (
                <div className="img-container">
                    <video
                        width="100%"
                        height="100%"
                        loop
                        autoPlay
                        muted
                        style={ { objectFit: 'contain' } }
                        ref={ ref }
                        playsInline
                        disablePictureInPicture
                        className=' item-border-style'

                    // playsinline
                    >
                        <source src={ item.payloadItem } type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            ) }

            { item.type === 'figmaInteractive' && (

                <div
                    className="img-container"
                    ref={ ref }

                    style={ {

                        height: dynamicFrameHeight,
                        position: 'relative',
                    } }
                >

                    <div
                        onMouseEnter={ handleMouseEnter }
                        onMouseLeave={ handleMouseLeave }
                        onTouchStart={ handleMouseEnter }
                        style={ {
                            width: dynamicFrameWidth,
                            height: '100%',
                            margin: 'auto',

                        } }
                    > 

                        { !interactiveHovered && <div style={ {
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',

                            zIndex: '1',
                            height: '100%',


                            width: dynamicFrameWidth,

                            backgroundColor: 'rgba(245,245,245, .6)',


                            backdropFilter: 'blur(.5px)',

                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            gap: '16px',
                            opacity: `${ displayOverlay ? 100 : 0 }%`,
                            pointerEvents: `${ touchableStatus ? 'auto' : 'none' }`,
                            transition: `${ displayOverlay ? 'opacity 200ms ' : 'opacity 75ms' }`,
                            // out , in 
                            
                            // transition: `${ displayOverlay ? 'opacity 500ms ' : 'opacity 100ms' }`,
                            // transition: `${ displayOverlay ? 'opacity 350ms ' : 'opacity 100ms' }`,
                            // transition: `${ displayOverlay ? 'opacity 250ms ' : 'opacity 75ms' }`,
                            // transition: `${ displayOverlay ? 'opacity 75ms ' : 'opacity 75ms' }`,
                            // transition: `${ displayOverlay ? 'opacity 100ms ' : 'opacity 100ms' }`,
                            // transition: `${ displayOverlay ? 'opacity 175ms ' : 'opacity 100ms' }`,
                            // transition: `${ displayOverlay ? 'opacity 300ms ' : 'opacity 100ms' }`,
                            // transition: `${ displayOverlay ? 'opacity 200ms ' : 'opacity 100ms' }`,
                            // transition: `${ displayOverlay ? 'opacity 200ms ' : 'opacity 200ms' }`,
                            // transition: `${ displayOverlay ? 'opacity 300ms ' : 'opacity 300ms' }`,
                        } }
                        >
                            <div style={ { width: '100px', height: '100px' } }>
                                <img
                                    src={ pointerSVG }
                                    style={ { width: '100%', height: '100%' } }
                                />
                            </div>

                            <div style={ {
                                fontSize: '36px',
                                fontWeight: 'bold',
                                color: 'black',
                                position: 'relative',
                            } }
                                className='figma-interactive-font-div'
                                text-content='Figma Interactive'

                            >Figma Interactive</div>


                        </div> }

                        { carouselVisible ? (
                            <iframe
                                width={ `100%` }
                                height={ '100%' }
                                src={ item.payloadItem }
                                loading="lazy"
                                allow="fullscreen"
                                style={ { border: 'none' } }
                                className=' item-border-style'
                            />
                        ) : (
                            <div
                                style={ {
                                    width: '100%',
                                    height: '100%',
                                    border: 'none',
                                    // other identical characteristics
                                } }
                            />
                        ) }





                    </div>

                </div>
            ) }













            {/* { item.type === 'figmaSlideshow' && (
                <div
                    className="img-container"
                    ref={ ref }
                    style={ { height: item.frameHeight - 0, pointerEvents: 'none' } }
                >
                    <iframe
                        // width={ item.frameWidth }
                        height={ item.frameHeight }
                        width="100%"
                        src={ item.payloadItem }
                        loading="lazy"
                        // loading="eager"
                        allow="fullscreen"
                        style={ { border: 'none' } }
                    // cursor='crosshair'

                    // style={{border:'none', borderRadius:'25px'}}
                    />
                </div>
            ) } */}


            { item.type === 'figmaSlideshow' && (
                // <div className="img-container"  ref={ ref } style={{ height:item.frameHeight, pointerEvents:'none'}}>
                // <div className="img-container" ref={ ref } style={{ height: `${Math.min((item.ratio * viewWidth), maxHeight)}vw` }}>
                <div
                    className="img-container"
                    ref={ ref }
                    style={ {
                        height: `${ ( ( item.ratio * viewWidth ) / 100 ) * window.innerWidth >=
                            ( maxHeight / 100 ) * window.innerHeight
                            ? `${ maxHeight }vh`
                            : `${ item.ratio * viewWidth }vw`
                            }`,
                        // pointerEvents: 'none'
                    } }
                > 
                    {/* <iframe
                        width={ item.frameWidth }
                        height={ '100%' }
                        // width='100%'
                        src={ item.payloadItem }
                        loading="lazy"
                        // loading="eager"
                        allow="fullscreen"
                        style={ { border: 'none' } }
                    // cursor='crosshair'

                    // style={{border:'none', borderRadius:'25px'}}
                    /> */}

                    { carouselVisible ? (
                        <iframe
                            width={ item.frameWidth }
                            height={ '100%' }
                            // width='100%'
                            src={ item.payloadItem }
                            loading="lazy"
                            // loading="eager"
                            allow="fullscreen"
                            style={ { border: 'none' } }
                            className=' item-border-style'
                        />
                    ) : (
                        <div
                            style={ {
                                // width: '100%',
                                width: item.frameWidth, // Use the same width as the iframe

                                height: '100%',
                                border: 'none',
                                // other identical characteristics
                            } }
                        />
                    ) }




                </div>
            ) }


            { item.type === 'youtubeEmbed' && (
                // <div className="img-container">

                <div
                    className="img-container"
                    ref={ ref }
                    // style={ {
                    //     height: `${ maxHeight }vh`,
                    //     // pointerEvents: 'none'
                    // } }
                    style={ {
                        height: `${ ( ( item.ratio * viewWidth ) / 100 ) * window.innerWidth >=
                            ( maxHeight / 100 ) * window.innerHeight
                            ? `${ maxHeight }vh`
                            : `${ item.ratio * viewWidth }vw`
                            }`,
                        position: 'relative',
                    } }
                >



                    <iframe
                        width={ dynamicFrameWidth }
                        // width="100%"
                        height="100%"
                        src={ item.payloadItem }
                        // ref={ ref }
                        allowFullScreen
                        frameBorder="0"
                        // allow="autoplay"
                        allow="autoplay; fullscreen"
                        className=' item-border-style'
                    // sandbox="allow-same-origin allow-top-navigation"

                    >
                    </iframe>

                    {/* </iframe> */ }
                    {/* <img className="carousel-img" src={ item.payloadItem } ref={ ref } /> */ }
                </div>
            )
            }
            {/* { item.type === 'video' && (
                <div className="img-container">
                    <video width="320" height="240" loop autoPlay muted ref={ ref }>
                        <source src={ item.payloadItem } type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            ) } */}

        </div >
    )
} )

// split rendering by type and therefore point to different refs for width?

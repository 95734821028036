import React from 'react'

const LeftArrow = ( { activeHoverState, terminalState } ) => {
    return (
        <div
            className={ `arrows ${ terminalState ? 'terminal-arrows' : '' } ${ activeHoverState ? '' : 'inactive-arrows'
                }` }
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                // width="35"
                // height="33"
                width="34"
                height="32"
                // width="34.5"
                // height="32.5"
                // width="33.5"
                // height="31.5"
                // viewBox="-0.5 -0.5 34 32"
                // viewBox="-0.5 -0.5 33 31"
                viewBox="-0.5 -0.5 34 31"
                fill="none"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M16.159 0.168526L0.246029 13.7268C-0.0819969 14.0063 -0.0819931 14.513 0.246033 14.7925L16.159 28.3508C16.6135 28.738 17.313 28.415 17.313 27.8179V21.0646H31.7386C32.1252 21.0646 32.4386 20.7512 32.4386 20.3646V8.49256C32.4386 8.10596 32.1252 7.79256 31.7386 7.79256H17.313V0.701349C17.313 0.104275 16.6135 -0.218704 16.159 0.168526Z"
                />
            </svg>

            {/* 
            { terminalState ? <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="22" height="22" rx="0.7" fill="none" />
            </svg> :
                <svg xmlns="http://www.w3.org/2000/svg" width="35" height="33" viewBox="-0.5 -0.5 34 32" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.159 0.168526L0.246029 13.7268C-0.0819969 14.0063 -0.0819931 14.513 0.246033 14.7925L16.159 28.3508C16.6135 28.738 17.313 28.415 17.313 27.8179V21.0646H31.7386C32.1252 21.0646 32.4386 20.7512 32.4386 20.3646V8.49256C32.4386 8.10596 32.1252 7.79256 31.7386 7.79256H17.313V0.701349C17.313 0.104275 16.6135 -0.218704 16.159 0.168526Z" />
                </svg> } */}
        </div>
    )
}
export default LeftArrow

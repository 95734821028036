/* eslint-disable no-restricted-globals */


import { React, useEffect, useState } from 'react'

import './SectionHeader.css'
import './ContentSection.css' // for divider

const SectionHeader = ( { sectionData, sectionPosRefArray, index, pinchBlocker } ) => {


  // function determineMobileHeader() {
  //   const portraitMobileStatus = window.innerWidth <= 1024
  //     ? window.innerHeight > window.innerWidth ? true : false
  //     : window.innerWidth > 1920 ? false : false


    

  //   return portraitMobileStatus
  // }

  // const [ mobileHeader, setMobileHeader ] = useState( determineMobileHeader() )


  // useEffect( () => {



  //   let timeoutId = null

  //   const handleResize = () => {

  //     if ( pinchBlocker == true ) {
  //       return
  //     }

  //     if ( timeoutId ) {
  //       clearTimeout( timeoutId )
  //     }


  //     timeoutId = setTimeout( () => {

      
  //       setMobileHeader( determineMobileHeader() )

  //     }, 10 )



  //   }


  //   const handleOrientationChange = () => {

  //     // if ( timeoutId ) {
  //     //   clearTimeout( timeoutId )
  //     // }


  //     // timeoutId = setTimeout( () => {


  //     //   setMobileHeader( determineMobileHeader() )

  //     // }, 10 )






  //     setMobileHeader( determineMobileHeader() )

  //   }

  //   window.addEventListener( 'resize', handleResize )
  //   // window.addEventListener( 'orientationchange', handleOrientationChange )
  //   screen.orientation.addEventListener( 'change', handleOrientationChange )


  //   return () => {

  //     if ( timeoutId ) {
  //       clearTimeout( timeoutId )
  //     }
  //     window.removeEventListener( 'resize', handleResize )
  //     // window.removeEventListener( 'orientationchange', handleOrientationChange )
  //     screen.orientation.removeEventListener( 'change', handleOrientationChange )
  //   }
  // }, [] )


  return (
    <div>
   
      <div className="divider"></div>








<div className="mobile-section-header">

        {/* <div className="section-header-wrapper" ref={ ( element ) => ( sectionPosRefArray.current[ index ] = element ) } > */}
        <div className="section-header-wrapper"  >
          <div className="divider-div" >

          </div>
          <div className="section-header-mobile" >



            <div className="icon-div"
            >
              <img src={ sectionData.sectionIcon } />
            </div>

            <span className="section-header-title">{ sectionData.roleTitle }</span>
            <div className='date-span-wrapper'>{ sectionData.roleTime }</div>


            {/* <span className='grey-divider'>|</span> */ }

            <span className='desc-span'>
              { sectionData.roleDesc }
            </span>


            {/* </div> */ }


          </div>
          <div className="divider-div">

          </div>
        </div>


        </div>




<div className="reg-section-header">

        <div className="section-header-wrapper" ref={ ( element ) => ( sectionPosRefArray.current[ index ] = element ) } >
          <div className="divider-div" >
          </div>
          <div className="section-header" >


            <div className="top-row">
              <div className="icon-div"
              >
                <img src={ sectionData.sectionIcon } />
              </div>
              <span className="section-header-title">{ sectionData.roleTitle }</span>
          

              { sectionData.roleDesc && (

                <span className='grey-divider'>|</span>

              ) }

              <span className='desc-span'>
                { sectionData.roleDesc }
              </span>


            </div>
            <div className='date-span-wrapper'>{ sectionData.roleTime }</div>


          </div>
          <div className="divider-div">

          </div>
        </div>


        </div>





  

    </div>
  )
}

export default SectionHeader

import { useMemo } from "react";
import "./EXPContentComponent.css";
const EXPContentComponent = ({
  chainscapeGamesLogo2,
  logoCHAINSCAPEGAMESPixelA,
  logoCHAINSCAPEGAMESPixelA1,
  chainscapeGamesLogo21,
  image56,
  image71,
  ld,
  lUDEREOrigAlt,
  lUDEREOrig,
  chainscapeGamesLogo22,
  prop,
  chainscapeGames,
  web3GameStudio,
  roundRockTX,
  artDirectorAndUXLead,
  ledMultinationalArtDepart,
  spearheadedTheAestheticFo,
  designedAndLedMultimediaP,
  developedAVisionFor3dGame,
  showLogoCHAINSCAPEGAMESPixelA,
  image56Icon,
  lUDEREOrigIcon,
  eXPContentComponentWidth,
  eXPContentComponentGap,
  frameDivWidth,
  frameDivWidth1,
  frameDivHeight,
  chainscapeGamesLogo2IconTop,
  chainscapeGamesLogo2IconWidth,
  chainscapeGamesLogo2IconHeight,
  lOGOLeft,
  lOGOFontSize,
  lOGOTextShadow,
  lOGOWebkitTextStroke,
  logoCHAINSCAPEGAMESPixelALeft,
  logoCHAINSCAPEGAMESPixelAWidth,
  logoCHAINSCAPEGAMESPixelAHeight,
  logoCHAINSCAPEGAMESPixelATop,
  logoCHAINSCAPEGAMESPixelALeft1,
  logoCHAINSCAPEGAMESPixelAWidth1,
  logoCHAINSCAPEGAMESPixelAHeight1,
  chainscapeGamesLogo2IconLeft,
  chainscapeGamesLogo2IconWidth1,
  chainscapeGamesLogo2IconHeight1,
  image56IconLeft,
  image56IconWidth,
  image56IconHeight,
  image71IconLeft,
  image71IconWidth,
  image71IconHeight,
  ldIconLeft,
  ldIconWidth,
  ldIconHeight,
  lUDEREOrigAltTop,
  lUDEREOrigAltLeft,
  lUDEREOrigAltWidth,
  lUDEREOrigAltHeight,
  lUDEREOrigIconTop,
  lUDEREOrigIconLeft,
  lUDEREOrigIconWidth,
  lUDEREOrigIconHeight,
  frameDivWidth2,
  frameDivHeight1,
  chainscapeGamesLogo2IconTop1,
  chainscapeGamesLogo2IconWidth2,
  chainscapeGamesLogo2IconHeight2,
  lOGOLeft1,
  lOGOFontSize1,
  lOGOTextShadow1,
  lOGOWebkitTextStroke1,
  inchTop,
  inchLeft,
  chainscapeGamesWeb3ContaiFontSize,
  pFontSize,
  chainscapeGamesFontSize,
  web3GameStudioFontSize,
  roundRockTXFontSize,
  artDirectorAndContainerFontSize,
  artDirectorAndFontSize,
  ledMultinationalArtDepartFontSize,
  ledMultinationalArtFontSize,
  bulletTwo,
  bulletThree,
  bulletFour,
  bulletFive,
  bulletSix,
}) => {
  const eXPContentComponentStyle = useMemo(() => {
    return {
      width: eXPContentComponentWidth,
      gap: eXPContentComponentGap,
    };
  }, [eXPContentComponentWidth, eXPContentComponentGap]);

  const frameDiv1Style = useMemo(() => {
    return {
      width: frameDivWidth,
    };
  }, [frameDivWidth]);

  const frameDiv2Style = useMemo(() => {
    return {
      width: frameDivWidth1,
      height: frameDivHeight,
    };
  }, [frameDivWidth1, frameDivHeight]);

  const chainscapeGamesLogo2IconStyle = useMemo(() => {
    return {
      top: chainscapeGamesLogo2IconTop,
      width: chainscapeGamesLogo2IconWidth,
      height: chainscapeGamesLogo2IconHeight,
    };
  }, [
    chainscapeGamesLogo2IconTop,
    chainscapeGamesLogo2IconWidth,
    chainscapeGamesLogo2IconHeight,
  ]);

  const lOGOStyle = useMemo(() => {
    return {
      left: lOGOLeft,
      fontSize: lOGOFontSize,
      textShadow: lOGOTextShadow,
      webkitTextStroke: lOGOWebkitTextStroke,
    };
  }, [lOGOLeft, lOGOFontSize, lOGOTextShadow, lOGOWebkitTextStroke]);

  const logoCHAINSCAPEGAMESPixelArStyle = useMemo(() => {
    return {
      left: logoCHAINSCAPEGAMESPixelALeft,
      width: logoCHAINSCAPEGAMESPixelAWidth,
      height: logoCHAINSCAPEGAMESPixelAHeight,
    };
  }, [
    logoCHAINSCAPEGAMESPixelALeft,
    logoCHAINSCAPEGAMESPixelAWidth,
    logoCHAINSCAPEGAMESPixelAHeight,
  ]);

  const logoCHAINSCAPEGAMESPixelAr1Style = useMemo(() => {
    return {
      top: logoCHAINSCAPEGAMESPixelATop,
      left: logoCHAINSCAPEGAMESPixelALeft1,
      width: logoCHAINSCAPEGAMESPixelAWidth1,
      height: logoCHAINSCAPEGAMESPixelAHeight1,
    };
  }, [
    logoCHAINSCAPEGAMESPixelATop,
    logoCHAINSCAPEGAMESPixelALeft1,
    logoCHAINSCAPEGAMESPixelAWidth1,
    logoCHAINSCAPEGAMESPixelAHeight1,
  ]);

  const chainscapeGamesLogo2Icon1Style = useMemo(() => {
    return {
      left: chainscapeGamesLogo2IconLeft,
      width: chainscapeGamesLogo2IconWidth1,
      height: chainscapeGamesLogo2IconHeight1,
    };
  }, [
    chainscapeGamesLogo2IconLeft,
    chainscapeGamesLogo2IconWidth1,
    chainscapeGamesLogo2IconHeight1,
  ]);

  const image56IconStyle = useMemo(() => {
    return {
      left: image56IconLeft,
      width: image56IconWidth,
      height: image56IconHeight,
      top:'-0px'
    };
  }, [image56IconLeft, image56IconWidth, image56IconHeight]);

  const image71IconStyle = useMemo(() => {
    return {
      left: image71IconLeft,
      width: image71IconWidth,
      height: image71IconHeight,
    };
  }, [image71IconLeft, image71IconWidth, image71IconHeight]);

  const ldIconStyle = useMemo(() => {
    return {
      left: ldIconLeft,
      width: ldIconWidth,
      height: ldIconHeight,
    };
  }, [ldIconLeft, ldIconWidth, ldIconHeight]);

  const lUDEREOrigAltStyle = useMemo(() => {
    return {
      top: lUDEREOrigAltTop,
      left: lUDEREOrigAltLeft,
      width: lUDEREOrigAltWidth,
      height: lUDEREOrigAltHeight,
    };
  }, [
    lUDEREOrigAltTop,
    lUDEREOrigAltLeft,
    lUDEREOrigAltWidth,
    lUDEREOrigAltHeight,
  ]);

  const lUDEREOrigIconStyle = useMemo(() => {
    return {
      top: lUDEREOrigIconTop,
      left: lUDEREOrigIconLeft,
      width: lUDEREOrigIconWidth,
      height: lUDEREOrigIconHeight,
    };
  }, [
    lUDEREOrigIconTop,
    lUDEREOrigIconLeft,
    lUDEREOrigIconWidth,
    lUDEREOrigIconHeight,
  ]);

  const frameDiv3Style = useMemo(() => {
    return {
      width: frameDivWidth2,
      height: frameDivHeight1,
    };
  }, [frameDivWidth2, frameDivHeight1]);

  const chainscapeGamesLogo2Icon2Style = useMemo(() => {
    return {
      top: chainscapeGamesLogo2IconTop1,
      width: chainscapeGamesLogo2IconWidth2,
      height: chainscapeGamesLogo2IconHeight2,
    };
  }, [
    chainscapeGamesLogo2IconTop1,
    chainscapeGamesLogo2IconWidth2,
    chainscapeGamesLogo2IconHeight2,
  ]);

  const lOGO1Style = useMemo(() => {
    return {
      left: lOGOLeft1,
      fontSize: lOGOFontSize1,
      textShadow: lOGOTextShadow1,
      webkitTextStroke: lOGOWebkitTextStroke1,
    };
  }, [lOGOLeft1, lOGOFontSize1, lOGOTextShadow1, lOGOWebkitTextStroke1]);

  const inch1Style = useMemo(() => {
    return {
      top: inchTop,
      left: inchLeft,
    };
  }, [inchTop, inchLeft]);

  const chainscapeGamesWeb3ContainerStyle = useMemo(() => {
    return {
      fontSize: chainscapeGamesWeb3ContaiFontSize,
    };
  }, [chainscapeGamesWeb3ContaiFontSize]);

  const pStyle = useMemo(() => {
    return {
      fontSize: pFontSize,
    };
  }, [pFontSize]);

  const chainscapeGamesStyle = useMemo(() => {
    return {
      fontSize: chainscapeGamesFontSize,
    };
  }, [chainscapeGamesFontSize]);

  const web3GameStudioStyle = useMemo(() => {
    return {
      fontSize: web3GameStudioFontSize,
    };
  }, [web3GameStudioFontSize]);

  const roundRockTXStyle = useMemo(() => {
    return {
      fontSize: roundRockTXFontSize,
    };
  }, [roundRockTXFontSize]);

  const artDirectorAndContainerStyle = useMemo(() => {
    return {
      fontSize: artDirectorAndContainerFontSize,
    };
  }, [artDirectorAndContainerFontSize]);

  const artDirectorAndStyle = useMemo(() => {
    return {
      fontSize: artDirectorAndFontSize,
    };
  }, [artDirectorAndFontSize]);

  const ledMultinationalArtDepartmeStyle = useMemo(() => {
    return {
      fontSize: ledMultinationalArtDepartFontSize,
    };
  }, [ledMultinationalArtDepartFontSize]);

  const ledMultinationalArtStyle = useMemo(() => {
    return {
      fontSize: ledMultinationalArtFontSize,
    };
  }, [ledMultinationalArtFontSize]);

  return (
    <div className="exp-content-component" style={eXPContentComponentStyle}>
      <div className="frame-parent" style={frameDiv1Style}>
        <div className="chainscapegameslogo2-parent" style={frameDiv2Style}>
          <img
            className="chainscapegameslogo2-icon"
            alt=""
            src={chainscapeGamesLogo2}
            style={chainscapeGamesLogo2IconStyle}
          />
          <b className="logo" style={lOGOStyle}>
            LOGO
          </b>
          {showLogoCHAINSCAPEGAMESPixelA && (
          // {showLogoCHAINSCAPEGAMESPixelA && (      INFO 1
            <img
              className="logo-chainscape-games-pixel-ar"
              alt=""
              src={logoCHAINSCAPEGAMESPixelA}
              style={logoCHAINSCAPEGAMESPixelArStyle}
            />
          )}
          <img
            className="logo-chainscape-games-pixel-ar1"
            alt=""
            src={logoCHAINSCAPEGAMESPixelA1}
            style={logoCHAINSCAPEGAMESPixelAr1Style}
          />
          <img
            className="chainscapegameslogo2-icon1"
            alt=""
            src={chainscapeGamesLogo21}
            style={chainscapeGamesLogo2Icon1Style}
          />
          {image56Icon && (
            <img
              className="image-56-icon"
              alt=""
              src={image56}
              style={image56IconStyle}
            />
          )}
          <img
            className="image-71-icon"
            alt=""
            src={image71}
            style={image71IconStyle}
          />
          <img className="ld-icon" alt="" src={ld} style={ldIconStyle} />
          <img
            className="ludere-orig-alt"
            alt=""
            src={lUDEREOrigAlt}
            style={lUDEREOrigAltStyle}
          />
          {lUDEREOrigIcon && (
            <img
              className="ludere-orig-icon"
              alt=""
              src={lUDEREOrig}
              style={lUDEREOrigIconStyle}
            />
          )}
        </div>
        <div className="chainscapegameslogo2-group" style={frameDiv3Style}>
          <img
            className="chainscapegameslogo2-icon2"
            alt=""
            src={chainscapeGamesLogo22}
            style={chainscapeGamesLogo2Icon2Style}
          />
          <b className="logo1" style={lOGO1Style}>
            LOGO
          </b>
        </div>
        <div className="component-1">
          <div className="inch-frame">
            <div className="inch2" style={inch1Style}>
              16th
            </div>
          </div>
        </div>
        <div
          className="chainscape-games-web3-container"
          style={chainscapeGamesWeb3ContainerStyle}
        >
          <p className="p" style={pStyle}>
            {prop}
          </p>
          <p className="chainscape-games">
            <b style={chainscapeGamesStyle}>{chainscapeGames}</b>
          </p>
          <p className="p" style={web3GameStudioStyle}>
            {web3GameStudio}
          </p>
          <p className="p" style={roundRockTXStyle}>
            {roundRockTX}
          </p>
        </div>
      </div>
      <div
        className="art-director-and-container"
        style={artDirectorAndContainerStyle}
      >
        <p className="chainscape-games">
          <i style={artDirectorAndStyle}>{artDirectorAndUXLead}</i>
        </p>
        <ul
          className="led-multinational-art-departme"
          style={ledMultinationalArtDepartmeStyle}
        >
          <li className="spearheaded-the-aesthetic-for">
            <span style={ledMultinationalArtStyle}>
              {ledMultinationalArtDepart}
            </span>
          </li>
          <li style={{ display: bulletTwo === "" ? "none" : "auto" }}>
            <span>
              {bulletTwo}
            </span>
          </li>
          <li style={{ display: bulletThree === "" ? "none" : "auto" }}>
            <span>
              {bulletThree}
            </span>
          </li>
          <li style={{ display: bulletFour === "" ? "none" : "auto" }}>
            <span>
              {bulletFour}
            </span>
          </li>
          <li style={{ display: bulletFive === "" ? "none" : "auto" }}>
            <span>
              {bulletFive}
            </span>
          </li>
          <li style={{ display: bulletSix === "" ? "none" : "auto" }}>
            <span>
              {bulletSix}
            </span>
          </li>
          {/* <li className="spearheaded-the-aesthetic-for">
            <span>
              spearheaded the aesthetic for the game world, characters, and user
              interface/experience, working closely with the CEO/Founder to
              ensure alignment with overall product vision
            </span>
          </li>
          <li className="spearheaded-the-aesthetic-for">
            <span>
              designed and led multimedia production of high quality infinity
              card NFT collection in four weeks
            </span>
          </li>
          <li>
            <span>
              developed a vision for 3d game version to capture potential pivot
            </span>
          </li>
          <li style={{ display: testBullet1 === "" ? "none" : "auto" }}>
            <span>
              {testBullet1}
            </span>
          </li> */}
        </ul>
      </div>
    </div>
  );
};

export default EXPContentComponent;

import React from 'react'

import './Heading.css'

const Heading = ( { resumeMode, setResumeMode, contentOpacity, lockMode, resumeModeViewToggle, VIEW_TOGGLE_ANIM_TIME, headerTextFading, setHeaderTextFading } ) => {


function headerClickHandler () {
    setResumeMode( !resumeMode )
    setHeaderTextFading(true)

}

    return (
        <div className="main-heading-container">
            {/* <div className='heading'> */ }
            <div className={ 'heading' + ( lockMode ? ' heading-locked' : '' ) }>
                {/* <div className="business-card" style={{ opacity: resumeMode ? '0' : '1', pointerEvents: resumeMode ? 'none' : 'auto', transition: resumeMode ? '' : 'opacity 350ms ease-in-out'  }}> */ }
                <div className="business-card" style={ { opacity: contentOpacity, pointerEvents: resumeModeViewToggle ? 'none' : 'auto', transition: `opacity ${ VIEW_TOGGLE_ANIM_TIME }ms ease-in-out` } }>

                    <div>Michael Glovin</div>
                    {/* { headerTextFading && <div>zzz</div> } */}
                    { !lockMode && (
                        <div> 310 579 5011 </div>
                    ) }





                    {/* <div>310 579 5011</div> */ }
                </div>



                <div
                    // className="resume-div resume-div-text"
                    className={ `resume-div resume-div-text ${ resumeMode ? "resume-div-text-dark" : "" }` }
                    // className={ `resume-div resume-div-text ${ resumeMode ? "resume-div-text-dark" : "" } ${ headerTextFading ? "resume-div-text-fading" : "" }` }
                    // className={ `resume-div resume-div-text  ${ headerTextFading ? "resume-div-text-fading" : "" }` }
                    // style={ { transition: headerTextFading ? '' : '0ms all' } }
                    // style={ { transition: headerTextFading ? '' : '0ms all' } }
                    // style={ { transition: headerTextFading ? '500ms all' : '' } }
                    // style={ { transition: headerTextFading ? '1000ms all' : '' } }
                    style={ { transition: headerTextFading ? '1000ms all' : '' } }
                    // style={ { transition: headerTextFading ? '1100ms all' : '' } }
                    // style={ { color: headerTextFading ? '' : 'red' } }
                    // onClick={ () => setResumeMode( !resumeMode ) }
                    onClick={ headerClickHandler}
                    // text-content={ `<- Portfolio` }
                    text-content={ `Portfolio` }
                    // text-content={ `←  Portfolio` }
                >
                    { resumeMode ? (
                        <span>Portfolio</span>
                    ) : (
                        <span>Resume</span>
                    ) }
                </div>

            </div>
        </div>
    )
}

export default Heading
import { useMemo } from "react";
import "./SubDivider.css";
const SubDivider = ({
  subDividerSubDivider,
  subDividerIconMaxHeight,
  subDividerIconWidth,
  subDividerIconPosition,
  subDividerIconHeight,
}) => {
  const subDividerIconStyle = useMemo(() => {
    return {
      maxHeight: subDividerIconMaxHeight,
      width: subDividerIconWidth,
      position: subDividerIconPosition,
      height: subDividerIconHeight,
    };
  }, [
    subDividerIconMaxHeight,
    subDividerIconWidth,
    subDividerIconPosition,
    subDividerIconHeight,
  ]);

  return (
    <img
      className="sub-divider-icon"
      alt=""
      src={subDividerSubDivider}
      style={subDividerIconStyle}
    />
  );
};

export default SubDivider;

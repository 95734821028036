
/* eslint-disable no-restricted-globals */


import React, { forwardRef, useState, useEffect, useLayoutEffect } from 'react'

import './CarouselSingleItem.css'

import pointerSVG from '../../assets/pointer.svg'

export const CarouselSingleItem = forwardRef( function CarouselSingleItem( { item, carouselVisible }, ref ) {
    // const viewWidth = 45
    // const viewWidth = 45vw + 2vw + 160px + 96px
    // const viewWidth = `${45 + 2}vw`;
    // const viewWidth = 47;
    // const viewWidth = `${45 + 2}vw + 160px + 96px`

    const [ interactiveHovered, setInteractiveHovered ] = useState( false )
    const [ displayOverlay, setDisplayOverlay ] = useState( true )
    const [ fadeTimer, setFadeTimer ] = useState( null )
    const [ touchableStatus, setTouchableStatus ] = useState( true )




    // WIDTH VARIABLES

    // const vwFullDesk = 45
    // const vwSmallDesk = 60 // same conversion
    // const vwPortraitMobile = 100 // doesn't need conversion
    // const vwLandscapeMobile = 95 //+ mobile thumb and gap but no 96?



    const vwFullDesk = 43.5

    const vwSmallDesk = 58
    const vwSmallTablet = 60
    // const vwSmallTablet = 62.5

    const vwPortraitMobile = 100

    const vwLandscapeMobile = 78.5











    const THUMB_WIDTH = 160
    const THUMB_WIDTH_MOBILE = 128
    const THUMB_WIDTH_SMTABLET = 152

    // const maxHeight = 100
    // const maxHeight = 80
    const maxHeight = 75.5

    // WIDTH FUNCTIONS

    // function widthConverter( viewType ) {

    //     const convertedWidth = ( window.innerWidth * ( viewType + 2 ) / 100 ) + THUMB_WIDTH + 96
    //     return convertedWidth

    // }

    // const baseFrameWidthHandler = () => {
    //     const determinedWidth = window.innerWidth <= 1024
    //         ? window.innerHeight > window.innerWidth ? vwPortraitMobile : vwLandscapeMobile
    //         : window.innerWidth > 1920 ? vwFullDesk : vwSmallDesk
    //     return determinedWidth
    // }


    function baseFrameWidthHandler() {
        let determinedWidth

        switch ( true ) {
            // case window.innerWidth <= 1024 && window.innerWidth >= 1280 && window.innerHeight < window.innerWidth: // is this only for landscape??
            case window.innerWidth >= 1024 && window.innerWidth <= 1280: // was flipped omg
                // console.log( `case is small tablet lanscape` )
                determinedWidth = ( vwSmallTablet + 4 ) / 100 * window.innerWidth + THUMB_WIDTH_SMTABLET + 20
                break
            case window.innerWidth <= 1024 && window.innerHeight > window.innerWidth:
                // console.log( `case is mobile portrait` )
                determinedWidth = vwPortraitMobile / 100 * window.innerWidth
                break
            case window.innerWidth <= 1024 && window.innerHeight <= window.innerWidth:
                // console.log( `case is mobile landscape` )
                determinedWidth = ( ( vwLandscapeMobile + 3.5 + 15 ) / 100 * window.innerWidth )
                break
            case window.innerWidth > 1920:
                // console.log( `case is desk ` )
                determinedWidth = ( window.innerWidth * ( vwFullDesk + 2 ) / 100 ) + THUMB_WIDTH + 96 + 20
                break
            default:
                // console.log( `case is small desk ` )
                // determinedWidth = ( window.innerWidth * ( vwSmallDesk + 2 ) / 100 ) + THUMB_WIDTH + 96 + 20 + 72 // doesn't need the mat margin?
                determinedWidth = ( window.innerWidth * ( vwSmallDesk + 1.5 ) / 100 ) + THUMB_WIDTH + 96 + 20
                break

            // **
            // this must be adding gap width (+2)  ?
            // maybe do the whole calc then subtract the mat margin?
            // **
        }

        // console.log(`determined width : ${determinedWidth}`); q q

        return determinedWidth
    }

    function getMaxHeightStatus( viewWidth ){


        if ( (item.ratio * viewWidth) >= (( maxHeight / 100 ) * window.innerHeight) ) { // max desired height vs. max allowed height
            return true
        } else {
            return false  
        }

    }



    const getDynamicFrameHeight = () => {

        const _viewWidth = baseFrameWidthHandler()  // should be returning a pixel value == single-view-width without mat-margin

        const _maxHeightStatus = getMaxHeightStatus( _viewWidth )

        // console.log(`single view max height status`, _maxHeightStatus) // test

        if ( _maxHeightStatus == true ) {
            return `${ maxHeight }vh` // return max height if desired height is more than or equal to max
            // return `${( maxHeight / 100 ) * window.innerHeight }px` // return max height if desired height is more than or equal to max
        }
        else if ( _maxHeightStatus == false ) {
            return `${ item.ratio * _viewWidth }px` // return calculated height if desired height is less than max
        }

    }

    const getDynamicFrameWidth = () => {

        // ratio is height/width

        const _viewWidth = baseFrameWidthHandler()

        const _maxHeightStatus = getMaxHeightStatus( _viewWidth )

        if ( _maxHeightStatus == true ) {
            
            return `${ maxHeight * ( 1 / item.ratio ) }vh` // in max height case, return corresponding width
            // return `${ ( maxHeight / 100 ) * window.innerHeight * ( 1 / item.ratio ) }px` // in max height case, return corresponding width
        }
        else if ( _maxHeightStatus == false ) {
            // return `${ item.ratio * _viewWidth }px` 
            return `${ _viewWidth }px` // I think that means if we're not maxing out the height we're maxing out the width
        }


        // const calcedHeight = ( item.ratio * _viewWidth ) >=
        //     ( maxHeight / 100 ) * window.innerHeight
        //     ? maxHeight * ( window.innerHeight / 100 )
        //     // : item.ratio * viewWidth
        //     : item.ratio * _viewWidth

        // // console.log( `(1/item.ratio) * calcedHeight || 1/${ item.ratio }*${ calcedHeight }` )

        // const calcedWidth = ( 1 / item.ratio ) * calcedHeight
        // // return 500
        // return calcedWidth
    }



    const [ viewWidth, setViewWidth ] = useState( baseFrameWidthHandler() )

    const [ dynamicFrameHeight, setDynamicFrameHeight ] = useState( getDynamicFrameHeight() )
    const [ dynamicFrameWidth, setDynamicFrameWidth ] = useState( getDynamicFrameWidth() )


    useEffect ( () => {
    // useLayoutEffect( () => {




        let timeoutId = null

        const handleResize = () => {



            // if ( timeoutId ) {
            //     clearTimeout( timeoutId )
            // }

            // // frameWidth.current = window.innerWidth <= 1024
            // //     ? window.innerHeight > window.innerWidth ? vwPortraitMobile : vwLandscapeMobile
            // //     : window.innerWidth > 1920 ? vwFullDesk : vwSmallDesk


            // timeoutId = setTimeout( () => {

            //     setTimeout( () => {

            //         // setViewWidth( window.innerWidth <= 1024
            //         //     ? window.innerHeight > window.innerWidth ? vwPortraitMobile : vwLandscapeMobile
            //         //     : window.innerWidth > 1920 ? vwFullDesk : vwSmallDesk )

            //         // setViewWidth(baseFrameWidthHandler())

            //         setDynamicFrameHeight( getDynamicFrameHeight() )
            //         setDynamicFrameWidth( getDynamicFrameWidth() )


            //     }, 1 )

            // }, 250 )

            setDynamicFrameHeight( getDynamicFrameHeight() )
            setDynamicFrameWidth( getDynamicFrameWidth() )



        }



        const handleOrientationChange = () => {


            // if ( timeoutId ) {
            //     clearTimeout( timeoutId )
            // }


            // timeoutId = setTimeout( () => {

            //     setTimeout( () => {



            //         setDynamicFrameHeight( getDynamicFrameHeight() )
            //         setDynamicFrameWidth( getDynamicFrameWidth() )


            //     }, 1 )

            // }, 250 )



            setDynamicFrameHeight( getDynamicFrameHeight() )
            setDynamicFrameWidth( getDynamicFrameWidth() )


        }

        window.addEventListener( 'resize', handleResize )
        // window.addEventListener( 'orientationchange', handleOrientationChange )
        screen.orientation.addEventListener( 'change', handleOrientationChange )


        return () => {

            if ( timeoutId ) {
                clearTimeout( timeoutId )
            }
            window.removeEventListener( 'resize', handleResize )
            // window.removeEventListener( 'orientationchange', handleOrientationChange )
            screen.orientation.removeEventListener( 'change', handleOrientationChange )

        }
    }, [] )

    // const viewWidth = ( window.innerWidth * ( 45 + 2 ) / 100 ) + THUMB_WIDTH + 96
    // 👃🏻 full width 👃🏻
    // 45vw desktop width + 2vw gap width + thumb width + 96?? (why 96?)

    // const viewWidth = window.innerWidth * ( 45 / 100 )
    // 👃🏻 45 vw 👃🏻

    // ALSO EDIT CarouselSingleView.css 👃🏻👃🏻👃🏻


    // const viewWidth = window.innerWidth * ( 45 / 100 )
    // const viewWidth = 48;
    //  calc(var(--frame-width) + var(--pillar-gap) + var(--thumb-width) + 96px)
    // --frame-width: 45vw;
    // --pillar-gap: 2vw;
    // --thumb-width: 160px;
    // --thumb-ratio: .53;
    // const maxHeight = 70

    // console.log( `item ratio (${ item.ratio }) * viewWidth (${ viewWidth }) [[= ${ item.ratio * viewWidth }]] is equal to or larger than maxheight (${ maxHeight }) / 100 * window.innerheight(${ window.innerHeight })  [[= ${ ( maxHeight / 100 ) * window.innerHeight }]] ${ ( item.ratio * viewWidth ) >=  // correlating height based on total width
    //     ( maxHeight / 100 ) * window.innerHeight }`
    // )



    useEffect( () => {
        return () => {
            if ( fadeTimer ) {
                clearTimeout( fadeTimer )
            }
        }
    }, [ fadeTimer ] )


    const handleMouseEnter = () => {

        setTouchableStatus( false )

        if ( fadeTimer ) {
            clearTimeout( fadeTimer )

        }

        setDisplayOverlay( false )

        const timerID = setTimeout( () => {
            setInteractiveHovered( true )
            // console.log(`timer fired!`)
        }, 600 )


        setFadeTimer( timerID )
        // setTimeout( () => {
        //     setInteractiveHovered( true )
        // }, 300 )
    }

    // const handleTouch = () => {

    //     if ( fadeTimer ) {
    //         clearTimeout( fadeTimer )

    //     }

    //     // setDisplayOverlay( false )
    //     setInteractiveHovered(false)

    //     const timerID = setTimeout( () => {
    //         setInteractiveHovered( true )
    //         // console.log(`timer fired!`)
    //     }, 600 )


    //     setFadeTimer( timerID )
    //     // setTimeout( () => {
    //         //     setInteractiveHovered( true )
    //         // }, 300 )
    // }

    const handleMouseLeave = () => {

        setTouchableStatus( true )

        if ( fadeTimer ) {
            clearTimeout( fadeTimer )
            // console.log(`timer cancelled!`)
        }

        setInteractiveHovered( false )
        // setDisplayOverlay( true )


        setTimeout( () => {
            setDisplayOverlay( true )

        }, 1 )
    }



    return (
        <div>
            { item.type === 'image' && (
                <div className="single-single-img-container">
                    <img className="carousel-single-img" src={ item.payloadItem } ref={ ref } />
                </div>
            ) }
            {/* { item.type === 'video' && (
                <div className="single-single-img-container">
                    <video width="320" height="240" loop autoPlay muted ref={ ref }>
                        <source src={ item.payloadItem } type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            ) } */}
            { item.type === 'video' && (
                <div className="single-single-img-container">
                    <video
                        width="100%"
                        height="100%"
                        loop
                        autoPlay
                        muted
                        style={ { objectFit: 'contain' } }
                        ref={ ref }
                    >
                        <source src={ item.payloadItem } type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            ) }
            {/* { ( item.type === 'figmaInteractive' ) && (
                // <div className="single-single-img-container"  ref={ ref } style={{ height:item.frameHeight, pointerEvents:'none'}}>
                <div className="single-single-img-container" ref={ ref } style={ { height: item.frameHeight, touchAction: 'manipulation' } }>
                    <iframe
                        width={ item.frameWidth }
                        height={ item.frameHeight }
                        // width='100%'
                        src={ item.payloadItem }
                        loading='lazy'
                        // loading="eager"
                        allow='fullscreen'
                        style={ { border: 'none' } }
                    // cursor='crosshair'



                    // style={{border:'none', borderRadius:'25px'}}

                    />
                </div >
            ) } */}
            { item.type === 'figmaInteractive' && (
                // <div className="single-single-img-container"  ref={ ref } style={{ height:item.frameHeight, pointerEvents:'none'}}>
                // <div className="single-single-img-container" ref={ ref } style={{ height: `${Math.min((item.ratio * viewWidth), maxHeight)}vw` }}>
                <div
                    className="single-single-img-container"
                    ref={ ref }
                    // onMouseEnter={ handleMouseEnter }
                    onMouseLeave={ handleMouseLeave }
                    onPointerEnter={ handleMouseEnter }
                    // onPointerLeave={ handleMouseLeave}
                    // onTouchStartCapture={handleMouseEnter}
                    // onTouchStart={ handleMouseEnter }
                    // onTouchEnd={ handleMouseLeave }
                    // onTouch={ handleMouseEnter }
                    // style={ {
                    //     // height: `${ ( ( item.ratio * viewWidth ) / 100 ) * window.innerWidth >=
                    //     height: `${ ( item.ratio * viewWidth ) >=  // correlating height based on total width
                    //         ( maxHeight / 100 ) * window.innerHeight
                    //         ? `${ maxHeight }vh`
                    //         // ? `${item.ratio * viewWidth}px`
                    //         // : `${ item.ratio * viewWidth }vw`
                    //         // : `${ item.ratio * viewWidth }vw + + 160px + 96px`
                    //         // : `${ item.ratio * viewWidth }vw`
                    //         // : `${item.ratio * parseFloat(viewWidth)}`
                    //         // : `${item.ratio * parseFloat(viewWidth)}`
                    //         : `${ item.ratio * viewWidth - 1 }px`
                    //         // : `${ maxHeight }vh`
                    //         }`,
                    //     position: 'relative'
                    // } }
                    style={ {
                        // height: `${ ( ( item.ratio * viewWidth ) / 100 ) * window.innerWidth >=
                        //     ( maxHeight / 100 ) * window.innerHeight
                        //     ? `${ maxHeight }vh`
                        //     : `${ item.ratio * viewWidth }vw`
                        //     }`,
                        height: dynamicFrameHeight,
                        width: dynamicFrameWidth,
                        position: 'relative',
                    } }
                >

                    { !interactiveHovered && <div style={ {
                        position: 'absolute',
                        // top: '50%',
                        // left: '50%',
                        // transform: 'translate(-50%, -50%)',

                        zIndex: '1',
                        height: '100%',

                        // width: item.ratio * viewWidth >= ( maxHeight / 100 ) * window.innerHeight ? `${ ( maxHeight / 100 ) * ( window.innerHeight / item.ratio ) }px` : '100%',
                        width: dynamicFrameWidth,
                        // backgroundColor: 'rgba(240,240,240, .75)',

                        // good 
                        // backgroundColor: 'rgba(245,245,245, .75)',


                        // backgroundColor: 'rgba(245,245,245, .75)',
                        backgroundColor: 'rgba(245,245,245, .5)',

                        // backgroundColor: 'rgba(230,230,230, .85)',
                        backdropFilter: 'blur(.5px)',
                        // backdropFilter: 'blur(2px)',
                        // backdropFilter: 'blur(1.5px)',
                        // backdropFilter: 'blur(1px)',

                        // background: `linear-gradient(338deg, #F0F0F0 0%, rgba(240, 240, 240, 0.75) 99.58%)`,
                        // background:`linear-gradient(338deg, #F0F0F0 0%, rgba(240, 240, 240, 0.15) 99.58%)`,

                        // backgroundColor: interactiveHovered ? 'rgba(240,240,240, .5)' : 'rgba(240,240,240, .75)',
                        // opacity: interactiveHovered ? '0%' : '75%',
                        // transition: 'opacity 350ms ease-in-out',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        gap: '16px',
                        opacity: `${ displayOverlay ? 100 : 0 }%`,
                        // pointerEvents: 'none',
                        pointerEvents: `${ touchableStatus ? 'auto' : 'none' }`,
                        // transition: 'opacity 400ms ease-in-out',
                        // transition: 'opacity 250ms',
                        // transition: 'opacity 225ms',
                        // transition: 'opacity 350ms',
                        // transition: `${ displayOverlay ? 'opacity 550ms ': 'opacity 225ms' }`,
                        // transition: `${ displayOverlay ? 'opacity 550ms ': 'opacity 275ms' }`,
                        // transition: `${ displayOverlay ? 'opacity 250ms ': 'opacity 125ms' }`,
                        // transition: `${ displayOverlay ? 'opacity 300ms ': 'opacity 150ms' }`,
                        // transition: `${ displayOverlay ? 'opacity 275ms ' : 'opacity 0ms' }`,
                        // transition: `${ displayOverlay ? 'opacity 275ms ' : 'opacity 125ms' }`,
                        // transition: `${ displayOverlay ? 'opacity 575ms ' : 'opacity 150ms' }`,
                        // transition: `${ displayOverlay ? 'opacity 575ms ' : 'opacity 145ms' }`,
                        // transition: `${ displayOverlay ? 'opacity 575ms ' : 'opacity 120ms' }`,
                        // transition: `${ displayOverlay ? 'opacity 350ms ' : 'opacity 75ms' }`,
                        // transition: `${ displayOverlay ? 'opacity 200ms ' : 'opacity 200ms' }`,
                        transition: `${ displayOverlay ? 'opacity 200ms ' : 'opacity 75ms' }`,


                        // transition: 'opacity 400ms',
                    } }

                    >
                        <div style={ { width: '100px', height: '100px' } }>
                            <img
                                // src="/assets/pointer.svg" // Use the relative path to your SVG file
                                src={ pointerSVG }
                                style={ { width: '100%', height: '100%' } }
                            />
                        </div>
                        <div style={ {
                            fontSize: '36px',
                            fontWeight: 'bold',
                            // color:'white',
                            // color:'#464646',
                            color: 'black',
                            // textShadow: '0px 0px 12px rgba(245,245,245, .3)',
                            // textShadow: '0px 0px 12px rgba(245,245,245, .4)',
                            // textShadow: '0px 0px 12px rgba(245,245,245, .5)',
                            position: 'relative',
                        } }
                            className='figma-interactive-font-div'
                            text-content='Figma Interactive'

                        >Figma Interactive</div>
                   
                    </div> }

              







                    { carouselVisible ? (
                        <iframe
                            height={ '100%' }
                            width={ '100%' }
                            src={ item.payloadItem }
                            loading="lazy"
                            allow="fullscreen"
                            style={ { border: 'none' } }
                            className=' item-border-style'
                        />
                    ) : (
                        <div
                            style={ {
                                width: '100%',
                                height: '100%',
                                border: 'none',
                                // other identical characteristics
                            } }
                        />
                    ) }







                </div>
            ) }

            {/* { item.type === 'figmaSlideshow' && (
                <div
                    className="single-single-img-container"
                    ref={ ref }
                    style={ { height: item.frameHeight - 0, pointerEvents: 'none' } }
                >
                    <iframe
                        // width={ item.frameWidth }
                        height={ item.frameHeight }
                        width="100%"
                        src={ item.payloadItem }
                        loading="lazy"
                        // loading="eager"
                        allow="fullscreen"
                        style={ { border: 'none' } }
                    // cursor='crosshair'

                    // style={{border:'none', borderRadius:'25px'}}
                    />
                </div>
            ) } */}


            { item.type === 'figmaSlideshow' && (
                // <div className="single-single-img-container"  ref={ ref } style={{ height:item.frameHeight, pointerEvents:'none'}}>
                // <div className="single-single-img-container" ref={ ref } style={{ height: `${Math.min((item.ratio * viewWidth), maxHeight)}vw` }}>
                <div
                    className="single-single-img-container"
                    ref={ ref }
                    style={ {
                        height: `${ ( ( item.ratio * viewWidth ) / 100 ) * window.innerWidth >=
                            ( maxHeight / 100 ) * window.innerHeight
                            ? `${ maxHeight }vh`
                            : `${ item.ratio * viewWidth }vw`
                            }`,
                        pointerEvents: 'none'
                    } }
                >
                    <iframe
                        width={ item.frameWidth }
                        height={ '100%' }
                        // width='100%'
                        src={ item.payloadItem }
                        loading="lazy"
                        // loading="eager"
                        allow="fullscreen"
                        style={ { border: 'none' } }
                    // cursor='crosshair'

                    // style={{border:'none', borderRadius:'25px'}}
                    />
                </div>
            ) }
        </div>
    )
} )

// split rendering by type and therefore point to different refs for width?

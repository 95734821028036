// next .. loop ref into state? (update of state triggers ref change with use effect?)

import React, { useRef, useState, useEffect, useLayoutEffect } from 'react'

import './CarouselView.css'

import { CarouselItem } from './CarouselItem'
// import CarouselNavButton from './CarouselComponents/CarouselNavButton'

// const CarouselView = ( { content, carouselPosition, setCarouselPosition, posTransition, setActiveHoverState, itemRefArray, setIndicatorTransitionState } ) => {
const CarouselView = ( { content,
    carouselPosition,
    setCarouselPosition,
    // posTransition,
    setActiveHoverState,
    itemRefArray,
    setIndicatorTransitionState,
    sliderRef,
    sliderContents,
    MARGIN_WIDTH,
    NET_ITEM_WIDTH,
    GAP_WIDTH,
    carouselBackup,
    mouseDownHandler,
    mouseUpHandler,
    carouselVisible,
} ) => {
   




    useEffect(() => {
        // sliderContents.current.style.transform = `translateX(-${MARGIN_WIDTH }px)`
    
    
    }, [])
    


    const sliderInit = () => {
        if ( sliderRef.current ) {
            sliderRef.current.classList.add( "instant-init" )
            sliderContents.current.classList.add( "carousel-contents-dragging" )

            // sliderContents.current.style.transform = `translateX(-${MARGIN_WIDTH }px)`;


            sliderRef.current.style.height = `${ itemRefArray.current[ 0 ].offsetHeight - 1 }px`
  
            sliderContents.current.style.gap = `${ GAP_WIDTH }px`
            setTimeout( () => {
                sliderRef.current.classList.remove( "instant-init" )
                sliderContents.current.classList.remove( "carousel-contents-dragging" )
            }, 0 )

        }
    }


 
    function mouseEnterHandler() {
        setIndicatorTransitionState( true )

        if ( window.innerWidth > 1024 ) {
            setActiveHoverState( true )

        }
        // setTimeout(() => {
        //     setActiveHoverState( true )
        // }, 0);
        setTimeout( () => {
            setIndicatorTransitionState( false )
        }, 201 )

    }
    function mouseLeaveHandler() {
        setIndicatorTransitionState( true )
        setActiveHoverState( false )
        // setTimeout(() => {
        //     setActiveHoverState( false )
        // }, 0);
        setTimeout( () => {
            setIndicatorTransitionState( false )
        }, 201 )

    }

    function mouseDownMiddleHandler( event ) {
        sliderRef.current.classList.add( "dragging" )
        mouseDownHandler( event )

    }

    // OUTPUT ----------------------------------------------


    return (
        <div
            ref={ sliderRef }
            className="Carousel"
            // onMouseDown={ ( event ) => mouseDownHandler( event ) }
            onMouseDown={ ( event ) => mouseDownMiddleHandler( event ) }
            onMouseUp={ ( event ) => mouseUpHandler( event ) }
            onTouchStart={ ( event ) => mouseDownHandler( event ) }
            onTouchEnd={ ( event ) => mouseUpHandler( event ) }
            // onMouseEnter={ () => setActiveHoverState( true ) }
            // onMouseLeave={ () => setActiveHoverState( false ) }
            onMouseEnter={ mouseEnterHandler }
            onMouseLeave={ mouseLeaveHandler }

        // style={{ height: itemRefArray.current[ 0 ].offsetHeight }}
        >
            {/* <div style={{position:"absolute", fontSize:'24px',} }>{carouselPosition}</div>   */ }
            {/* <div style={ { position: "absolute", fontSize: '24px', } }>{ window.innerWidth }</div> */ }
            {/* <div style={ { height: '20px', marginLeft: `${ MARGIN_WIDTH - 20 }px`, opacity: '0%' } }>.</div> */}
            <div className='carousel-contents' ref={ sliderContents } style={ {  marginLeft: `${ MARGIN_WIDTH }px`, marginRight:`${ MARGIN_WIDTH }px`, transform:`translateX(-${MARGIN_WIDTH }px`}}>
            {/* <div className='carousel-contents' ref={ sliderContents } style={ {  marginLeft: `${ MARGIN_WIDTH }px`, marginRight:`${ MARGIN_WIDTH }px`,}}> */}
                { content.map( ( item, index ) => {
                    // needs id?
                    // !!!!!!! filter first for categories?
                    return (



                        <div

                            
                            // className='test1'
                            style={ {
                                // marginLeft: index === 0 ? `${ MARGIN_WIDTH }px` : '0px',
                           
                            } }
                            onLoad={ () => {
                                // console.log(`firing pre init, type should be ${item.type} ------ ${item.zonk} ${item.zonk} ${item.zonk} ${item.zonk}@@@@@@@@@@@@@@@@@@ DESC IS ${item.desc}`)
                                if ( index === 0 ) {
                                    // if (index === content.length - 1) {
                                    sliderInit() // Make sure you have a function to set the scrollLeft
                                }
                            } }
                            onLoadedData={ () => {
                                if ( index === 0 ) {
                                    // if (index === content.length - 1) {
                                    sliderInit() // Make sure you have a function to set the scrollLeft
                                }
                            } }
                        >
                            { <CarouselItem item={ item } carouselVisible={ carouselVisible } ref={ ( element ) => ( itemRefArray.current[ index ] = element ) } /> }</div>

                    )
                } ) }
            </div>
            {/* <div style={{height:'20px',width:`${MARGIN_WIDTH}px`, color:'red', backgroundColor:'blue', opacity:'20%'}}>3</div> */ }
            {/* <div style={{height:'20px',marginLeft:`${MARGIN_WIDTH-20}px`, color:'red', backgroundColor:'pink', opacity:'0%'}}>.</div> */ }
            {/* <div style={ { height: '20px', marginLeft: `${ MARGIN_WIDTH - 20 }px`, opacity: '0%' } }>.</div> */}
        </div>
    )
}

export default CarouselView

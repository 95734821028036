import { React, useEffect, useState } from 'react'


// const SlideIndicator = ( { content, carouselPosition, activeHoverState,indicatorTransitionState } ) => {
const SlideIndicator = ( { content, carouselPosition, indicatorTransitionState } ) => {

const [carouselPosChangeState, setCarouselPosChangeState] = useState(false)


// useEffect(() => {
//     setCarouselPosChangeState(false)
//     // setTimeout(() => {
//     //     setCarouselPosChangeState(false)
//     // }, 1000);
// }, [activeHoverState])


// useEffect(() => {

//     // console.log(carouselPosition)
//     setCarouselPosChangeState(true)
//     // setTimeout(() => {
//     //     setCarouselPosChangeState(false)
//     // }, 1000);

// }, [carouselPosition])

// ${ carouselPosChangeState ? 'indicator-dot-border-transition' : '' }  

    return (
        <div
            // className={ `indicator-container-html ${ activeHoverState ? '' : 'inactive-indicator' }` }
            className={ `indicator-container-html` }
        >
            { content.map( ( _, index ) => {
                return (

                    <div
                        key={ index }
                        className={ 
                            `indicator-dot-html 
                            ${ carouselPosition === index ? 'active-indicator-dot' : '' } 
                       
                            ${ indicatorTransitionState ? 'indicator-dot-border-transition' : '' }
                            ` }

                    />

                )
            } ) }
        </div>
    )
}

export default SlideIndicator



     // ${ !activeHoverState && carouselPosition === index ? 'inactive-hover-active-indicator-dot' : '' }
                            // ${ activeHoverState ? '' : 'inactive-hover-indicator-dot' }  
import React, { useState } from 'react';

import './Unlocker.css'

function Unlocker({ setLockMode }) {
  const [password, setPassword] = useState('');
//   const [isLocked, setIsLocked] = useState(true);
const [error, setError] = useState('');

  const handlePasswordChange = (event) => {

    const cleanedPass = event.target.value.replace(/\s+/g, '').toLowerCase()
    // setPassword(event.target.value);
    setPassword(cleanedPass);
    setError('')
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    
    // Replace 'your_password' with the actual password you want to check against
    if (password === 'tapir') {
      setLockMode(false);
    //   onUnlock(true); // Callback to unlock other features
    } else {
        setError('Incorrect password. Please try again.');
    }
  };

  return (
    <div 
    className='unlocker-div'
    >
      
        <form onSubmit={handleSubmit}>
          {/* <label style={{ marginRight: '16px' }}> */}
          {/* <label style={{ marginRight: '4px' }}> */}
          <label >
            Enter Password:
            <input
            //   type="password"
              value={password}
              onChange={handlePasswordChange}
              style={{ marginLeft: '8px', marginRight:'8px' }}
              className='pw-input'
            />
          </label>
          <button type="submit">Unlock</button>
          {/* {error && <p style={{ color: 'red' }}>{error}</p>} */}
          {error && <p style={{  color:'rgb(240, 0, 0)'}}>{error}</p>}
        </form>
      
    </div>
  );
}

export default Unlocker;
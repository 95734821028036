import React, { useEffect, useState, useRef } from 'react'

import './ChicletNoRole.css'


const ChicletNoRole = ( { portfolioData, index } ) => {
// const Chiclet = ( { portfolioData, index, sectionPosRefArray } ) => {


    // const [scrollLock, setScrollLock] = useState(false)


 // most recent

    // const bodyScrollState = useRef( false )
    // const chicletRef = useRef( null )


    // const scrollToSection = ( index ) => {
    //     // setScrollLock(true)
    //     // scrollDestination.current = sectionPosRefArray.current[ index ].getBoundingClientRect().top - 10
    //     // document.body.classList.add('disable-interactions');

    //         // chicletRef.current.classList.add('disable-interactions');
    //         sectionPosRefArray.current[ index ].scrollIntoView( {
    //             behavior: 'smooth',
    //             // alignToTop: 'false',
    //         } )
            
    //     }
     


    //     setTimeout(() => {
    //         chicletRef.current.classList.remove('disable-interactions');
            
    //     }, 3000);


    

 // most recent  


    // const scrollDestination = useRef(0)



    //   useEffect(() => {


    //     const handleScroll = () => {
    //             const scrollY = window.scrollY;
    //             console.log(`Scroll Y: ${scrollY}, scroll destination ${scrollDestination.current} `);


    //             if (scrollY >= scrollDestination.current){
    //                         console.log('should unlock')
    //                         console.log('should unlock')
    //                         console.log('should unlock')
    //                         console.log('should unlock')
    //                         setScrollLock(false)
    //                     }
    //     }

    //     if (scrollLock){
    //         window.addEventListener('scroll', handleScroll);
    //         document.body.classList.add('disable-interactions')
    //         document.body.style.overflow = 'hidden'
    //         const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
    //         console.log(`WIDTH ------------- ${scrollbarWidth} - inner win ${window.innerWidth}, client width ${document.documentElement.clientWidth}`)
    //         document.body.style.marginRight = `${scrollbarWidth}px`;
    //     }

    //     else{
    //         document.body.classList.remove('disable-interactions')
    //         document.body.style.overflow = 'visible'
    //         window.removeEventListener('scroll', handleScroll)
    //         document.body.style.marginRight = '0';
    //     }
    //     return () => {
    //     }
    //   }, [scrollLock])





        // window.scrollY < sectionPosRefArray.current[ index ].getBoundingClientRect().top

        // console.log(sectionPosRefArray.current[ index ].getBoundingClientRect().top)
        // document.body.style.overflow = 'hidden';
        // document.body.classList.remove('disable-interactions');

   

    return (
        <div 
        className='chiclet-target'
        // onClick={ () => scrollToSection( index )}
        > 

<div className="chiclet-container chiclet-container-norole">


        <div 
        // ref={chicletRef}
        className="chiclet-no-role"     
         >
            <div
                className="chiclet-no-role-img-div"
                // style={ {
                //     backgroundImage: `  linear-gradient(313deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.08) 59.90%, rgba(0, 0, 0, 0.00) 100%), url(${ portfolioData[ index ].roleHeaderImage })`,
                // } }
            >
                {/* <div style={{backgroundColor:'red', height: '71%', width:'100%', marginTop:'auto',}}> */}
                {/* <div style={{ height: '71%', width:'100%', marginTop:'auto',}}> */}
                {/* <div style={{ height: '71%', width:'100%', marginTop:'auto',}}> */}
                <div className="space-emulator" style={{ }}>
                <div className="chiclet-no-role-title-container">
                    <div text-content={ portfolioData[ index ].roleTitle } className="chiclet-no-role-text">{ portfolioData[ index ].roleTitle }</div>
                </div>
                </div>

                <div className="gradient-style"></div>
                <img src={ portfolioData[ index ].roleHeaderImage }></img>
            </div>
            {/* <div className="chiclet-desc-container">
                <div className="desc-role">{ portfolioData[ index ].roleRole }</div>
                
                <div className="desc-dates">{ portfolioData[ index ].roleTime }</div>
            </div> */}
        </div>

</div>

        </div>
    )
}

export default ChicletNoRole
{
    /* <div className='chiclet-bg-img' style={{ backgroundImage: linear-gradient(to bottom, rgba(245, 246, 252, 0.52), rgba(117, 19, 93, 0.73)), : `url(${img1})` }}> help </div> */
}
{
    /* <div className="chiclet-img-div"><img className="chiclet-img" src={img1} alt="" /></div> */
}

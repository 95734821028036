import React from 'react'

import './CarouselThumbs.css'
import ThumbPosIndicator from './ThumbPosIndicator'
import ThumbPosIndicatorMobile from './ThumbPosIndicatorMobile'

const CarouselThumbs = ( {
	content,
	carouselPosition,
	// instantCarouselPos,
	documentScrollState,
	updateCarousel,
	posChangeOriginator,
	hasDesc,
	// setArrowSliding,
	// setArrowMoveState,
	// setArrowPosition
} ) => {
	return (
		<div className="thumb-container-container" style={{paddingTop:`${hasDesc ? ``: `51px`}`}}>
			{/* <ThumbPosIndicator length={content.length}/> */ }
			<div className="thumb-pos-div">
			<ThumbPosIndicator content={ content } carouselPosition={ carouselPosition } carouselLength={content.length} posChangeOriginator={posChangeOriginator} />
			</div>
			<div className="thumb-pos-div-mobile">
			<ThumbPosIndicatorMobile content={ content } carouselPosition={ carouselPosition } carouselLength={content.length} posChangeOriginator={posChangeOriginator} />

			</div>
			
			{/* <ThumbPosIndicator content={ content } carouselPosition={ carouselPosition } carouselLength={content.length} /> */}

			{/* <div style={ { display: 'flex', flexDirection: 'column', height: '100%', width:`25px`, background:'blue' } }>
				
			</div> */}
			<div className="thumb-container" >

				{ content.map( ( item, index ) => {
					return (
						<div>
							<div className='thumb-immediate-frame'
								onMouseEnter={ () => {




									// if ( !documentScrollState )  {
									if ( !documentScrollState && !document.body.classList.contains('dragging-global') )  {
										posChangeOriginator.current = `thumb`
										return (
											
											( updateCarousel( index, 'instant' ) ) )
									}
								} }
							>
								{ <img
									className={ `thumb ${ carouselPosition === index ? 'thumb-current' : '' }` }
									//  src={ item.payloadItem} /> } 
									//  src={item.type === 'image' ? item.payloadItem : item.thumb} /> } 
									src={ item.thumb } /> }
							</div>
						</div>
					)
				} ) }
			</div>
		</div>
	)
}

export default CarouselThumbs

/* eslint-disable no-restricted-globals */

import React, { useState, useEffect } from 'react'

import Chiclet from './Chiclet'
import ChicletNoRole from './ChicletNoRole'

import './MainNav.css'

const MainNav = ( { portfolioData, sectionPosRefArray, setDocumentScrollState, pinchBlocker, } ) => {




    // const nativeWidth = 979.48
    // const nativeWidth = chiclet_size * length of content array + gapsize * length of content array - 1
    const nativeWidth = ( 183.4 * portfolioData.length ) + ( 12 * ( portfolioData.length - 1 ) )

    const zoomFinder = () => {

        const viewWidth = window.innerWidth
        const viewHeight = window.innerHeight
        // const maxVw = 1024 // Maximum width where zoom applies
        // const maxVw = 1280 // Maximum width where zoom applies
        const maxVw = nativeWidth + 128
        // const maxLandscapeVw = 1600
        const zoomSize = 90 // Maximum zoom level
        // const zoomSize = 85 // Maximum zoom level
        // const zoomSize = 65 // Maximum zoom level
        const minVw = 769

        // if ( viewWidth <= maxVw && viewWidth > viewHeight  ) {
        if ( (viewWidth <= maxVw && viewWidth > minVw) || (viewWidth <= maxVw && viewWidth > viewHeight )) {

            const newZoomLevel = viewWidth * ( zoomSize ) / nativeWidth
            return newZoomLevel
        } else {
            return null
        }
    }

    const [ zoomLevel, setZoomLevel ] = useState( zoomFinder )





    // 979.48

    const [ isMobile, setIsMobile ] = useState( false )


    useEffect( () => {



        // const userAgent = navigator.userAgent
        // const isMobileDevice = /Mobi|Android/i.test( userAgent )
        // // const isSmallScreen = window.innerWidth <= 768 // Adjust as needed
        // const isSmallScreen = window.innerWidth <= 1024 // Adjust as needed

        // if ( isMobileDevice || isSmallScreen ) {
        //     setIsMobile( true )
        // }



        // const calculateZoom = () => {

        //     if ( !isMobile ) {

        //         const newZoomLevel = zoomFinder()

        //         setZoomLevel( newZoomLevel )
        //     }


        // }



        const calculateZoom = () => {


            if (pinchBlocker == true){
                return
            }


            const newZoomLevel = zoomFinder()

            setZoomLevel( newZoomLevel )



        }


        const orientationChangeHandler = () => {


            const newZoomLevel = zoomFinder()

            setZoomLevel( newZoomLevel )

            // setTimeout( () => {

            //     const newZoomLevel = zoomFinder()

            //     setZoomLevel( newZoomLevel )
            // }, 5 )

            // setTimeout( () => {

            //     const newZoomLevel = zoomFinder()

            //     setZoomLevel( newZoomLevel )
            // }, 10 )



        }


        calculateZoom()

        window.addEventListener( 'resize', calculateZoom )
        // window.addEventListener( 'orientationchange', orientationChangeHandler )
        screen.orientation.addEventListener('change', orientationChangeHandler);

        return () => {
            window.removeEventListener( 'resize', calculateZoom )
            // window.removeEventListener( 'orientationchange', orientationChangeHandler )
            screen.orientation.removeEventListener('change', orientationChangeHandler);
        }
    }, [] )












    const scrollToSection = ( index ) => {
        setDocumentScrollState( true )
        sectionPosRefArray.current[ index ].scrollIntoView( {
            behavior: 'smooth',
        } )
        setTimeout( () => {
            setDocumentScrollState( false )
        }, 800 )
    }

    return (
        <div className='main-nav-container' >



            {
                portfolioData.map( ( item, index ) => (

                    // <div onClick={()=>(scrollToSection(index))} >
                    <div onMouseDown={ () => ( scrollToSection( index ) ) } >
                        <div style={ { zoom: zoomLevel ? `${ zoomLevel }%` : 'unset' } }>
                            {/* <Chiclet portfolioData={portfolioData} onClick={() => scrollToSection(index)} /> */ }
                            {/* <Chiclet portfolioData={portfolioData} index={index} sectionPosRefArray={sectionPosRefArray} /> */ }
                            { item.roleRole ? (
                                <Chiclet portfolioData={ portfolioData } index={ index } />
                            ) : (
                                <ChicletNoRole portfolioData={ portfolioData } index={ index } />
                            ) }
                            {/* <Chiclet portfolioData={portfolioData} onClick={() => console.log('help!')} /> */ }
                        </div>
                    </div>
                ) )
            }



        </div >
    )
}

export default MainNav








//     <div onClick={ () => ( console.log( `winner?` ) ) }>
//      <Chiclet portfolioData={ portfolioData } sectionPosRefArray={ sectionPosRefArray } />
// </div>

// {
//     portfolioData.map((item, index) => (

//         <div>
//             {/* <Chiclet portfolioData={portfolioData} onClick={() => scrollToSection(index)} /> */}
//             <Chiclet portfolioData={portfolioData} onClick={() => console.log('help!')} />
//         </div>
//     ))
// }

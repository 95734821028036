import { useMemo } from "react";
import "./Education.css";
const Education = ({
  componentTitle,
  educationWidth,
  educationHeight,
  educationPosition,
  educationLeft,
  educationFontSize,
}) => {
  const educationStyle = useMemo(() => {
    return {
      width: educationWidth,
      height: educationHeight,
      position: educationPosition,
    };
  }, [educationWidth, educationHeight, educationPosition]);

  const education1Style = useMemo(() => {
    return {
      left: educationLeft,
      fontSize: educationFontSize,
    };
  }, [educationLeft, educationFontSize]);

  return (
    <div className="education" style={educationStyle}>
      <b className="education1" style={education1Style}>
        {componentTitle}
      </b>
    </div>
  );
};

export default Education;




import React from 'react'

import "./ReturnArrow.css"

const ReturnArrow = ( { appDivRef, resumeMode } ) => {

    const documentHeight = document.documentElement.scrollHeight


    return (
        <div
            // className="return-arrow-container"
            className={ `return-arrow-container ${ resumeMode ? "return-arrow-resume-mode" : "" }` }

            // style={{marginTop:'calc(100vh + var(--offset))'}}
            // style={{marginTop:`calc(${documentHeight}px + var(--offset))`}}
            // style={ { marginTop: `calc(${ documentHeight }px + 256px` } }
            onClick={ () => { window.scrollTo( { top: 0, behavior: 'smooth' } ) } }
            // onClick={ () => { window.scrollTo( { top: 0} ) } }
        >
     
            <svg xmlns="http://www.w3.org/2000/svg" width="63" height="64" viewBox="0 0 63 64" fill="none">
                <path d="M30.3074 16.9081L10.6466 37.957C8.85564 39.8743 10.2153 43.0048 12.8389 43.0048L49.8717 43.0048C52.4954 43.0048 53.855 39.8743 52.0641 37.957L32.4033 16.9081C31.8365 16.3012 30.8742 16.3012 30.3074 16.9081Z" fill="#888888" />
                <rect x="23.916" y="63.3545" width="30.2399" height="15.4629" rx="3" transform="rotate(-90 23.916 63.3545)" fill="#888888" />
                <rect x="62.709" y="8.84082" width="62.7087" height="8.84082" rx="3" transform="rotate(180 62.709 8.84082)" fill="#888888" />
            </svg>


        </div>
    )
}
export default ReturnArrow

import React, { useEffect, useRef, useState } from 'react'

import './Resume.css'

import Letter133 from './ResumeComponents/Letter133'
import FigmaEmbed from './CarouselComponents/FigmaEmbed'
import VideoEmbed from './CarouselComponents/VideoEmbed'
// import TestSkills from './ResumeComponents/TestSkills'


function Resume( { resumeMode, resumeOpacity, setResumeOpacity, VIEW_TOGGLE_ANIM_TIME, VIEW_TOGGLE_ANIM_TIME_OUT, fadeInResume, compensationTest, setResumeMode} ) {

  // const [fadeIn, setFadeIn] = useState(false)

  // const sourceDivRef = useRef( null )
  // const targetDivRef = useRef( null )



  // useEffect( () => {
  //   if ( sourceDivRef.current && targetDivRef.current ) {
  //     const sourceDivHeight = sourceDivRef.current.clientHeight
  //     targetDivRef.current.style.height = `${ sourceDivHeight }px`
  //   }
  // }, [] )



  useEffect( () => {
    // setFadeIn(true)
    if ( fadeInResume ) {

      setResumeOpacity( 1 )
    }


  }, [ fadeInResume ] )


  return (

    <div>
      <div
        className="resume-container"
        // ref={ sourceDivRef }
        // style={ { opacity: resumeOpacity, transition: !resumeMode ? `opacity ${VIEW_TOGGLE_ANIM_TIME_OUT}ms ease-in-out` : `opacity ${VIEW_TOGGLE_ANIM_TIME}ms ease-in-out`, zIndex:'2'}}>
        // style={ { opacity: resumeOpacity, transition: !resumeMode ? `opacity ${VIEW_TOGGLE_ANIM_TIME_OUT}ms ease-in-out` : `opacity ${VIEW_TOGGLE_ANIM_TIME}ms ease-in-out`, zIndex:'2', backgroundColor: compensationTest? 'red' : 'blue'}}>
        style={ { opacity: resumeOpacity, transition: !resumeMode ? `opacity ${VIEW_TOGGLE_ANIM_TIME_OUT}ms ease-in-out` : `opacity ${VIEW_TOGGLE_ANIM_TIME}ms ease-in-out`, zIndex:'2', position: compensationTest? 'relative' : 'absolute'}}>
        {/* // style={ { opacity: resumeOpacity, transition: !resumeMode ? `opacity ${ VIEW_TOGGLE_ANIM_TIME_OUT }ms ease-in-out` : `opacity ${ VIEW_TOGGLE_ANIM_TIME }ms ease-in-out`, zIndex: '2', position: compensationTest ? 'relative' : 'absolute' } }> */}
        {/* <div className="resume-container" style={ { opacity: resumeOpacity, transition: `opacity ${VIEW_TOGGLE_ANIM_TIME}ms ease-in-out`}}> */ }
        {/* <div className="resume-container" style={ { opacity: resumeOpacity, transition: 'opacity 350ms ease-in-out' }}> */ }
        {/* <div className="resume-container" style={ { opacity: fadeIn ? '1' : '0', transition: 'opacity 50ms ease-in-out' }}> */ }
        {/* <div className="resume-container" style={ { opacity: fadeIn ? '1' : '0',}}> */ }
        {/* <div className="resume-container" style={ { opacity: fadeIn ? '1' : '0', transition: 'opacity 350ms ease-in-out' }}>  */ }

        {/* NEXT FIXES - 1-- fix role images 2-- fix grey line widths 2px? 3px? 3-- language grey line spacing */ }
        {/* then work on handling video, figma embed? */ }

        {/* <FigmaEmbed 
iframeSrc="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FqVPHBE0lGv3CCzmFxOJIDE%2FA9-Accomodations-App---fin%3Fpage-id%3D0%253A1%26node-id%3D204-887%26starting-point-node-id%3D204%253A887%26scaling%3Dcontain%26mode%3Ddesign%26t%3DSnPGwJnRdhiUCDaH-1"
height='600'
width='400'
/> */}
        {/* <div>
<FigmaEmbed 
iframeSrc="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FqVPHBE0lGv3CCzmFxOJIDE%2FA9-Accomodations-App---fin%3Fpage-id%3D0%253A1%26node-id%3D204-887%26starting-point-node-id%3D204%253A887%26scaling%3Dcontain%26mode%3Ddesign%26t%3DSnPGwJnRdhiUCDaH-1&hide-ui=1"
height='600'
width='400'
/>
</div>

<VideoEmbed /> */}


        <Letter133 resumeMode={resumeMode} setResumeMode={setResumeMode} />



      </div>
      {/* <div ref={targetDivRef} style={{width:`100%`, backgroundColor:'blue', position:'relative', zIndex:'0'}}></div> */ }
      {/* <div ref={targetDivRef} style={{width:`100%`, position:'relative', zIndex:'0', userSelect:'none', pointerEvents:'none', opacity:'0%'}}></div> */ }
      {/* <div ref={targetDivRef} style={{width:`100%`, position:'relative', zIndex:'0', userSelect:'none', pointerEvents:'none', backgroundColor:'blue'}}></div> */ }
      {/* <div ref={targetDivRef} style={{width:`100%`, position:'relative', zIndex:'0', userSelect:'none', pointerEvents:'none', backgroundColor:'blue', opacity: resumeOpacity, transition: !resumeMode ? `opacity ${VIEW_TOGGLE_ANIM_TIME_OUT}ms ease-in-out` : `opacity ${VIEW_TOGGLE_ANIM_TIME}ms ease-in-out`,}}></div> */ }

      {/* <div ref={targetDivRef} style={{width:`100%`, position:'relative', zIndex:'0', userSelect:'none', pointerEvents:'none', backgroundColor:'blue',}}></div> */ }
      {/* <div ref={targetDivRef} style={{width:`100%`, position:'relative', zIndex:'0', userSelect:'none', pointerEvents:'none', backgroundColor:'blue', display: showRelativeBlock ? 'block' : 'none'}}></div> */ }
    </div>
  )
}

export default Resume


import React from 'react'

const RightArrow = ( { activeHoverState, terminalState } ) => {
  return (
    <div
      className={ `arrows ${ terminalState ? 'terminal-arrows' : '' } ${ activeHoverState ? '' : 'inactive-arrows'
        }` }
        // style={{cursor:`${ terminalState ? 'no-drop' : 'pointer' }`}}
    >








      
      <svg
        xmlns="http://www.w3.org/2000/svg"
        // width="35"
        // height="33"
        width="34"
        height="32"
        // width="34.5"
        // height="32.5"
        // width="33.5"
        // height="31.5"
        // viewBox="-0.5 -0.5 34 32"
        // viewBox="-0.5 -0.5 33 31"
        viewBox="-0.5 -0.5 34 31"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.2796 0.168526L32.1926 13.7268C32.5206 14.0063 32.5206 14.513 32.1926 14.7925L16.2796 28.3508C15.8251 28.738 15.1256 28.415 15.1256 27.8179V21.0646H0.7C0.313401 21.0646 0 20.7512 0 20.3646V8.49256C0 8.10596 0.313401 7.79256 0.7 7.79256H15.1256V0.701349C15.1256 0.104275 15.8251 -0.218704 16.2796 0.168526Z"
        />
      </svg>

      {/* { terminalState ? <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="22" height="22" rx="0.7" fill="none" />
      </svg> :
        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="33" viewBox="-0.5 -0.5 34 32" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2796 0.168526L32.F1926 13.7268C32.5206 14.0063 32.5206 14.513 32.1926 14.7925L16.2796 28.3508C15.8251 28.738 15.1256 28.415 15.1256 27.8179V21.0646H0.7C0.313401 21.0646 0 20.7512 0 20.3646V8.49256C0 8.10596 0.313401 7.79256 0.7 7.79256H15.1256V0.701349C15.1256 0.104275 15.8251 -0.218704 16.2796 0.168526Z" />
          \      </svg> } */}



{/* { terminalState ? <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="22" height="22" rx="0.7" fill="none" />
      </svg> :
      <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="33"
      viewBox="-0.5 -0.5 34 32"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.2796 0.168526L32.1926 13.7268C32.5206 14.0063 32.5206 14.513 32.1926 14.7925L16.2796 28.3508C15.8251 28.738 15.1256 28.415 15.1256 27.8179V21.0646H0.7C0.313401 21.0646 0 20.7512 0 20.3646V8.49256C0 8.10596 0.313401 7.79256 0.7 7.79256H15.1256V0.701349C15.1256 0.104275 15.8251 -0.218704 16.2796 0.168526Z"
      />
    </svg>}

 */}


    </div>
  )
}
export default RightArrow

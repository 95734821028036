import { useMemo } from "react";
import "./RectangleComponent.css";

import doubleDivider from '../../assets/doubleDivider.svg'
// import {ReactComponent as doubleDivider} from '../../assets/doubleDivider.svg'

const RectangleComponent = ({
  rectangleDivWidth,
  rectangleDivHeight,
  rectangleDivPosition,
  rectangleDivBackgroundColor,
}) => {
  const rectangleDivStyle = useMemo(() => {
    return {
      width: rectangleDivWidth,
      height: rectangleDivHeight,
      position: rectangleDivPosition,
      backgroundColor: rectangleDivBackgroundColor,
    };
  }, [
    rectangleDivWidth,
    rectangleDivHeight,
    rectangleDivPosition,
    rectangleDivBackgroundColor,
  ]);

  return (
    <div className="rectangle-parent" style={rectangleDivStyle}>
      <div style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    // backgroundColor: 'cyan'
}}>
    <img src={doubleDivider} alt="" />
</div>
      {/* <img src={doubleDivider} alt="Divider" /> */}
      {/* <doubleDivider/> */}
      <div className="component-child" />
    </div>
  );
};

export default RectangleComponent;

import { React, useEffect } from 'react'

import Unlocker from './Unlocker'
import MainNav from './MainNav'
import ContentSection from './ContentSection'

const ContentView = ( {
    setLockMode,
    resumeModeViewToggle,
    contentOpacity,
    setContentOpacity,
    VIEW_TOGGLE_ANIM_TIME,
    VIEW_TOGGLE_ANIM_TIME_OUT,
    portfolioData,
    sectionPosRefArray,
    setDocumentScrollState,
    documentScrollState,
    lockMode,
    resumeMode,
    fadeInContent,
    pinchBlocker,
} ) => {


    // useEffect( () => {
    //     // setFadeIn(true)
    //     setContentOpacity( 1 )


    // }, [] )




    useEffect( () => {
        // setFadeIn(true)
        if ( fadeInContent ) {

            setContentOpacity( 1 )
        }


    }, [ fadeInContent ] )


    return (
        <div>
            { lockMode ? (

                // <div style={ { marginTop: '48px', opacity: resumeModeViewToggle ? '1' : '0', } }>
                <div style={ { opacity: contentOpacity, transition: resumeMode ? `opacity ${ VIEW_TOGGLE_ANIM_TIME_OUT }ms ease-in-out` : `opacity ${ VIEW_TOGGLE_ANIM_TIME }ms ease-in-out` } }>
                <div style={ { marginTop: '48px' } }>
                    <Unlocker setLockMode={ setLockMode } />
                </div>
                </div>
            ) : (

                <div style={ { opacity: contentOpacity, transition: resumeMode ? `opacity ${ VIEW_TOGGLE_ANIM_TIME_OUT }ms ease-in-out` : `opacity ${ VIEW_TOGGLE_ANIM_TIME }ms ease-in-out` } }>
                    <MainNav portfolioData={ portfolioData } sectionPosRefArray={ sectionPosRefArray } setDocumentScrollState={ setDocumentScrollState } pinchBlocker={ pinchBlocker } />
                    { portfolioData.map( ( item, index ) => {

                        return (

                            <div >
                                { < ContentSection
                                    sectionData={ portfolioData[ index ] }
                                    documentScrollState={ documentScrollState }
                                    sectionPosRefArray={ sectionPosRefArray }
                                    index={ index }
                                    pinchBlocker={ pinchBlocker }
                                /> }

                            </div>

                        )
                    } ) }


                </div>
            ) }
        </div>
    )
}

export default ContentView
import React, { useRef, useState, useEffect } from 'react'
import RightArrow from './CarouselResources/right_arrow'
import LeftArrow from './CarouselResources/left_arrow'

import './ArrowContainer.css'

function ArrowContainer( {
  arrowDirection,
  moveFunction,
  arrowPosition,
  setArrowPosition,
  arrowMoveState,
  setArrowMoveState,
  activeHoverState,
  setActiveHoverState,
  arrowSliding,
  setArrowSliding,
  carouselPosition,
  content,
  itemRefArray,
  mouseIsOver,
  setIndicatorTransitionState
} ) {
  const tallDivRef = useRef( null )
  const arrowContainer = useRef( null )

  const [ timer, setTimer ] = useState( null )
  const [ firstClick, setFirstClick ] = useState( true )

  useEffect( () => {
    return () => {
      if ( timer ) {
        clearTimeout( timer )
      }
    }
  }, [ timer ] )


  // ERROR ONLY FIRES IF I DON"T DO ANYTHING ELSE FIRST


//   useEffect( () => {

//       const imgBounds = itemRefArray.current[ carouselPosition ].getBoundingClientRect()
//       setArrowPosition( ( ( ( imgBounds.top + window.scrollY ) + ( imgBounds.bottom + window.scrollY ) ) / 2 ) - arrowContainer.current.clientHeight / 2 )


// // testing firstrun

//   }, [ ] )





// set init position to fix bug

  useEffect( () => {
  
    const imgBounds = itemRefArray.current[ carouselPosition ].getBoundingClientRect()
    setArrowPosition( ( ( ( imgBounds.top + window.scrollY ) + ( imgBounds.bottom + window.scrollY ) ) / 2 ) - arrowContainer.current.clientHeight / 2 )

  }, [] )




  useEffect( () => {
    // console.log( `carousel pos has changed, is mouse over? ${ mouseIsOver.current }` )
    // console.log(`ARROWCONTAINER.JS:: inside arrow container, carousel position is ${carouselPosition}`)
    // console.log( `fired carousel position change, mouse over is ${ mouseIsOver.current }` )
    if ( !mouseIsOver.current ) {
      const imgBounds = itemRefArray.current[ carouselPosition ].getBoundingClientRect()
      setArrowPosition( ( ( ( imgBounds.top + window.scrollY ) + ( imgBounds.bottom + window.scrollY ) ) / 2 ) - arrowContainer.current.clientHeight / 2 )
    }
    // console.log(`ARROWCONTAINER.JS:: mouse is over is ${mouseIsOver.current}`)

  }, [ carouselPosition ] )


  // useEffect( () => {

  //   setArrowSliding( true )

  // }, [] )

  // useEffect( () => {
  //   // const imgBounds = itemRefArray.current[ carouselPosition ].getBoundingClientRect()
  //   // setArrowPosition( ( ( ( imgBounds.top + window.scrollY ) + ( imgBounds.bottom + window.scrollY ) ) / 2 ) - arrowContainer.current.clientHeight / 2 )
  //   arrowContainerUpdate( carouselPosition )
  //   // setArrowPosition('absolute')

  // }, [] )

  // useEffect( () => {
  //   const imgBounds = itemRefArray.current[ carouselPosition ].getBoundingClientRect()
  //   setArrowPosition( ( ( ( imgBounds.top + window.scrollY ) + ( imgBounds.bottom + window.scrollY ) ) / 2 ) - arrowContainer.current.clientHeight / 2 )

  //   // setArrowSliding( true )
  //   arrowContainerUpdate( carouselPosition )
  //   // mouseIsOver.current = false
  //   // setArrowMoveState( null )
  //   // setArrowPosition(0)
  //   setArrowSliding( false )

  // }, [] )



  function arrowContainerUpdate( pos ) {
    const imgBounds = itemRefArray.current[ pos ].getBoundingClientRect()
    setArrowPosition( ( ( imgBounds.top + window.scrollY ) + ( imgBounds.bottom + window.scrollY ) ) / 2 - arrowContainer.current.clientHeight / 2 )
  }


  function clickHandler( e ) {

    if ( timer ) {
      clearTimeout( timer )

    }

    mouseIsOver.current = true

    // const yVal = e.clientY + window.scrollY
    // const parentRect = tallDivRef.current.getBoundingClientRect()
    if ( firstClick ) {
      arrowContainerUpdate( carouselPosition )
      setFirstClick( false )
    }
    // setArrowPosition('0')
    setArrowMoveState( 'absolute' )
    moveFunction()
  }



  function mouseLeaveHandler() {


    if ( timer ) {
      clearTimeout( timer )

    }



    // setActiveHoverState( false )
    // setArrowMoveState( 'null' )
    setArrowSliding( true )
    arrowContainerUpdate( carouselPosition )
    mouseIsOver.current = false


    // setTimeout( () => {

    //   setArrowMoveState( null )
    //   // setArrowPosition(0)
    //   setArrowSliding( false )
    // }, 300 )


    const timerID = setTimeout( () => {
      setArrowMoveState( null )
      // setArrowPosition(0)
      setArrowSliding( false )
    }, 300 )


    setTimer( timerID )
  }

  function mouseLeaveOuterHandler() {



    setIndicatorTransitionState( true )
    setActiveHoverState( false )
    // setTimeout(() => {
    //     setActiveHoverState( false )
    // }, 0);
    setTimeout( () => {
      setIndicatorTransitionState( false )
    }, 201 )

  }

  function mouseEnterHandler() {
    setIndicatorTransitionState( true )
    setActiveHoverState( true )
    // setTimeout(() => {
    //     setActiveHoverState( true )
    // }, 0);
    setTimeout( () => {
      setIndicatorTransitionState( false )
    }, 201 )
  }

  return (
    <div
      ref={ tallDivRef }
      className="arrow-container-container"
      onMouseEnter={ mouseEnterHandler }
      onMouseLeave={ mouseLeaveOuterHandler }

    // onMouseMove={ handleMouseMove }
    >
      <div
        ref={ arrowContainer }
        className={ `arrow-container ${ !activeHoverState ? 'inactive-arrow-container' : '' } ${ arrowSliding ? 'arrow-container-moving' : '' }` }
        onMouseLeave={ mouseLeaveHandler }
        onClick={ clickHandler }
        style={ {
          top: arrowPosition,
          position: arrowMoveState,
          cursor: `${ ( carouselPosition === content.length - 1 && arrowDirection === 'right' ) || ( carouselPosition === 0 && arrowDirection === 'left' ) ?
            'auto' :
            'pointer'
            }`,
          // opacity: `${ (carouselPosition === content.length - 1 && arrowDirection === 'right') || (carouselPosition === 0 && arrowDirection === 'left') ?
          // '0%':
          // '100%'
          // }`,
        } }
      >
        { arrowDirection === 'right' ? (
          <RightArrow
            activeHoverState={ activeHoverState }
            terminalState={ carouselPosition == content.length - 1 }
          />
        ) : (
          <LeftArrow
            activeHoverState={ activeHoverState }
            terminalState={ carouselPosition == 0 }
          />
        ) }
      </div>
    </div>
  )
}

export default ArrowContainer

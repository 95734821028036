/* eslint-disable no-restricted-globals */


import Property116Inch from "./Property116Inch"
import Education from "./Education"
import RectangleComponent from "./RectangleComponent"
import EXPContentComponent from "./EXPContentComponent"
import SubDivider from "./SubDivider"
import TestSkills from "./TestSkills"
import "./Letter133.css"

import React, { useState, useEffect, useRef } from 'react'
// import '../../assets/resume_images/undefined25.png'
// import ludorig from '../../../public/ludorig.png'


const Letter133 = ( { resumeMode, setResumeMode } ) => {

  const nativeWidth = 979.48
  // const nativeHeight = 2981.64
  // const nativeHeight = 3053.94
  const nativeHeight = 3033

  const portraitMode = useRef( null )
  const portraitModePrev = useRef( null )

  const runOnceFlag = useRef( false )

  function portraitModeUpdater() {
    if ( window.innerHeight > window.innerWidth ) {
      // console.log( `now portrait` )
      portraitMode.current = true
    }
    else {
      // console.log( `now landscape` )
      portraitMode.current = false
    }
  }

  const zoomFinder = () => {
    const viewWidth = window.innerWidth
    const maxVw = 1024 // Maximum width where zoom applies
    const zoomSize = 90 // zoom amount

    if ( viewWidth <= maxVw ) {

      const newZoomLevel = viewWidth * ( zoomSize ) / nativeWidth // should return a percentage value of the size vs. the native width (like 75% of native width -- returns the 75 (so needs div by 100))
      // console.log( `newZoomLevel`, newZoomLevel )
      return newZoomLevel
    } else {
      return null
    }
  }

  const [ zoomLevel, setZoomLevel ] = useState( zoomFinder() )
  // const zoomLevel = 30

  const [ isMobile, setIsMobile ] = useState( false )


  useEffect( () => {

    const calculateZoom = () => {

      const newZoomLevel = zoomFinder()
      setZoomLevel( newZoomLevel )

    }

    if ( runOnceFlag.current == false && window.innerWidth <= 1024 ) {
      portraitModeUpdater()
      portraitModePrev.current = portraitMode.current
      calculateZoom()
      setTimeout( () => {

        calculateZoom()
      }, 200 )



      triggerRepaint()
      setTimeout( () => {

        triggerRepaint()
      }, 1000 )
      setTimeout( () => {

        triggerRepaint()
      }, 2000 )




      runOnceFlag.current = true
    }

    const userAgent = navigator.userAgent
    const isMobileDevice = /Mobi|Android/i.test( userAgent )
    // const isSmallScreen = window.innerWidth <= 768 // Adjust as needed
    const isSmallScreen = window.innerWidth <= 1024 // Adjust as needed

    if ( isMobileDevice || isSmallScreen ) {
      setIsMobile( true )

    }


    const handleOrientationChange = () => {

      calculateZoom()
      // portraitModeUpdater()
      // portraitModePrev.current = portraitMode.current
    }


    const handleResize = () => {

     
      portraitModeUpdater()

      if ( portraitMode.current != portraitModePrev.current ) {

        calculateZoom()
        portraitModePrev.current = portraitMode.current
  
        setTimeout( () => {
          calculateZoom()
        }, 500 )
        setTimeout( () => {
          calculateZoom()
        }, 1000 )
      }










      // else if ( isMobile == false ) {
      //   calculateZoom()

      // }

    }





    // Event listener for window resize and orientation change
    window.addEventListener( 'resize', handleResize )
    // window.addEventListener( 'orientationchange', handleOrientationChange ) 
    screen.orientation.addEventListener( 'change', handleOrientationChange )

    

    // Cleanup the event listeners
    return () => {
      window.removeEventListener( 'resize', handleResize )
      // window.removeEventListener( 'orientationchange', handleOrientationChange )
      screen.orientation.removeEventListener( 'change', handleOrientationChange )

    }
  }, [] )



  //   useEffect( () => {
  //     if ( window.innerWidth <= 1024 ) {

  // console.log(`repaint time baby -------------------------`);      

  //       triggerRepaint()
  //       setTimeout( () => {

  //         triggerRepaint()
  //       }, 1000 )
  //       setTimeout( () => {

  //         triggerRepaint()
  //       }, 2000 )
  //     }
  //     return () => {

  //     }
  //   }, [] )



  const [ repaintTrigger, setRepaintTrigger ] = useState( false )
  /* eslint-disable */

  const triggerRepaint = () => {

    // Toggle the repaintTrigger state to trigger a repaint
    setRepaintTrigger( ( prevValue ) => !prevValue )
    const body = document.body
    body.style.display = 'none'
    // eslint-disable-next-line no-use-before-define
    body.offsetHeight // // eslint-disable-line no-use-before-define
    // body.offsetHeight; // Trigger layout recalculation
    body.style.display = ''

  }
  /* eslint-enable */


  function returnToPorfolio() {
    window.scrollTo( { top: 0, behavior: 'smooth' } )
    setResumeMode( !resumeMode )
    // setTimeout(() => {

    // window.scrollTo( { top: 0 } )
    // }, 0);
    // }, 125);
  }


  return (
    // <div className="letter-133" style={{zoom:`${zoomLevel}%`}}>
    // <div className="letter-133" style={ { zoom: zoomLevel ? `${ zoomLevel }%` : 'unset' } }>
    <div className="letter-container"
      style={ {
        position: "relative",
        // height:'400px',
        // height:'2981.64px',
        // height: `${zoomLevel * nativeHeight}px`,
        // height: zoomLevel ? `${zoomLevel / 100 * nativeHeight + 32}px` : '2981.64px',
        // height:'3010.45px',
        // backgroundColor:'pink',
        // width:'300px',
        // zoom:'50%'

        // height: zoomLevel ? `${ zoomLevel / 100 * nativeHeight + 48 }px` : '2981.64px',
        // height: zoomLevel ? `${ zoomLevel / 100 * nativeHeight + 128 }px` : '2981.64px',



        height: zoomLevel
          // ? window.innerWidth <= 768 
          ? ( window.innerWidth >= 1024 && window.innerHeight > window.innerWidth )
            // ? isMobile 
            ? `${ zoomLevel / 100 * nativeHeight + 64 }px`
            // ? `${ zoomLevel / 100 * nativeHeight + 96 }px`
            : `${ zoomLevel / 100 * nativeHeight + 48 }px`
          // : '2981.64px',
          // : `${nativeHeight + 64}px`,
          : `${nativeHeight + 21}px`,

          // I think the +64 would give a better offset but then diff between main view and resume view would have to be handled seperately 

        // height: zoomLevel ? `${ zoomLevel / 100 * nativeHeight + 48 }px` : '2981.64px',

        // height: zoomLevel ? `${ zoomLevel / 100 * nativeHeight + 64 }px` : '2981.64px',
        // height: zoomLevel ? `${ zoomLevel / 100 * nativeHeight }px` : '2981.64px',
        // marginBottom:'1vw',



      } }

    >

      {/* <div
        style={ {
          position: 'absolute',
          top: '-50px',
          left: '0px',
          height: '200px',
          width: '200px',
          backgroundColor: 'yellow',
          zIndex: '1',
        } }

      >



        <button onClick={ triggerRepaint }>Trigger Repaint</button>
      </div> */}



      <div className="letter-133" style={ {
        position: 'absolute',
        // width: '100%',
        // height: '100%', // Set dimensions for the inner div
        // transform: 'translate(-50%, -50%) scale(0.2)', // Scale the inner div down to 10%

        // fontSize:`.5em`



        // transform: `translate(-50%, -50%) scale(${zoomLevel / 100})`, // Scale the inner div down to 10%
        // transform: zoomLevel ? `translate(-50%, -50%) scale(${zoomLevel / 100})` : 'unset', // Apply scale based on zoomLevel
        // top: zoomLevel ? '50%' : '0',
        // top: zoomLevel ? '50%' : '0',
        //  fontSize:`12px`

        // --------------------------

        // top: '50%', // Align the top of the inner div to the vertical center of the outer div
        // left: '50%', // Align the left side of the inner div to the horizontal center of the outer div
        // transform: `translate(-50%, -50%) scale(${ zoomLevel / 100 })`, // Scale the inner div down to 10%
        transformOrigin: 'top',
        transform: zoomLevel ? `translate(-50%, 0%) scale(${ zoomLevel / 100 })` : 'unset', // Apply scale based on zoomLevel
        position: zoomLevel ? 'absolute' : 'relative',
        top: '0',
        left: zoomLevel ? '50%' : 'auto',
        color: repaintTrigger ? 'black' : 'black',


        // --------------------------


        // // top: '50%', // Align the top of the inner div to the vertical center of the outer div
        // // left: '50%', // Align the left side of the inner div to the horizontal center of the outer div
        // transformOrigin: 'top',
        // transform: `translate(-50%, 0%) scale(${ zoomLevel / 100 })`, // Apply scale based on zoomLevel
        // position: 'absolute',
        // top: '0',
        // left: '50%',
        // // visibility: repaintTrigger ? 'hidden' : 'visible', // Toggle visibility
        // // transform:  repaintTrigger ? `translate(-50%, 0%) scale(${ 30 / 100 })` : `translate(-50%, 0%) scale(${ 20 / 100 })` , // Apply scale based on zoomLevel
        // // color:'red'
        // // color:repaintTrigger ? 'red': 'blue',
        // color: repaintTrigger ? 'black' : 'black',





        // --------------------------



        // position: 'absolute',
        // // position: zoomLevel ? 'absolute' : 'relative',
        // // width: '100%',
        // // height: '100%', // Set dimensions for the inner div
        // // transform: 'translate(-50%, -50%) scale(0.2)', // Scale the inner div down to 10%


        // width: '100%',
        // height: '100%',
        // // width: zoomLevel ? '100%' : '979.48px', // Set dimensions for the inner div
        // // height: zoomLevel ? '100%' : '2981.64px', // Set dimensions for the inner div

        // // width: 979.48px;
        // //  height: 2981.64px;
        // top: zoomLevel ? '50%' : '0', // Align the top of the inner div to the vertical center of the outer div if zoomLevel is provided
        // left: zoomLevel ? '50%' : 'auto', // Align the left side of the inner div to the horizontal center of the outer div if zoomLevel is provided
        // top: '50%', // Align the top of the inner div to the vertical center of the outer div
        // left: '50%', // Align the left side of the inner div to the horizontal center of the outer div
        // // transform: zoomLevel ? `translate(-50%, -50%) scale(${zoomLevel / 100})` : 'unset', // Apply scale based on zoomLevel

        // transform: `translate(-50%, -50%) scale(${zoomLevel / 100})`, // Scale the inner div down to 10%














        // transform: zoomLevel ? `translate(-50%, -50%) scale(${zoomLevel / 100})` : 'unset', // Apply scale based on zoomLevel
        // translate: '(-50%, -50%)',
        // WebkitTransform: zoomLevel ? `scale(${ zoomLevel / 100 })` : 'unset',
        // transform: zoomLevel ? `scale(${ zoomLevel / 100 })` : 'unset',
        // transformOrigin: 'center center', // Zoom from the top center


      } }>


        <img className="letter-133-child" alt="" src="/undefined11.png" />
        <div className="image-70-parent">
          <img className="image-70-icon" alt="" src="/undefined12.png" />
          <img className="image-56-icon1" alt="" src="/undefined13.png" />
        </div>
        <div className="stack-skills">
          <img className="stack-skills-child" alt="" src="/undefined11.png" />
          <div className="frame-by-frame-animation-wrapper">
            <div className="frame-by-frame" />
          </div>
          <div className="d-modeling-parent">
            <div className="frame-by-frame">3D modeling</div>
            <Property116Inch
              positionNumber="6th"
              property116InchWidth="120.03px"
              property116InchHeight="19.21px"
              property116InchAlignSelf="unset"
              property116InchPosition="relative"
              property116InchTransform="unset"
              property116InchTransformOrigin="unset"
              frameDivBackgroundColor="#75cdff"
              inchTop="calc(50% - 4.8px)"
              inchLeft="calc(50% - 5.6px)"
            />
            <div className="frame-by-frame">digital sculpting</div>
            <Property116Inch
              positionNumber="6th"
              property116InchWidth="120.03px"
              property116InchHeight="19.21px"
              property116InchAlignSelf="unset"
              property116InchPosition="relative"
              property116InchTransform="unset"
              property116InchTransformOrigin="unset"
              frameDivBackgroundColor="#75cdff"
              inchTop="calc(50% - 4.8px)"
              inchLeft="calc(50% - 5.6px)"
            />
            <div className="frame-by-frame">{ `digital painting ` }</div>
            <Property116Inch
              positionNumber="6th"
              property116InchWidth="120.03px"
              property116InchHeight="19.21px"
              property116InchAlignSelf="unset"
              property116InchPosition="relative"
              property116InchTransform="unset"
              property116InchTransformOrigin="unset"
              frameDivBackgroundColor="#75cdff"
              inchTop="calc(50% - 4.8px)"
              inchLeft="calc(50% - 5.6px)"
            />
          </div>
          <div className="compositing-parent">
            <div className="frame-by-frame">compositing</div>
            <Property116Inch
              positionNumber="6th"
              property116InchWidth="155.24px"
              property116InchHeight="19.21px"
              property116InchAlignSelf="unset"
              property116InchPosition="relative"
              property116InchTransform="unset"
              property116InchTransformOrigin="unset"
              frameDivBackgroundColor="#75cdff"
              inchTop="calc(50% - 4.8px)"
              inchLeft="calc(50% - 5.6px)"
            />
            <div className="frame-by-frame">sketching</div>
            <Property116Inch
              positionNumber="6th"
              property116InchWidth="155.24px"
              property116InchHeight="19.21px"
              property116InchAlignSelf="unset"
              property116InchPosition="relative"
              property116InchTransform="unset"
              property116InchTransformOrigin="unset"
              frameDivBackgroundColor="#75cdff"
              inchTop="calc(50% - 4.8px)"
              inchLeft="calc(50% - 5.6px)"
            />
            <div className="frame-by-frame">perspective drawing</div>
          </div>
          <div className="animation-parent">
            <div className="animation">animation</div>
            <Property116Inch
              positionNumber="6th"
              property116InchWidth="116.83px"
              property116InchHeight="19.21px"
              property116InchAlignSelf="unset"
              property116InchPosition="relative"
              property116InchTransform="unset"
              property116InchTransformOrigin="unset"
              frameDivBackgroundColor="#75cdff"
              inchTop="calc(50% - 4.8px)"
              inchLeft="calc(50% - 5.6px)"
            />
            <div className="frame-by-frame">design thinking</div>
            <Property116Inch
              positionNumber="6th"
              property116InchWidth="116.83px"
              property116InchHeight="19.21px"
              property116InchAlignSelf="unset"
              property116InchPosition="relative"
              property116InchTransform="unset"
              property116InchTransformOrigin="unset"
              frameDivBackgroundColor="#75cdff"
              inchTop="calc(50% - 4.8px)"
              inchLeft="calc(50% - 5.6px)"
            />
            <div className="frame-by-frame">anatomy</div>
            <Property116Inch
              positionNumber="6th"
              property116InchWidth="116.83px"
              property116InchHeight="19.21px"
              property116InchAlignSelf="unset"
              property116InchPosition="relative"
              property116InchTransform="unset"
              property116InchTransformOrigin="unset"
              frameDivBackgroundColor="#75cdff"
              inchTop="calc(50% - 4.8px)"
              inchLeft="calc(50% - 5.6px)"
            />
          </div>
          <div className="ux-design-parent">
            <div className="frame-by-frame">ux design</div>
            <Property116Inch
              positionNumber="6th"
              property116InchWidth="182.45px"
              property116InchHeight="19.21px"
              property116InchAlignSelf="unset"
              property116InchPosition="relative"
              property116InchTransform="unset"
              property116InchTransformOrigin="unset"
              frameDivBackgroundColor="#75cdff"
              inchTop="calc(50% - 4.8px)"
              inchLeft="calc(50% - 6.4px)"
            />
            <div className="frame-by-frame">human-centered design</div>
            <Property116Inch
              positionNumber="6th"
              property116InchWidth="182.45px"
              property116InchHeight="19.21px"
              property116InchAlignSelf="unset"
              property116InchPosition="relative"
              property116InchTransform="unset"
              property116InchTransformOrigin="unset"
              frameDivBackgroundColor="#75cdff"
              inchTop="calc(50% - 4.8px)"
              inchLeft="calc(50% - 6.4px)"
            />
            <div className="frame-by-frame">teamwork</div>
            <Property116Inch
              positionNumber="6th"
              property116InchWidth="182.45px"
              property116InchHeight="19.21px"
              property116InchAlignSelf="unset"
              property116InchPosition="relative"
              property116InchTransform="unset"
              property116InchTransformOrigin="unset"
              frameDivBackgroundColor="#75cdff"
              inchTop="calc(50% - 4.8px)"
              inchLeft="calc(50% - 6.4px)"
            />
          </div>
        </div>
        <div className="border" />
        <div className="rectangle-group">
          <div className="group-child" />
          <div className="group-item" />
          <div className="group-inner" />
          <div className="rectangle-div" />
          <div className="group-child1" />
        </div>
        <div className="frame-group">
          <div
            style={ { pointerEvents: 'auto' } }
            className="michael-glovin-parent">
            <div className="frame-by-frame">
              <span>
                <b>Michael Glovin</b>
                {/* <b>{zoomLevel}</b> */ }
              </span>
              <span className="michaelglovingmailcom">
                <span>{ ` ` }</span>
                <span className="span">{ `| ` }</span>
                <span

                >{ `michaelglovin@gmail.com ` }</span>
                <span className="span">{ `| ` }</span>
                <span className="book-publisher">(310) 579-5011</span>
              </span>
            </div>
            <div className="portfolio">portfolio</div>
          </div>
          <Property116Inch
            positionNumber="qrtr"
            property116InchWidth="806.63px"
            property116InchHeight="28.81px"
            property116InchAlignSelf="unset"
            property116InchPosition="relative"
            property116InchTransform="unset"
            property116InchTransformOrigin="unset"
            frameDivBackgroundColor="#ff9393"
            inchTop="calc(50% - 4.8px)"
            inchLeft="calc(50% - 6.4px)"
          />
          <img className="frame-child" alt="" src="/undefined14.png" />
          <div className="edu-frame">
            <Property116Inch
              positionNumber="qrtr"
              property116InchWidth="806.63px"
              property116InchHeight="28.81px"
              property116InchAlignSelf="unset"
              property116InchPosition="relative"
              property116InchTransform="unset"
              property116InchTransformOrigin="unset"
              frameDivBackgroundColor="#ff9393"
              inchTop="calc(50% - 4.8px)"
              inchLeft="calc(50% - 6.4px)"
            />
            <Education
              componentTitle="Education"
              educationWidth="80.02px"
              educationHeight="19.21px"
              educationPosition="relative"
              educationLeft="1.25%"
            // educationFontSize=".9rem"
            />
            <Property116Inch
              positionNumber="qrtr"
              property116InchWidth="806.63px"
              property116InchHeight="28.81px"
              property116InchAlignSelf="unset"
              property116InchPosition="relative"
              property116InchTransform="unset"
              property116InchTransformOrigin="unset"
              frameDivBackgroundColor="#ff9393"
              inchTop="calc(50% - 4.8px)"
              inchLeft="calc(50% - 6.4px)"
            />
            <div className="edu-contents">
              <div className="master-of-science-integrated-d-parent">
                <div className="master-of-science-container">
                  <span className="michaelglovingmailcom">
                    <span>{ `2021 ` }</span>
                    <span className="span">|</span>
                  </span>
                  <span>
                    <span className="michaelglovingmailcom">{ ` ` }</span>
                    <b className="master-of-science">
                      Master of Science Integrated Design, Business, and
                      Technology
                    </b>
                    <span className="michaelglovingmailcom">{ ` ` }</span>
                  </span>
                  <span className="michaelglovingmailcom">
                    <span className="span">| </span>
                    <span>
                      {/* Iovine and Young Academy, University of Southern California */ }
                      University of Southern California, Iovine and Young Academy
                    </span>
                  </span>
                </div>
                <img className="image-57-icon" alt="" src="/undefined15.png" />
                <div className="frame-item" />
              </div>
              <Property116Inch
                positionNumber="8th"
                property116InchWidth="806.63px"
                property116InchHeight="14.4px"
                property116InchAlignSelf="unset"
                property116InchPosition="relative"
                property116InchTransform="unset"
                property116InchTransformOrigin="unset"
                frameDivBackgroundColor="#ffc075"
                inchTop="calc(50% - 4px)"
                inchLeft="calc(50% - 6.4px)"
              />
              <div className="master-of-science-integrated-d-parent">
                <div className="master-of-science-container">
                  <span sName="michaelglovingmailcom">
                    <span>{ `2011 ` }</span>
                    <span className="span">|</span>
                  </span>
                  <span>
                    <span className="michaelglovingmailcom">{ ` ` }</span>
                    <b>BA Arts and Animation, Cum Laude</b>
                  </span>
                  <span className="michaelglovingmailcom">
                    <span>{ ` ` }</span>
                    <span className="span">|</span>
                    <span>
                      { " " }
                      {/* Cal State University Northridge, Early Entrance Program (EEP) */ }
                      {/* California State University Northridge  */}
                      California State University Northridge and California State University Los Angeles, Early Entrance Program (EEP) 
                      
                       {/*
                      { " " }
                      <span className="span">|</span>
                      { " " }
*/ }
                      {/* Early Entrance Program (EEP)                   at Cal State LA */}
                      
                      {/* California State University Los Angeles, Early Entrance Program (EEP)  */}
                    </span>
                  </span>
                </div>
                <img className="image-73-icon" alt="" src="/undefined16.png" />
                <div className="frame-item" />
              </div>
              <Property116Inch
                positionNumber="qrtr"
                property116InchWidth="806.63px"
                property116InchHeight="28.81px"
                property116InchAlignSelf="unset"
                property116InchPosition="relative"
                property116InchTransform="unset"
                property116InchTransformOrigin="unset"
                frameDivBackgroundColor="#ff9393"
                inchTop="calc(50% - 4.8px)"
                inchLeft="calc(50% - 6.4px)"
              />
            </div>
          </div>
          <RectangleComponent
            rectangleDivWidth="952.27px"
            rectangleDivHeight="12.8px"
            rectangleDivPosition="relative"
            rectangleDivBackgroundColor="unset"
          />
          <div className="edu-frame">
            <Property116Inch
              positionNumber="qrtr"
              property116InchWidth="806.63px"
              property116InchHeight="28.81px"
              property116InchAlignSelf="unset"
              property116InchPosition="relative"
              property116InchTransform="unset"
              property116InchTransformOrigin="unset"
              frameDivBackgroundColor="#ff9393"
              inchTop="calc(50% - 4.8px)"
              inchLeft="calc(50% - 6.4px)"
            />
            <Education
              componentTitle="Experience"
              educationWidth="82.11px"
              educationHeight="16.59px"
              educationPosition="relative"
              educationLeft="-3.65%"
            // educationFontSize=".9rem"
            />
            <Property116Inch
              positionNumber="qrtr"
              property116InchWidth="806.63px"
              property116InchHeight="28.81px"
              property116InchAlignSelf="unset"
              property116InchPosition="relative"
              property116InchTransform="unset"
              property116InchTransformOrigin="unset"
              frameDivBackgroundColor="#ff9393"
              inchTop="calc(50% - 4.8px)"
              inchLeft="calc(50% - 6.4px)"
            />
            <div className="exp-content-frame">
              <EXPContentComponent
                chainscapeGamesLogo2="/undefined17.png"
                logoCHAINSCAPEGAMESPixelA="/undefined18.png"
                logoCHAINSCAPEGAMESPixelA1="/undefined19.png"
                chainscapeGamesLogo21="/undefined20.png"
                image56="/undefined21.png"
                image71="/undefined22.png"
                ld="/undefined23.png"
                lUDEREOrigAlt="/undefined24.png"
                lUDEREOrig="/undefined25.png"
                chainscapeGamesLogo22="/undefined26.png"
                prop="2021 - 2022"
                chainscapeGames="Chainscape Games"
                web3GameStudio="web3 game studio"
                roundRockTX="Round Rock, TX"
                artDirectorAndUXLead=" Art Director and UX Lead"
                ledMultinationalArtDepart="led multinational art department and worked closely with development team to successfully hit tight launch target that included the integration of 7000 different NFTs into game cosmetics"
                bulletTwo="spearheaded the aesthetic for the game world, characters, and user interface/experience, working closely with the CEO/Founder to ensure alignment with overall product vision"
                bulletThree="designed and led multimedia production of high quality infinity card NFT collection in four weeks"
                bulletFour="developed a vision for 3d game version to capture potential pivot"
                bulletFive=""
                bulletSix=""
                showLogoCHAINSCAPEGAMESPixelA={ true }
                image56Icon={ false }
                lUDEREOrigIcon={ false }
                eXPContentComponentWidth="806.63px"
                eXPContentComponentGap="38.41px"
                frameDivWidth="169.65px"
                frameDivWidth1="209.66px"
                frameDivHeight="38.41px"
                chainscapeGamesLogo2IconTop="calc(50% - 15px)"
                chainscapeGamesLogo2IconWidth="111.45px"
                chainscapeGamesLogo2IconHeight="29.62px"
                lOGOLeft="73.1px"
                lOGOFontSize="27.17px"
                lOGOTextShadow="0px 2.7168688774108887px 2.72px rgba(0, 0, 0, 0.25)"
                lOGOWebkitTextStroke="unset"
                logoCHAINSCAPEGAMESPixelALeft="132.32px"
                logoCHAINSCAPEGAMESPixelAWidth="76.29px"
                logoCHAINSCAPEGAMESPixelAHeight="47.35px"
                logoCHAINSCAPEGAMESPixelATop="9.73px"
                logoCHAINSCAPEGAMESPixelALeft1="42.69px"
                logoCHAINSCAPEGAMESPixelAWidth1="168.05px"
                logoCHAINSCAPEGAMESPixelAHeight1="19.21px"
                chainscapeGamesLogo2IconLeft="50.7px"
                chainscapeGamesLogo2IconWidth1="158.44px"
                chainscapeGamesLogo2IconHeight1="38.41px"
                image56IconLeft="101.91px"
                image56IconWidth="107.23px"
                image56IconHeight="38.41px"
                image71IconLeft="101.91px"
                image71IconWidth="107.23px"
                image71IconHeight="24.01px"
                ldIconLeft="157.93px"
                ldIconWidth="32.01px"
                ldIconHeight="38.41px"
                lUDEREOrigAltTop="22.32px"
                lUDEREOrigAltLeft="108.83px"
                lUDEREOrigAltWidth="100.31px"
                lUDEREOrigAltHeight="15.67px"
                lUDEREOrigIconTop="9.9px"
                lUDEREOrigIconLeft="87.51px"
                lUDEREOrigIconWidth="121.63px"
                lUDEREOrigIconHeight="19.01px"
                frameDivWidth2="152.04px"
                frameDivHeight1="30.41px"
                chainscapeGamesLogo2IconTop1="calc(50% - 14.2px)"
                chainscapeGamesLogo2IconWidth2="111.45px"
                chainscapeGamesLogo2IconHeight2="29.62px"
                lOGOLeft1="81.1px"
                lOGOFontSize1="19.75px"
                lOGOTextShadow1="0px 1.9754137992858887px 1.98px rgba(0, 0, 0, 0.25)"
                lOGOWebkitTextStroke1="unset"
                inchTop="calc(50% - 5.2px)"
                inchLeft="calc(50% - 8px)"
                chainscapeGamesWeb3ContaiFontSize="unset"
                // pFontSize="10px"
                // chainscapeGamesFontSize=".9rem"
                // web3GameStudioFontSize="10px"
                // roundRockTXFontSize="10px"
                artDirectorAndContainerFontSize="unset"
              // artDirectorAndFontSize=".9rem"
              // ledMultinationalArtDepartFontSize="10px"
              // ledMultinationalArtFontSize="10px"
              />

              <Property116Inch
                positionNumber="6th"
                property116InchWidth="806.63px"
                property116InchHeight="19.21px"
                property116InchAlignSelf="unset"
                property116InchPosition="relative"
                property116InchTransform="unset"
                property116InchTransformOrigin="unset"
                frameDivBackgroundColor="#75cdff"
                inchTop="calc(50% - 4.8px)"
                inchLeft="calc(50% - 6.4px)"
              />
              <SubDivider
                subDividerSubDivider="/undefined27.png"
                subDividerIconMaxHeight="unset"
                subDividerIconWidth="574.74px"
                subDividerIconPosition="relative"
                subDividerIconHeight="1.6px"
              />
              <Property116Inch
                positionNumber="6th"
                property116InchWidth="806.63px"
                property116InchHeight="19.21px"
                property116InchAlignSelf="unset"
                property116InchPosition="relative"
                property116InchTransform="unset"
                property116InchTransformOrigin="unset"
                frameDivBackgroundColor="#75cdff"
                inchTop="calc(50% - 4.8px)"
                inchLeft="calc(50% - 6.4px)"
              />
              <EXPContentComponent
                chainscapeGamesLogo2="/undefined28.png"
                logoCHAINSCAPEGAMESPixelA="/undefined29.png"
                // logoCHAINSCAPEGAMESPixelA="../../assets/resume_images/undefined25.png"
                logoCHAINSCAPEGAMESPixelA1="/undefined30.png"
                chainscapeGamesLogo21="/undefined31.png"
                image56="/undefined32.png"
                image71="/undefined33.png"
                ld="/undefined34.png"
                lUDEREOrigAlt="/undefined35.png"
                // lUDEREOrig="/undefined36.png"
                lUDEREOrig="/ludorig.png"
                chainscapeGamesLogo22="/undefined37.png"
                prop="2016 - 2020"
                chainscapeGames="Ludere Clothing"
                web3GameStudio="men’s fashion line"
                roundRockTX="Los Angeles, CA"
                artDirectorAndUXLead="Co-founder, Art Director, and Designer"
                ledMultinationalArtDepart="took clothing start up from zero to an incorporated company with trademarks and a complete pipeline of trusted local contractors"
                bulletTwo="led design process for branding and development of house style"
                bulletThree="designed, co-designed, and oversaw production of complex fitted garments such as lined outerwear and pants"
                bulletFour="designed and coordinated the production of a custom trim package, including a zipper pull, label, snap buttons, and tack buttons, while ensuring compatibility across multiple manufacturers and contractors"
                bulletFive="designed UI/UX for website based on a collaborative vision"
                bulletSix="illustrated graphics for tags and garments"
                showLogoCHAINSCAPEGAMESPixelA={ false }
                image56Icon={ false }
                lUDEREOrigIcon={ true }
                eXPContentComponentWidth="806.63px"
                eXPContentComponentGap="38.41px"
                frameDivWidth="169.65px"
                frameDivWidth1="209.66px"
                frameDivHeight="38.41px"
                chainscapeGamesLogo2IconTop="calc(50% - 15px)"
                chainscapeGamesLogo2IconWidth="111.45px"
                chainscapeGamesLogo2IconHeight="29.62px"
                lOGOLeft="73.1px"
                lOGOFontSize="27.17px"
                lOGOTextShadow="0px 2.7168688774108887px 2.72px rgba(0, 0, 0, 0.25)"
                lOGOWebkitTextStroke="unset"
                logoCHAINSCAPEGAMESPixelALeft="132.32px"
                logoCHAINSCAPEGAMESPixelAWidth="76.29px"
                logoCHAINSCAPEGAMESPixelAHeight="47.35px"
                logoCHAINSCAPEGAMESPixelATop="9.73px"
                logoCHAINSCAPEGAMESPixelALeft1="42.69px"
                logoCHAINSCAPEGAMESPixelAWidth1="168.05px"
                logoCHAINSCAPEGAMESPixelAHeight1="19.21px"
                chainscapeGamesLogo2IconLeft="50.7px"
                chainscapeGamesLogo2IconWidth1="158.44px"
                chainscapeGamesLogo2IconHeight1="38.41px"
                image56IconLeft="101.91px"
                image56IconWidth="107.23px"
                image56IconHeight="38.41px"
                image71IconLeft="101.91px"
                image71IconWidth="107.23px"
                image71IconHeight="24.01px"
                ldIconLeft="157.93px"
                ldIconWidth="32.01px"
                ldIconHeight="38.41px"
                lUDEREOrigAltTop="22.32px"
                lUDEREOrigAltLeft="108.83px"
                lUDEREOrigAltWidth="100.31px"
                lUDEREOrigAltHeight="15.67px"
                // ludere edit
                // lUDEREOrigIconTop="9.9px"
                // lUDEREOrigIconTop="7px"
                lUDEREOrigIconTop="7.9px"
                // lUDEREOrigIconTop="11.9px"
                // lUDEREOrigIconLeft=`"87.51px"
                lUDEREOrigIconLeft="80.51px"
                // lUDEREOrigIconWidth="121.63px"
                lUDEREOrigIconWidth="125.63px"
                lUDEREOrigIconHeight="19.01px"
                frameDivWidth2="152.04px"
                frameDivHeight1="30.41px"
                chainscapeGamesLogo2IconTop1="calc(50% - 14.2px)"
                chainscapeGamesLogo2IconWidth2="111.45px"
                chainscapeGamesLogo2IconHeight2="29.62px"
                lOGOLeft1="81.1px"
                lOGOFontSize1="19.75px"
                lOGOTextShadow1="0px 1.9754137992858887px 1.98px rgba(0, 0, 0, 0.25)"
                lOGOWebkitTextStroke1="unset"
                inchTop="calc(50% - 5.2px)"
                inchLeft="calc(50% - 8px)"
                chainscapeGamesWeb3ContaiFontSize="unset"
                // pFontSize="10px"
                // chainscapeGamesFontSize=".9rem"
                // web3GameStudioFontSize="10px"
                // roundRockTXFontSize="10px"
                artDirectorAndContainerFontSize="unset"
              // artDirectorAndFontSize=".9rem"
              // ledMultinationalArtDepartFontSize="10px"
              // ledMultinationalArtFontSize="10px"
              />
              <Property116Inch
                positionNumber="6th"
                property116InchWidth="806.63px"
                property116InchHeight="19.21px"
                property116InchAlignSelf="unset"
                property116InchPosition="relative"
                property116InchTransform="unset"
                property116InchTransformOrigin="unset"
                frameDivBackgroundColor="#75cdff"
                inchTop="calc(50% - 4.8px)"
                inchLeft="calc(50% - 6.4px)"
              />
              <SubDivider
                subDividerSubDivider="/undefined38.png"
                subDividerIconMaxHeight="unset"
                subDividerIconWidth="574.74px"
                subDividerIconPosition="relative"
                subDividerIconHeight="1.6px"
              />
              <Property116Inch
                positionNumber="6th"
                property116InchWidth="806.63px"
                property116InchHeight="19.21px"
                property116InchAlignSelf="unset"
                property116InchPosition="relative"
                property116InchTransform="unset"
                property116InchTransformOrigin="unset"
                frameDivBackgroundColor="#75cdff"
                inchTop="calc(50% - 4.8px)"
                inchLeft="calc(50% - 6.4px)"
              />
              <EXPContentComponent
                chainscapeGamesLogo2="/undefined39.png"
                logoCHAINSCAPEGAMESPixelA="/undefined40.png"
                logoCHAINSCAPEGAMESPixelA1="/undefined41.png"
                chainscapeGamesLogo21="/undefined42.png"
                image56="/undefined43.png"
                image71="/undefined44.png"
                ld="/undefined45.png"
                lUDEREOrigAlt="/undefined46.png"
                // lUDEREOrig="/undefined47.png"
                lUDEREOrig="/ludorig.png"
                chainscapeGamesLogo22="/undefined48.png"
                prop="2013 -  2014"
                chainscapeGames="Silicon Beach Media"
                web3GameStudio="mobile game studio"
                roundRockTX="Marina Del Rey, CA"
                artDirectorAndUXLead="Game Artist"
                ledMultinationalArtDepart="illustrated and created assets for mobile games"
                bulletTwo="created UX and UI for mobile games"
                bulletThree="reported to Art Director and Chief Creative Officer"
                bulletFour="collaborated with developers and other artists"
                bulletFive=""
                bulletSix=""
                showLogoCHAINSCAPEGAMESPixelA={ false }
                image56Icon={ true }
                lUDEREOrigIcon={ false }
                eXPContentComponentWidth="806.63px"
                eXPContentComponentGap="38.41px"
                frameDivWidth="169.65px"
                frameDivWidth1="209.66px"
                frameDivHeight="38.41px"
                chainscapeGamesLogo2IconTop="calc(50% - 15px)"
                chainscapeGamesLogo2IconWidth="111.45px"
                chainscapeGamesLogo2IconHeight="29.62px"
                lOGOLeft="73.1px"
                lOGOFontSize="27.17px"
                lOGOTextShadow="0px 2.7168688774108887px 2.72px rgba(0, 0, 0, 0.25)"
                lOGOWebkitTextStroke="unset"
                logoCHAINSCAPEGAMESPixelALeft="132.32px"
                logoCHAINSCAPEGAMESPixelAWidth="76.29px"
                logoCHAINSCAPEGAMESPixelAHeight="47.35px"
                logoCHAINSCAPEGAMESPixelATop="9.73px"
                logoCHAINSCAPEGAMESPixelALeft1="42.69px"
                logoCHAINSCAPEGAMESPixelAWidth1="168.05px"
                logoCHAINSCAPEGAMESPixelAHeight1="19.21px"
                chainscapeGamesLogo2IconLeft="50.7px"
                chainscapeGamesLogo2IconWidth1="158.44px"
                chainscapeGamesLogo2IconHeight1="38.41px"
                image56IconLeft="101.91px"
                image56IconWidth="107.23px"
                image56IconHeight="38.41px"
                image71IconLeft="101.91px"
                image71IconWidth="107.23px"
                image71IconHeight="24.01px"
                ldIconLeft="157.93px"
                ldIconWidth="32.01px"
                ldIconHeight="38.41px"
                lUDEREOrigAltTop="22.32px"
                lUDEREOrigAltLeft="108.83px"
                lUDEREOrigAltWidth="100.31px"
                lUDEREOrigAltHeight="15.67px"
                lUDEREOrigIconTop="9.9px"
                lUDEREOrigIconLeft="87.51px"
                lUDEREOrigIconWidth="121.63px"
                lUDEREOrigIconHeight="19.01px"
                frameDivWidth2="152.04px"
                frameDivHeight1="30.41px"
                chainscapeGamesLogo2IconTop1="calc(50% - 14.2px)"
                chainscapeGamesLogo2IconWidth2="111.45px"
                chainscapeGamesLogo2IconHeight2="29.62px"
                lOGOLeft1="81.1px"
                lOGOFontSize1="19.75px"
                lOGOTextShadow1="0px 1.9754137992858887px 1.98px rgba(0, 0, 0, 0.25)"
                lOGOWebkitTextStroke1="unset"
                inchTop="calc(50% - 5.2px)"
                inchLeft="calc(50% - 8px)"
                chainscapeGamesWeb3ContaiFontSize="unset"
                // pFontSize="10px"
                // chainscapeGamesFontSize=".9rem"
                // web3GameStudioFontSize="10px"
                // roundRockTXFontSize="10px"
                artDirectorAndContainerFontSize="unset"
              // artDirectorAndFontSize="1.3em"
              // ledMultinationalArtDepartFontSize="10px"
              // ledMultinationalArtFontSize="10px"
              />
            </div>
          </div>
          <Property116Inch
            positionNumber="qrtr"
            property116InchWidth="806.63px"
            property116InchHeight="28.81px"
            property116InchAlignSelf="unset"
            property116InchPosition="relative"
            property116InchTransform="unset"
            property116InchTransformOrigin="unset"
            frameDivBackgroundColor="#ff9393"
            inchTop="calc(50% - 4.8px)"
            inchLeft="calc(50% - 6.4px)"
          />
          <RectangleComponent
            rectangleDivWidth="952.27px"
            rectangleDivHeight="12.8px"
            rectangleDivPosition="relative"
            rectangleDivBackgroundColor="#fff"
          />
          <Property116Inch
            positionNumber="qrtr"
            property116InchWidth="806.63px"
            property116InchHeight="28.81px"
            property116InchAlignSelf="unset"
            property116InchPosition="relative"
            property116InchTransform="unset"
            property116InchTransformOrigin="unset"
            frameDivBackgroundColor="#ff9393"
            inchTop="calc(50% - 4.8px)"
            inchLeft="calc(50% - 6.4px)"
          />
          <div className="edu-frame">
            <Education
              componentTitle="Freelance"
              educationWidth="73.65px"
              educationHeight="16.59px"
              educationPosition="relative"
              educationLeft="-4.07%"
            // educationFontSize=".9rem"
            />
            <Property116Inch
              positionNumber="qrtr"
              property116InchWidth="806.63px"
              property116InchHeight="28.81px"
              property116InchAlignSelf="unset"
              property116InchPosition="relative"
              property116InchTransform="unset"
              property116InchTransformOrigin="unset"
              frameDivBackgroundColor="#ff9393"
              inchTop="calc(50% - 4.8px)"
              inchLeft="calc(50% - 6.4px)"
            />
            <div className="edu-contents">
              <div className="reach-global-container">
                <p className="routledgefocal-press-book-p">
                  <span>
                    <span className="michaelglovingmailcom">2023 - Current</span>
                  </span>
                </p>
                <p className="routledgefocal-press-book-p">
                  <span>
                    <b className="master-of-science">
                      Label 5ive
                    </b>
                  </span>
                  <span className="michaelglovingmailcom">
                    <span>{ ` ` }</span>
                    <span className="span">|</span>
                    <span className="book-publisher">{ ` clothing company ` }</span>
                    <span className="span">|</span>
                    <span className="book-publisher">
                      { " " }
                      {/* created graphics and print patterns  */ }
                      {/* created graphics, print patterns, and designed trim */}
                      {/*created graphics, print patterns, trim designs, flats, garment mock-ups, and lookbook*/}
                      {/* created graphics, print patterns, trim designs, flats, garment mock-ups, lookbook, and collaborated on garment designs */}
                      {/* collaborated on garment designs, created graphics, print patterns, trim designs, flats, garment mock-ups, and lookbook */}
                      collaborated on garment designs and created graphics, print patterns, trim designs, flats, garment mock-ups, and lookbook layout
                    </span>
                  </span>
                </p>
              </div>
              <Property116Inch
                positionNumber="qrtr"
                property116InchWidth="806.63px"
                property116InchHeight="28.81px"
                property116InchAlignSelf="unset"
                property116InchPosition="relative"
                property116InchTransform="unset"
                property116InchTransformOrigin="unset"
                frameDivBackgroundColor="#ff9393"
                inchTop="calc(50% - 4.8px)"
                inchLeft="calc(50% - 6.4px)"
              />


{/* <span style={{backgroundColor:`cyan`}}> */}
<div className="reach-global-container">
                <p className="routledgefocal-press-book-p">
                  <span>
                    <span className="michaelglovingmailcom">2024</span>
                  </span>
                </p>
                <p className="routledgefocal-press-book-p">
                  <span>
                    <b className="master-of-science">
                      UCLA
                    </b>
                  </span>
                  <span className="michaelglovingmailcom">
                    <span>{ ` ` }</span>
                    <span className="span">|</span>
                    <span className="book-publisher">{ ` university ` }</span>
                    <span className="span">|</span>
                    <span className="book-publisher">
                      { " " }
                      {/* created graphics and print patterns  */ }
                      {/* created graphics, print patterns, and designed trim */}
                      {/*created graphics, print patterns, trim designs, flats, garment mock-ups, and lookbook*/}
                      {/* created graphics, print patterns, trim designs, flats, garment mock-ups, lookbook, and collaborated on garment designs */}
                      {/* collaborated on garment designs, created graphics, print patterns, trim designs, flats, garment mock-ups, and lookbook */}
                      collaborated with stakeholders to create logo for STAR program
                    </span>
                  </span>
                </p>
              </div>

              {/* </span> */}

              <Property116Inch
                positionNumber="qrtr"
                property116InchWidth="806.63px"
                property116InchHeight="28.81px"
                property116InchAlignSelf="unset"
                property116InchPosition="relative"
                property116InchTransform="unset"
                property116InchTransformOrigin="unset"
                frameDivBackgroundColor="#ff9393"
                inchTop="calc(50% - 4.8px)"
                inchLeft="calc(50% - 6.4px)"
              />



              <div className="routledgefocal-press-container">
                <p className="routledgefocal-press-book-p">
                  <span>
                    <span>2023</span>
                  </span>
                </p>
                <p className="routledgefocal-press-book-p">
                  <span>
                    <b className="master-of-science">Routledge/Focal Press</b>
                  </span>
                  <span>
                    <span>{ ` ` }</span>
                    <span className="span">|</span>
                    <span className="book-publisher">{ ` book publisher ` }</span>
                    <span className="span">|</span>
                    <span>{ ` created cover and illustrations for ` }</span>
                  </span>
                  <span>
                    <i className="master-of-science">The Final Rewrite</i>
                  </span>
                </p>
              </div>
              <Property116Inch
                positionNumber="qrtr"
                property116InchWidth="806.63px"
                property116InchHeight="28.81px"
                property116InchAlignSelf="unset"
                property116InchPosition="relative"
                property116InchTransform="unset"
                property116InchTransformOrigin="unset"
                frameDivBackgroundColor="#ff9393"
                inchTop="calc(50% - 4.8px)"
                inchLeft="calc(50% - 6.4px)"
              />
              <div className="reach-global-container">
                <p className="routledgefocal-press-book-p">
                  <span>
                    <span className="michaelglovingmailcom">2021</span>
                  </span>
                </p>
                <p className="routledgefocal-press-book-p">
                  <span>
                    <b className="master-of-science">Reach Globa</b>
                  </span>
                  <span className="michaelglovingmailcom">
                    <span>
                      <b className="master-of-science">l</b>
                    </span>
                    <span>
                      <span>{ ` ` }</span>
                      <span className="span">|</span>
                      <span className="book-publisher">{ ` marketing company ` }</span>
                      <span className="span">|</span>
                      <span>
                        { " " }
                        created landing page design for client Neon Buddha
                      </span>
                    </span>
                  </span>
                </p>
              </div>
              <Property116Inch
                positionNumber="qrtr"
                property116InchWidth="806.63px"
                property116InchHeight="28.81px"
                property116InchAlignSelf="unset"
                property116InchPosition="relative"
                property116InchTransform="unset"
                property116InchTransformOrigin="unset"
                frameDivBackgroundColor="#ff9393"
                inchTop="calc(50% - 4.8px)"
                inchLeft="calc(50% - 6.4px)"
              />
              <div className="reach-global-container">
                <p className="routledgefocal-press-book-p">
                  <span>
                    <span className="michaelglovingmailcom">2020</span>
                  </span>
                </p>
                <p className="routledgefocal-press-book-p">
                  <span>
                    <b className="master-of-science">
                      Accelerated Construction Technologies
                    </b>
                  </span>
                  <span className="michaelglovingmailcom">
                    <span>{ ` ` }</span>
                    <span className="span">|</span>
                    <span className="book-publisher">{ ` manufacturing company ` }</span>
                    <span className="span">|</span>
                    <span className="book-publisher">
                      { " " }
                      created concept art for project proposals
                    </span>
                  </span>
                </p>
              </div>
              <Property116Inch
                positionNumber="qrtr"
                property116InchWidth="806.63px"
                property116InchHeight="28.81px"
                property116InchAlignSelf="unset"
                property116InchPosition="relative"
                property116InchTransform="unset"
                property116InchTransformOrigin="unset"
                frameDivBackgroundColor="#ff9393"
                inchTop="calc(50% - 4.8px)"
                inchLeft="calc(50% - 6.4px)"
              />
              <div className="reach-global-container">
                <p className="routledgefocal-press-book-p">
                  <span>
                    <span className="michaelglovingmailcom">
                      2018 - 2019
                    </span>
                  </span>
                </p>
                <p className="routledgefocal-press-book-p">
                  <span>
                    <b className="master-of-science">Infinite Energylinks</b>
                  </span>
                  <span className="michaelglovingmailcom">
                    <span>{ ` ` }</span>
                    <span className="span">|</span>
                    <span className="book-publisher">{ ` personal development company ` }</span>
                    <span className="span">|</span>
                    <span>
                      { " " }
                      designed and illustrated characters and assets for videos,
                      created graphics for website, consulted on theme and design
                    </span>
                  </span>
                </p>
              </div>
              <Property116Inch
                positionNumber="qrtr"
                property116InchWidth="806.63px"
                property116InchHeight="28.81px"
                property116InchAlignSelf="unset"
                property116InchPosition="relative"
                property116InchTransform="unset"
                property116InchTransformOrigin="unset"
                frameDivBackgroundColor="#ff9393"
                inchTop="calc(50% - 4.8px)"
                inchLeft="calc(50% - 6.4px)"
              />
              <div className="routledgefocal-press-container">
                <p className="routledgefocal-press-book-p">
                  <span>
                    <span>2016</span>
                  </span>
                </p>
                <p className="routledgefocal-press-book-p">
                  <span>
                    <b className="master-of-science">Def Jam Recordings</b>
                  </span>
                  <span>
                    <span>{ ` ` }</span>
                    <span className="span">|</span>
                    <span className="book-publisher">{ ` record label ` }</span>
                    <span className="span">|</span>
                    <span>
                      { " " }
                      created album cover and merchandise collection for artist
                      Troi Irons
                    </span>
                  </span>
                </p>
              </div>
              <Property116Inch
                positionNumber="qrtr"
                property116InchWidth="806.63px"
                property116InchHeight="28.81px"
                property116InchAlignSelf="unset"
                property116InchPosition="relative"
                property116InchTransform="unset"
                property116InchTransformOrigin="unset"
                frameDivBackgroundColor="#ff9393"
                inchTop="calc(50% - 4.8px)"
                inchLeft="calc(50% - 6.4px)"
              />
              <div className="routledgefocal-press-container">
                <p className="routledgefocal-press-book-p">
                  <span>
                    <span>2014 - 2015</span>
                  </span>
                </p>
                <p className="routledgefocal-press-book-p">
                  <span>
                    <b className="master-of-science">JQ Art Business</b>
                  </span>
                  <span>
                    <span>{ ` ` }</span>
                    <span className="span">|</span>
                    <span className="book-publisher">{ ` multimedia agency ` }</span>
                    <span className="span">|</span>
                    <span>
                      { " " }
                      clean up, line work, and color for assorted comics
                    </span>
                  </span>
                </p>
              </div>
              <Property116Inch
                positionNumber="qrtr"
                property116InchWidth="806.63px"
                property116InchHeight="28.81px"
                property116InchAlignSelf="unset"
                property116InchPosition="relative"
                property116InchTransform="unset"
                property116InchTransformOrigin="unset"
                frameDivBackgroundColor="#ff9393"
                inchTop="calc(50% - 4.8px)"
                inchLeft="calc(50% - 6.4px)"
              />
              <div className="routledgefocal-press-container">
                <p className="routledgefocal-press-book-p">
                  <span>
                    <span>2014</span>
                  </span>
                </p>
                <p className="routledgefocal-press-book-p">
                  <span>
                    <b className="master-of-science">Pure Imagination Studios</b>
                  </span>
                  <span>
                    <span>{ ` ` }</span>
                    <span className="span">|</span>
                    <span className="book-publisher">{ ` entertainment company ` }</span>
                    <span className="span">|</span>
                    <span>
                      { " " }
                      designed theme park uniforms for Dreamworks and Warner
                      Brothers
                    </span>
                  </span>
                </p>
              </div>
              <Property116Inch
                positionNumber="qrtr"
                property116InchWidth="806.63px"
                property116InchHeight="28.81px"
                property116InchAlignSelf="unset"
                property116InchPosition="relative"
                property116InchTransform="unset"
                property116InchTransformOrigin="unset"
                frameDivBackgroundColor="#ff9393"
                inchTop="calc(50% - 4.8px)"
                inchLeft="calc(50% - 6.4px)"
              />
              <div className="routledgefocal-press-container">
                <p className="routledgefocal-press-book-p">
                  <span>
                    <span>2012</span>
                  </span>
                </p>
                <p className="routledgefocal-press-book-p">
                  <span>
                    <b className="master-of-science">Sun and Moon Films</b>
                  </span>
                  <span>
                    <span className="span"> |</span>
                    <span className="book-publisher"> production company</span>
                    <span className="span"> |</span>
                    <span>{ ` created and illustrated poster for ` }</span>
                  </span>
                  <span>
                    <i className="master-of-science">Brush with Danger</i>
                  </span>
                </p>
              </div>
              <Property116Inch
                positionNumber="qrtr"
                property116InchWidth="806.63px"
                property116InchHeight="28.81px"
                property116InchAlignSelf="unset"
                property116InchPosition="relative"
                property116InchTransform="unset"
                property116InchTransformOrigin="unset"
                frameDivBackgroundColor="#ff9393"
                inchTop="calc(50% - 4.8px)"
                inchLeft="calc(50% - 6.4px)"
              />
              <div className="routledgefocal-press-container">
                <p className="routledgefocal-press-book-p">
                  <span>
                    <span>2012</span>
                  </span>
                </p>
                <p className="routledgefocal-press-book-p">
                  <span>
                    <b className="master-of-science">Mischievous Muse Press</b>
                  </span>
                  <span>
                    <span>{ ` ` }</span>
                    <span className="span">|</span>
                    <span className="book-publisher">{ ` book publisher ` }</span>
                    <span className="span">|</span>
                    <span>{ ` illustrated cover for ` }</span>
                  </span>
                  <span>
                    <i className="master-of-science">Tincture of Time</i>
                  </span>
                </p>
              </div>
              <Property116Inch
                positionNumber="qrtr"
                property116InchWidth="806.63px"
                property116InchHeight="28.81px"
                property116InchAlignSelf="unset"
                property116InchPosition="relative"
                property116InchTransform="unset"
                property116InchTransformOrigin="unset"
                frameDivBackgroundColor="#ff9393"
                inchTop="calc(50% - 4.8px)"
                inchLeft="calc(50% - 6.4px)"
              />
              <div className="routledgefocal-press-container">
                <p className="routledgefocal-press-book-p">
                  <span>
                    <span>2011 - 2012</span>
                  </span>
                </p>
                <p className="routledgefocal-press-book-p">
                  <span>
                    <b className="master-of-science">Lespedi Productions</b>
                  </span>
                  <span>
                    <span>{ ` ` }</span>
                    <span className="span">|</span>
                    <span className="book-publisher"> film company</span>
                    <span className="span"> |</span>
                  </span>
                  <span>
                    <span>{ ` assisted in film editing, did compositing, and created visual effects for ` }</span>
                    <i className="master-of-science">{ `More Than the Rainbow ` }</i>
                    <span className="master-of-science">
                      (Best Feature Documentary - Coney Island Film Festival)
                    </span>
                  </span>
                </p>
              </div>
              <Property116Inch
                positionNumber="qrtr"
                property116InchWidth="806.63px"
                property116InchHeight="28.81px"
                property116InchAlignSelf="unset"
                property116InchPosition="relative"
                property116InchTransform="unset"
                property116InchTransformOrigin="unset"
                frameDivBackgroundColor="#ff9393"
                inchTop="calc(50% - 4.8px)"
                inchLeft="calc(50% - 6.4px)"
              />
              <div className="routledgefocal-press-container">
                <p className="routledgefocal-press-book-p">
                  <span>
                    <span>2011</span>
                  </span>
                </p>
                <p className="routledgefocal-press-book-p">
                  <span>
                    <b className="master-of-science">Routledge/Focal Press</b>
                  </span>
                  <span>
                    <span>{ ` ` }</span>
                    <span className="span">|</span>
                    <span className="book-publisher">{ ` book publisher ` }</span>
                    <span className="span">|</span>
                    <span> designed cover and created illustrations for </span>
                  </span>
                </p>
                <p className="routledgefocal-press-book-p">
                  <span>
                    <i>The Healthy Edit</i>
                  </span>
                </p>
              </div>
            </div>
          </div>
          <Property116Inch
            positionNumber="qrtr"
            property116InchWidth="806.63px"
            property116InchHeight="28.81px"
            property116InchAlignSelf="unset"
            property116InchPosition="relative"
            property116InchTransform="unset"
            property116InchTransformOrigin="unset"
            frameDivBackgroundColor="#ff9393"
            inchTop="calc(50% - 4.8px)"
            inchLeft="calc(50% - 6.4px)"
          />
          <RectangleComponent
            rectangleDivWidth="952.27px"
            rectangleDivHeight="12.8px"
            rectangleDivPosition="relative"
            rectangleDivBackgroundColor="#fff"
          />
          <Property116Inch
            positionNumber="qrtr"
            property116InchWidth="806.63px"
            property116InchHeight="28.81px"
            property116InchAlignSelf="unset"
            property116InchPosition="relative"
            property116InchTransform="unset"
            property116InchTransformOrigin="unset"
            frameDivBackgroundColor="#ff9393"
            inchTop="calc(50% - 4.8px)"
            inchLeft="calc(50% - 6.4px)"
          />
          <div className="skill-temp-parent">
            <div className="skill-temp-parent">
              <Education
                componentTitle="Skills"
                educationWidth="73.65px"
                educationHeight="16.59px"
                educationPosition="relative"
                educationLeft="20.37%"
              // educationFontSize=".9rem"
              />
              <div className="skill-contents-temp-wrapper">
                <div className="skill-contents-temp">
                  <div className="skill-contents-temp-inner">
                    <div className="sketching-parent">
                      <div className="frame-by-frame">sketching</div>
                      <div className="frame-by-frame">perspective drawing</div>
                      <div className="frame-by-frame">compositing</div>
                      <div className="frame-by-frame">
                        frame by frame animation
                      </div>
                    </div>
                  </div>
                  <Property116Inch
                    positionNumber="qrtr"
                    property116InchWidth="749.01px"
                    property116InchHeight="28.81px"
                    property116InchAlignSelf="unset"
                    property116InchPosition="relative"
                    property116InchTransform="unset"
                    property116InchTransformOrigin="unset"
                    frameDivBackgroundColor="#ff9393"
                    inchTop="calc(50% - 4.8px)"
                    inchLeft="calc(50% - 6.4px)"
                  />
                  <div className="skill-contents-temp-inner">
                    <div className="sketching-parent">
                      <div className="frame-by-frame">sketching</div>
                      <div className="frame-by-frame">perspective drawing</div>
                      <div className="frame-by-frame">lorem</div>
                      <div className="frame-by-frame">compositing</div>
                      <div className="frame-by-frame">
                        frame by frame animation
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Property116Inch
              positionNumber="6th"
              property116InchWidth="806.63px"
              property116InchHeight="19.21px"
              property116InchAlignSelf="unset"
              property116InchPosition="relative"
              property116InchTransform="unset"
              property116InchTransformOrigin="unset"
              frameDivBackgroundColor="#75cdff"
              inchTop="calc(50% - 4.8px)"
              inchLeft="calc(50% - 6.4px)"
            />
            <div className="frame-container">
              <div className="vector-parent">
                {/* <div className="vector-icon" alt="" src="/undefined49.png" /> */ }
                <div className="vector-icon" />
                <i className="art">Art</i>
                {/* <img className="frame-child1" alt="" src="/undefined50.png" /> */ }
                <div className="frame-child1" alt="" src="/undefined50.png" />
              </div>
              <div className="vector-parent">
                {/* <div className="vector-icon" alt="" src="/undefined51.png" /> */ }
                <div className="vector-icon" />
                <i className="art">Design</i>
                {/* <img className="frame-child1" alt="" src="/undefined52.png" /> */ }
                <div className="frame-child1" />
              </div>
            </div>

            <Property116Inch
              positionNumber="6th"
              property116InchWidth="806.63px"
              property116InchHeight="19.21px"
              property116InchAlignSelf="unset"
              property116InchPosition="relative"
              property116InchTransform="unset"
              property116InchTransformOrigin="unset"
              frameDivBackgroundColor="#75cdff"
              inchTop="calc(50% - 4.8px)"
              inchLeft="calc(50% - 6.4px)"
            />
            <TestSkills />

          </div>
          <Property116Inch
            positionNumber="qrtr"
            property116InchWidth="806.63px"
            property116InchHeight="28.81px"
            property116InchAlignSelf="unset"
            property116InchPosition="relative"
            property116InchTransform="unset"
            property116InchTransformOrigin="unset"
            frameDivBackgroundColor="#ff9393"
            inchTop="calc(50% - 4.8px)"
            inchLeft="calc(50% - 6.4px)"
          />
          <RectangleComponent
            rectangleDivWidth="952.27px"
            rectangleDivHeight="12.8px"
            rectangleDivPosition="relative"
            rectangleDivBackgroundColor="#fff"
          />
          <Property116Inch
            positionNumber="qrtr"
            property116InchWidth="806.63px"
            property116InchHeight="28.81px"
            property116InchAlignSelf="unset"
            property116InchPosition="relative"
            property116InchTransform="unset"
            property116InchTransformOrigin="unset"
            frameDivBackgroundColor="#ff9393"
            inchTop="calc(50% - 4.8px)"
            inchLeft="calc(50% - 6.4px)"
          />
          <div className="education-group">
            <Education
              componentTitle="Toolkit"
              educationWidth="73.65px"
              educationHeight="16.59px"
              educationPosition="relative"
              educationLeft="12.22%"
            // educationFontSize=".9rem"
            />
            <Property116Inch
              positionNumber="qrtr"
              property116InchWidth="806.63px"
              property116InchHeight="28.81px"
              property116InchAlignSelf="unset"
              property116InchPosition="relative"
              property116InchTransform="unset"
              property116InchTransformOrigin="unset"
              frameDivBackgroundColor="#ff9393"
              inchTop="calc(50% - 4.8px)"
              inchLeft="calc(50% - 6.4px)"
            />
            <div className="d-parent">
              <i className="d">3D</i>
              <i className="d1">2D</i>
              <div className="group-child2" alt="" src="/undefined53.png" />
              <div className="group-child3" alt="" src="/undefined54.png" />
              <div className="group-child4" alt="" src="/undefined55.png" />
              <div className="group-child5" alt="" src="/undefined56.png" />
            </div>
            <Property116Inch
              positionNumber="qrtr"
              property116InchWidth="806.63px"
              property116InchHeight="28.81px"
              property116InchAlignSelf="unset"
              property116InchPosition="relative"
              property116InchTransform="unset"
              property116InchTransformOrigin="unset"
              frameDivBackgroundColor="#ff9393"
              inchTop="calc(50% - 4.8px)"
              inchLeft="calc(50% - 6.4px)"
            />
            <div className="software-contents-temp">
              <div className="image-21-parent">
                <img className="image-21-icon" alt="" src="/undefined57.png" />
                <Property116Inch
                  positionNumber="8th"
                  property116InchWidth="51.21px"
                  property116InchHeight="14.4px"
                  property116InchAlignSelf="unset"
                  property116InchPosition="relative"
                  property116InchTransform="unset"
                  property116InchTransformOrigin="unset"
                  frameDivBackgroundColor="#ffc075"
                  inchTop="calc(50% - 4px)"
                  inchLeft="calc(50% - 6.4px)"
                />
                <div className="zbrush-expert">
                  <p className="routledgefocal-press-book-p">ZBrush</p>
                  <p className="routledgefocal-press-book-p">(expert)</p>
                </div>
              </div>
              <div className="image-21-parent">
                <img className="image-21-icon" alt="" src="/undefined58.png" />
                <Property116Inch
                  positionNumber="8th"
                  property116InchWidth="51.21px"
                  property116InchHeight="14.4px"
                  property116InchAlignSelf="unset"
                  property116InchPosition="relative"
                  property116InchTransform="unset"
                  property116InchTransformOrigin="unset"
                  frameDivBackgroundColor="#ffc075"
                  inchTop="calc(50% - 4px)"
                  inchLeft="calc(50% - 6.4px)"
                />
                <div className="zbrush-expert">
                  <p className="routledgefocal-press-book-p">Maya</p>
                  <p className="routledgefocal-press-book-p">(expert)</p>
                </div>
              </div>
              <div className="image-21-parent">
                <img className="image-22-icon" alt="" src="/undefined59.png" />
                <Property116Inch
                  positionNumber="8th"
                  property116InchWidth="51.21px"
                  property116InchHeight="14.4px"
                  property116InchAlignSelf="unset"
                  property116InchPosition="relative"
                  property116InchTransform="unset"
                  property116InchTransformOrigin="unset"
                  frameDivBackgroundColor="#ffc075"
                  inchTop="calc(50% - 4px)"
                  inchLeft="calc(50% - 6.4px)"
                />
                <div className="blender">Blender</div>
              </div>
              <div className="image-21-parent">
                <img className="image-25-icon" alt="" src="/undefined60.png" />
                <Property116Inch
                  positionNumber="8th"
                  property116InchWidth="51.21px"
                  property116InchHeight="14.4px"
                  property116InchAlignSelf="unset"
                  property116InchPosition="relative"
                  property116InchTransform="unset"
                  property116InchTransformOrigin="unset"
                  frameDivBackgroundColor="#ffc075"
                  inchTop="calc(50% - 4px)"
                  inchLeft="calc(50% - 6.4px)"
                />
                <div className="zbrush-expert">
                  <p className="routledgefocal-press-book-p">Figma</p>
                  <p className="routledgefocal-press-book-p">(expert)</p>
                </div>
              </div>
              <div className="image-21-parent">
                <img className="image-23-icon" alt="" src="/undefined61.png" />
                <Property116Inch
                  positionNumber="8th"
                  property116InchWidth="51.21px"
                  property116InchHeight="14.4px"
                  property116InchAlignSelf="unset"
                  property116InchPosition="relative"
                  property116InchTransform="unset"
                  property116InchTransformOrigin="unset"
                  frameDivBackgroundColor="#ffc075"
                  inchTop="calc(50% - 4px)"
                  inchLeft="calc(50% - 6.4px)"
                />
                <div className="photoshop-expert">
                  <p className="routledgefocal-press-book-p">Photoshop</p>
                  <p className="routledgefocal-press-book-p">(expert)</p>
                </div>
              </div>
              <div className="image-21-parent">
                <img className="image-22-icon" alt="" src="/undefined62.png" />
                <Property116Inch
                  positionNumber="8th"
                  property116InchWidth="51.21px"
                  property116InchHeight="14.4px"
                  property116InchAlignSelf="unset"
                  property116InchPosition="relative"
                  property116InchTransform="unset"
                  property116InchTransformOrigin="unset"
                  frameDivBackgroundColor="#ffc075"
                  inchTop="calc(50% - 4px)"
                  inchLeft="calc(50% - 6.4px)"
                />
                <div className="art">
                  <p className="routledgefocal-press-book-p">{ `PaintTool ` }</p>
                  <p className="routledgefocal-press-book-p">SAI</p>
                </div>
              </div>
              <div className="image-21-parent">
                <img className="image-23-icon" alt="" src="/undefined63.png" />
                <Property116Inch
                  positionNumber="8th"
                  property116InchWidth="51.21px"
                  property116InchHeight="14.4px"
                  property116InchAlignSelf="unset"
                  property116InchPosition="relative"
                  property116InchTransform="unset"
                  property116InchTransformOrigin="unset"
                  frameDivBackgroundColor="#ffc075"
                  inchTop="calc(50% - 4px)"
                  inchLeft="calc(50% - 6.4px)"
                />
                <div className="illustrator">Illustrator</div>
              </div>
            </div>
            <Property116Inch
              positionNumber="qrtr"
              property116InchWidth="806.63px"
              property116InchHeight="28.81px"
              property116InchAlignSelf="unset"
              property116InchPosition="relative"
              property116InchTransform="unset"
              property116InchTransformOrigin="unset"
              frameDivBackgroundColor="#ff9393"
              inchTop="calc(50% - 4.8px)"
              inchLeft="calc(50% - 6.4px)"
            />
            <div className="d-animation-parent">
              <i className="d-animation">2D Animation</i>
              <i className="video-editing">Video Editing</i>
              <i className="languages-working-knowledge">
                {/* Languages (working knowledge) */}
                Languages
                {/* Programming */}
                {/* Programming (working knowledge) */}
              </i>
              <div className="group-child6" alt="" src="/undefined64.png" />
              <div className="group-child7" alt="" src="/undefined65.png" />
              <div className="group-child8" alt="" src="/undefined66.png" />
              <div className="group-child9" alt="" src="/undefined67.png" />
              <div className="group-child10" alt="" src="/undefined68.png" />
              <div className="group-child11" alt="" src="/undefined69.png" />
            </div>
            <Property116Inch
              positionNumber="qrtr"
              property116InchWidth="806.63px"
              property116InchHeight="28.81px"
              property116InchAlignSelf="unset"
              property116InchPosition="relative"
              property116InchTransform="unset"
              property116InchTransformOrigin="unset"
              frameDivBackgroundColor="#ff9393"
              inchTop="calc(50% - 4.8px)"
              inchLeft="calc(50% - 6.4px)"
            />
            <div className="software-contents-temp1">
              <div className="image-21-parent">
                <img className="image-80-icon" alt="" src="/undefined70.png" />
                <Property116Inch
                  positionNumber="8th"
                  property116InchWidth="51.21px"
                  property116InchHeight="14.4px"
                  property116InchAlignSelf="unset"
                  property116InchPosition="relative"
                  property116InchTransform="unset"
                  property116InchTransformOrigin="unset"
                  frameDivBackgroundColor="#ffc075"
                  inchTop="calc(50% - 4px)"
                  inchLeft="calc(50% - 6.4px)"
                />
                <div className="art">Animate</div>
              </div>
              <div className="image-21-parent">
                <img className="image-69-icon" alt="" src="/undefined71.png" />
                <Property116Inch
                  positionNumber="8th"
                  property116InchWidth="51.21px"
                  property116InchHeight="14.4px"
                  property116InchAlignSelf="unset"
                  property116InchPosition="relative"
                  property116InchTransform="unset"
                  property116InchTransformOrigin="unset"
                  frameDivBackgroundColor="#ffc075"
                  inchTop="calc(50% - 4px)"
                  inchLeft="calc(50% - 6.4px)"
                />
                <div className="art">
                  <p className="routledgefocal-press-book-p">Toonboom</p>
                  <p className="routledgefocal-press-book-p">Harmony</p>
                </div>
              </div>
              <div className="image-21-parent">
                <img className="image-23-icon" alt="" src="/undefined72.png" />
                <Property116Inch
                  positionNumber="8th"
                  property116InchWidth="51.21px"
                  property116InchHeight="14.4px"
                  property116InchAlignSelf="unset"
                  property116InchPosition="relative"
                  property116InchTransform="unset"
                  property116InchTransformOrigin="unset"
                  frameDivBackgroundColor="#ffc075"
                  inchTop="calc(50% - 4px)"
                  inchLeft="calc(50% - 6.4px)"
                />
                <div className="premiere">Premiere</div>
              </div>
              <div className="image-21-parent">
                <img className="image-22-icon" alt="" src="/undefined73.png" />
                <Property116Inch
                  positionNumber="8th"
                  property116InchWidth="51.21px"
                  property116InchHeight="14.4px"
                  property116InchAlignSelf="unset"
                  property116InchPosition="relative"
                  property116InchTransform="unset"
                  property116InchTransformOrigin="unset"
                  frameDivBackgroundColor="#ffc075"
                  inchTop="calc(50% - 4px)"
                  inchLeft="calc(50% - 6.4px)"
                />
                <div className="art">Avid</div>
              </div>
              <div className="image-21-parent">
                <img className="image-22-icon" alt="" src="/undefined74.png" />
                <Property116Inch
                  positionNumber="8th"
                  property116InchWidth="51.21px"
                  property116InchHeight="14.4px"
                  property116InchAlignSelf="unset"
                  property116InchPosition="relative"
                  property116InchTransform="unset"
                  property116InchTransformOrigin="unset"
                  frameDivBackgroundColor="#ffc075"
                  inchTop="calc(50% - 4px)"
                  inchLeft="calc(50% - 6.4px)"
                />
                <div className="blender">Python</div>
              </div>
              <div className="image-65-parent">
                <img className="image-22-icon" alt="" src="/undefined75.png" />
                <Property116Inch
                  positionNumber="8th"
                  property116InchWidth="51.21px"
                  property116InchHeight="14.4px"
                  property116InchAlignSelf="unset"
                  property116InchPosition="relative"
                  property116InchTransform="unset"
                  property116InchTransformOrigin="unset"
                  frameDivBackgroundColor="#ffc075"
                  inchTop="calc(50% - 4px)"
                  inchLeft="calc(50% - 6.4px)"
                />
                <div className="javascript">JavaScript</div>
              </div>
              <div className="image-21-parent">
                <img className="image-64-icon" alt="" src="/undefined76.png" />
                <Property116Inch
                  positionNumber="8th"
                  property116InchWidth="51.21px"
                  property116InchHeight="14.4px"
                  property116InchAlignSelf="unset"
                  property116InchPosition="relative"
                  property116InchTransform="unset"
                  property116InchTransformOrigin="unset"
                  frameDivBackgroundColor="#ffc075"
                  inchTop="calc(50% - 4px)"
                  inchLeft="calc(50% - 6.4px)"
                />
                {/* <div className="illustrator">React Native</div> */}
                <div className="illustrator">React</div>
              </div>
            </div>
          </div>


        </div>
        <img className="redline-icon" alt="" src="/undefined77.png" />
      </div>

      {/* <div
        className="back-to-portfolio resume-div-text"
        onClick={ returnToPorfolio }
      >← return to portfolio </div> */}

    </div>
  )
}

export default Letter133

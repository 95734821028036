// next .. loop ref into state? (update of state triggers ref change with use effect?)

import React, { useRef, useState, useEffect } from 'react'

import './CarouselView.css'
import './CarouselSingleView.css'



import { CarouselSingleItem } from './CarouselSingleItem'
// import CarouselNavButton from './CarouselComponents/CarouselNavButton'

const CarouselView = ( { content,
    carouselPosition,
    setCarouselPosition,
    posTransition,
    setActiveHoverState,
    itemRefArray,
    sliderRef,
    carouselVisible,
} ) => {
    // const CarouselView = forwardRef( function CarouselView( content, setCarouselPosition) {
    // const CarouselView = function CarouselView( {content}, setCarouselPosition) {



    // const MARGIN_WIDTH = 64
    // const ITEM_WIDTH = 640

    // VW VAR
    // const ITEM_WIDTH = 40 * window.innerWidth / 100
    // const ITEM_WIDTH = 40 * window.innerWidth / 100
    // const ITEM_WIDTH = 65 * window.innerWidth / 100 // jumbo
    // const ITEM_WIDTH = 50 * window.innerWidth / 100
    // 55?
    // const GAP_WIDTH = 16
    // const GAP_WIDTH = 40
    // const GAP_WIDTH = 64
    // const NET_ITEM_WIDTH = GAP_WIDTH + ITEM_WIDTH

    // const sliderRef = useRef()
    const prevPageX = useRef()
    const prevScrollLeft = useRef()

    const [ dragState, setDragState ] = useState( false )






    const sliderInit = () => {
        if ( sliderRef.current ) {
            sliderRef.current.classList.add( "instant" )
            // setScrollPos(MARGIN_WIDTH)
            // sliderRef.current.style.height = itemRefArray.current[ 0 ].offsetHeight
            // sliderRef.current.scrollLeft = MARGIN_WIDTH
            sliderRef.current.style.height = `${ itemRefArray.current[ 0 ].offsetHeight - 1 }px`
            // sliderRef.current.style.gap = `${ GAP_WIDTH }px`
            setTimeout( () => {
                sliderRef.current.classList.remove( "instant" )
            }, 0 )

        }
    }


    // OUTPUT ----------------------------------------------

    return (
        <div
            ref={ sliderRef }
            className="Carousel single-carousel"
            // className="Carousel single-carousel"
            // className="single-carousel-styling single-carousel"
        // className="single-carousel single-carousel-alt"

        >
            { content.map( ( item, index ) => {
                // needs id?
                // !!!!!!! filter first for categories?
                return (

                    <div
                        // className='test1'
                        // style={ {
                        //     marginLeft: index === 0 ? `${ MARGIN_WIDTH }px` : '0px',
                        //     marginRight: index === ( content.length - 1 ) ? `${ MARGIN_WIDTH }px` : '0px',
                        // } }
                        style={ { width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' } }
                        onLoad={ () => {
                            if ( index === 0 ) {
                                // if (index === content.length - 1) {
                                sliderInit() // Make sure you have a function to set the scrollLeft
                            }
                        } }
                    >
                        { <CarouselSingleItem item={ item } carouselVisible={ carouselVisible } ref={ ( element ) => ( itemRefArray.current[ index ] = element ) } /> }
                    </div>

                )
            } ) }

        </div>
    )
}

export default CarouselView


/* eslint-disable no-restricted-globals */

import React, { useState, useRef, useEffect, useLayoutEffect } from 'react'


import './Carousel.css'
import CarouselView from './CarouselComponents/CarouselView'
import CarouselThumbs from './CarouselComponents/CarouselThumbs'
import ArrowContainer from './CarouselComponents/ArrowContainer'
import SlideIndicator from './CarouselComponents/SlideIndicator'

import RightArrow from './CarouselComponents/CarouselResources/right_arrow'
import LeftArrow from './CarouselComponents/CarouselResources/left_arrow'

// import { CarouselItem } from './CarouselComponents/CarouselItem'
// import CarouselNavButton from './CarouselComponents/CarouselNavButton'


function renderText( data ) {
    if ( typeof data === 'string' ) {
        return data
    } else if ( typeof data === 'object' && Array.isArray( data ) ) {
        return data.map( ( tuple, index, array ) => {
            const isLast = index === array.length - 1

            if ( tuple[ 0 ] === 'italic' ) {
                return (
                    <span key={ index } className="italic">
                        { tuple[ 1 ] }
                        { !isLast && ' ' }
                    </span>
                )
            } else {
                return (
                    <span key={ index }>
                        { tuple[ 1 ] }
                        { !isLast && ' ' }
                    </span>
                )
            }
        } )
    }
}

const Carousel = ( { carouselContent, documentScrollState, pinchBlocker, index, length } ) => {


    const sliderRef = useRef( null )
    const sliderContents = useRef( null )
    // const trackedPos = useRef( null )




    const itemRefArray = useRef( [] )
    const [ arrowPosition, setArrowPosition ] = useState( 0 )
    const [ arrowMoveState, setArrowMoveState ] = useState( null )
    const [ arrowSliding, setArrowSliding ] = useState( false )
    const mouseIsOver = useRef( null )


    const [ carouselPosition, setCarouselPosition ] = useState( 0 )

    const carouselBackup = useRef()

    const [ activeHoverState, setActiveHoverState ] = useState( false )
    const [ indicatorTransitionState, setIndicatorTransitionState ] = useState( false )

    const [ slideInfoWidthState, setSlideInfoWidthState ] = useState( 0 )

    const originalSlideNeighborhood = useRef( null )


    //  new vert indicator 
    const posChangeOriginator = useRef( null )



    // POS HANDLING

    // ALSO IN CAROUSELITEM

    // const MARGIN_WIDTH = 64
    const MARGIN_WIDTH = 64
    // const MARGIN_WIDTH = 128
    // const MARGIN_WIDTH = 256

    // const vwFullDesk = 45
    // const vwFullDesk = 42.5
    // const vwFullDesk = 43
    // const vwFullDesk = 44
    const vwFullDesk = 43.5
    // const vwSmallDesk = 60
    const vwSmallDesk = 58
    const vwSmallTablet = 60
    // const vwSmallTablet = 62.5
    // const vwSmallDesk = 65
    const vwPortraitMobile = 100
    // const vwLandscapeMobile = 93
    // const vwLandscapeMobile = 90
    // const vwLandscapeMobile = 93
    // const vwLandscapeMobile = 95
    const vwLandscapeMobile = 78.5
    // const mobileThumbSize = 128

    const frameWidth = useRef( 0 )
    // frameWidth.current = window.innerWidth <= 1024
    //     ? window.innerHeight > window.innerWidth ? vwPortraitMobile : vwLandscapeMobile
    //     : window.innerWidth > 1920 ? vwFullDesk : vwSmallDesk

    frameWidth.current = window.innerWidth <= 1024
        ? window.innerHeight > window.innerWidth ? vwPortraitMobile : vwLandscapeMobile
        : window.innerWidth > 1920 ? vwFullDesk :
            window.innerWidth >= 1280 ? vwSmallDesk : vwSmallTablet


    const ITEM_WIDTH = useRef( null )
    // ITEM_WIDTH.current = frameWidth.current * window.innerWidth / 100 - ( window.innerWidth <= 1024 && window.innerHeight < window.innerWidth ? mobileThumbSize : 0 )
    ITEM_WIDTH.current = frameWidth.current * window.innerWidth / 100


    // const GAP_WIDTH = 16
    // const GAP_WIDTH = 32
    const GAP_WIDTH = 48


    const NET_ITEM_WIDTH = useRef( null )
    NET_ITEM_WIDTH.current = GAP_WIDTH + ITEM_WIDTH.current

    // const [ isMobile, setIsMobile ] = useState( false )

    // const slideDescWidth = useRef( ITEM_WIDTH.current - ( 10 / 100 * window.innerWidth ) )

    // const slideDescWidth = useRef( ITEM_WIDTH.current - ( 10 / 100 * window.innerWidth ) )

    function findSlideDescWidth() {
        // return ITEM_WIDTH.current - ( 10 / 100 * window.innerWidth )
        return ITEM_WIDTH.current - ( 7.5 / 100 * window.innerWidth )
        // return ITEM_WIDTH.current -  (.2 * ITEM_WIDTH.current)
        // return ITEM_WIDTH.current - ( 5 / 100 * window.innerWidth )
    }


    const [ slideDescWidth, setSlideDescWidth ] = useState( findSlideDescWidth() )


    useEffect( () => {
  


   
        const updateDynamicWidth = () => {
            const frameWidthTemp = frameWidth.current
            const windowWidth = window.innerWidth
            const windowHeight = window.innerHeight

            const calculatedWidth = frameWidthTemp / 100 * windowWidth - ( .1 * windowWidth )

            setSlideInfoWidthState( calculatedWidth )

        }

        let timeoutId = null

        const handleResize = ( event ) => {

            if ( pinchBlocker == true ) {
                return
            }

            if ( event.touches ) {
                // console.log( `touches! ${ event.touches }` )
                return
            }

            if ( timeoutId ) {
                clearTimeout( timeoutId )
            }

            frameWidth.current = window.innerWidth <= 1024
                ? window.innerHeight > window.innerWidth ? vwPortraitMobile : vwLandscapeMobile
                : window.innerWidth > 1920 ? vwFullDesk :
                    window.innerWidth >= 1280 ? vwSmallDesk : vwSmallTablet

            timeoutId = setTimeout( () => {

                // ITEM_WIDTH.current = frameWidth.current * window.innerWidth / 100 - ( window.innerWidth <= 1024 && window.innerHeight < window.innerWidth ? mobileThumbSize : 0 )
                ITEM_WIDTH.current = frameWidth.current * window.innerWidth / 100
                NET_ITEM_WIDTH.current = GAP_WIDTH + ITEM_WIDTH.current

                setTimeout( () => {

                    updateCarousel( carouselBackup.current, 'instant' )
                    updateDynamicWidth()
                    setSlideDescWidth( findSlideDescWidth() )

                }, 1 )

            }, 250 )



        }


        const handleOrientationChange = () => {




            frameWidth.current = window.innerWidth <= 1024
                ? window.innerHeight > window.innerWidth ? vwPortraitMobile : vwLandscapeMobile
                : window.innerWidth > 1920 ? vwFullDesk :
                    window.innerWidth >= 1280 ? vwSmallDesk : vwSmallTablet



            // ITEM_WIDTH.current = frameWidth.current * window.innerWidth / 100 - ( window.innerWidth <= 1024 && window.innerHeight < window.innerWidth ? mobileThumbSize : 0 )
            ITEM_WIDTH.current = frameWidth.current * window.innerWidth / 100
            NET_ITEM_WIDTH.current = GAP_WIDTH + ITEM_WIDTH.current



            updateCarousel( carouselBackup.current, 'instant' )
            updateDynamicWidth()
            setSlideDescWidth( findSlideDescWidth() )





        }

        window.addEventListener( 'resize', handleResize )
        // window.addEventListener( 'orientationchange', handleOrientationChange )
        screen.orientation.addEventListener( 'change', handleOrientationChange )

        return () => {

            if ( timeoutId ) {
                clearTimeout( timeoutId )
            }
            window.removeEventListener( 'resize', handleResize )
            // window.removeEventListener( 'orientationchange', handleOrientationChange )
            screen.orientation.removeEventListener( 'change', handleOrientationChange )
        }
    }, [] )


    function updateCarousel( slidePos = carouselPosition, transitionType ) {


        sliderContents.current.classList.add( "carousel-contents-dragging" )

        // ITEM_WIDTH.current = frameWidth.current * window.innerWidth / 100 - ( window.innerWidth <= 1024 && window.innerHeight < window.innerWidth ? mobileThumbSize : 0 )
        ITEM_WIDTH.current = frameWidth.current * window.innerWidth / 100


        NET_ITEM_WIDTH.current = GAP_WIDTH + ITEM_WIDTH.current

        if ( transitionType === 'instant' ) {
            sliderRef.current.classList.add( "quick-selector" )
            

            // sliderRef.current.classList.remove( "instant" )

            setTimeout( () => {
                sliderRef.current.classList.remove( "quick-selector" )
                
            }, 50 )
        }

        // sliderRef.current.scrollLeft = ( slidePos * NET_ITEM_WIDTH.current ) + MARGIN_WIDTH
        trackedPos.current = ( slidePos * NET_ITEM_WIDTH.current ) + MARGIN_WIDTH
        // console.log(`tracked pos`,trackedPos.current)
        sliderContents.current.style.transform = `translateX(-${(slidePos * NET_ITEM_WIDTH.current) + MARGIN_WIDTH}px)`;
        sliderRef.current.style.height = `${ itemRefArray.current[ slidePos ].offsetHeight - 1 }px`
        setCarouselPosition( slidePos )
        carouselBackup.current = slidePos

        sliderContents.current.classList.remove( "carousel-contents-dragging" )

    }


    function decCarouselPosition() {
        if ( carouselPosition > 0 ) {
            updateCarousel( carouselPosition - 1, 'instant' )
            // updateCarousel( carouselPosition - 1, 'smooth' )

        }
        else {
            updateCarousel( carouselContent.content.length - 1, `instant` )
            // updateCarousel(carouselContent.content.length - 1, `smooth`)
        }

        posChangeOriginator.current = `button`
    }


    function incCarouselPosition() {
        if ( carouselPosition < carouselContent.content.length - 1 ) {
            updateCarousel( carouselPosition + 1, 'instant' )
            // updateCarousel( carouselPosition + 1, 'smooth' )

        }
        else {
            updateCarousel( 0, `instant` )
            // updateCarousel(0, `smooth`)
        }

        posChangeOriginator.current = `button`
    }



    // carouselView Funcs <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<


    const trackedPos = useRef( MARGIN_WIDTH)
    const prevPageX = useRef()
    const prevScrollLeft = useRef()

    const [ dragState, setDragState ] = useState( false )
    const pageScrollBlocker = useRef( false )
    const carouselPosChangeBlocker = useRef( false )

    useEffect( () => {
        const mouseUpListener = ( event ) => mouseUpHandler( event )
        const mouseMoveListener = ( event ) => dragHandler( event )


        let initialTouchPosition = null

        // const thresholdY = 5        
        // const thresholdX = 5
        const thresholdY = 4
        const thresholdX = 4


        const touchMoveListener = ( event ) => {
            const { clientX: currentX, clientY: currentY } = event.touches[ 0 ]

            if ( !initialTouchPosition ) {
                initialTouchPosition = { x: currentX, y: currentY }
                // return // Store initial position and exit
            }

            const deltaX = Math.abs( currentX - initialTouchPosition.x )
            // moveTallyX.current += deltaX
            // console.log( `move tally: ${ moveTallyX.current }` )
            const deltaY = Math.abs( currentY - initialTouchPosition.y )


            // event.preventDefault() // Prevent default scrolling behavior when scrollBlocker is active
            // if (deltaX>deltaY) {
            //     event.preventDefault() // Prevent default scrolling behavior when scrollBlocker is active
            // }


            if ( pageScrollBlocker.current === true && carouselPosChangeBlocker.current === false ) {
                // console.log( `should be moving x` )
                event.preventDefault() // Prevent default scrolling behavior when scrollBlocker is active
                dragHandler( event )
            } else {
                const { clientX: currentX, clientY: currentY } = event.touches[ 0 ]

                if ( !initialTouchPosition ) {
                    initialTouchPosition = { x: currentX, y: currentY }
                    // return // Store initial position and exit
                }

                const deltaX = Math.abs( currentX - initialTouchPosition.x )
                const deltaY = Math.abs( currentY - initialTouchPosition.y )

                if ( deltaX > thresholdX || deltaY > thresholdY ) {
                    if ( deltaX > thresholdX && pageScrollBlocker.current === false ) {
                        pageScrollBlocker.current = true // Moved more than 30 pixels left/right
                        event.preventDefault() // Prevent default scrolling behavior when scrollBlocker is active

                        dragHandler( event )
                    }
                    else if ( deltaY > thresholdY && carouselPosChangeBlocker.current === false ) {
                        // Moved more than 30 pixels up/down, prevent dragHandler
                        // event.preventDefault()
                        carouselPosChangeBlocker.current = true
                        // console.log( `blocking carousel scroll` )
                    }
                    else if ( carouselPosChangeBlocker.current === true ) {
                        // Moved more than 30 pixels up/down, prevent dragHandler
                        // event.preventDefault()
                        // console.log( `moving Y` )
                    }

                    // else if (  pageScrollBlocker.current === true && carouselPosChangeBlocker === false ) {
                    //     console.log(`should be moving x DEEP`)
                    //     console.log(`SHOULDNT GET HERE dlkjLKFJLSDKFJSDLKJF penultimate`)
                    //     event.preventDefault() // Prevent default scrolling behavior when scrollBlocker is active
                    //     dragHandler( event )
                    // }




                    else {
                        // Trigger dragHandler only if not moved more than 30 pixels up/down
                        // dragHandler( event )
                        // console.log( `shouldnt get here` )
                        // console.log( `||` )
                        // console.log( `|| page scroll is blocked? ${pageScrollBlocker.current} --  slide scroll is blocked? ${carouselPosChangeBlocker.current}` )
                        // console.log( `||` )

                    }
                }

                // initialTouchPosition = null // Reset initial position
            }
        }


        const touchEndListener = ( event ) => {
            // moveTallyX.current = 0
            // console.log(`move tally reset --------------------> ${moveTallyX.current}`)
            // console.log(`reset -------------------->`)
            pageScrollBlocker.current = false
            carouselPosChangeBlocker.current = false
            mouseUpHandler( event )
        }

        if ( dragState ) {
            window.addEventListener( "mouseup", mouseUpListener )
            window.addEventListener( "mousemove", mouseMoveListener )
            window.addEventListener( "touchend", touchEndListener )
            document.addEventListener( 'touchmove', touchMoveListener, { passive: false } )
        } else {
            window.removeEventListener( "mouseup", mouseUpListener )
            window.removeEventListener( "mousemove", mouseMoveListener )
            window.removeEventListener( "touchend", touchEndListener )
            document.removeEventListener( 'touchmove', touchMoveListener )
        }

        return () => {
            window.removeEventListener( "mouseup", mouseUpListener )
            window.removeEventListener( "mousemove", mouseMoveListener )
            window.removeEventListener( "touchend", touchEndListener )
            document.removeEventListener( 'touchmove', touchMoveListener )
        }
    }, [ dragState ] )



    // HELPER FUNCTIONS ----------------------------------------------


    function updateCarouselPosition( neighborhood ) {
        // posTransition.current = ``
        setCarouselPosition( neighborhood )
        carouselBackup.current = neighborhood
        // console.log( `carousel set to ${ carouselPosition }` )
    }

    function findClosestSlide( dragPosition, neighborhood ) {

        let neighborhoodProgress = ( dragPosition - MARGIN_WIDTH - ( neighborhood * NET_ITEM_WIDTH.current ) ) / NET_ITEM_WIDTH.current
        // console.log(`findClosestSlide -> neighborhoodProgress: ${neighborhoodProgress}` )
        if ( neighborhoodProgress >= .5 ) {
            updateCarouselPosition( neighborhood + 1 )
            return neighborhood + 1
        }
        else {
            updateCarouselPosition( neighborhood )
            return neighborhood
        }
    }


    // MOUSE DOWN / DRAG ----------------------------------------------


    function mouseDownHandler( event ) {
        // if (event.touches.length > 1) {
        if ( event.touches ) {

            if ( event.touches.length > 1 ) {
                pinchBlocker.current = true
                // console.log(`----------> INTECEPTED`)
                return
            }


        }
        // console.log(`----------> shoudlnt reach, event.touches.length: ${event.touches.length}`)
        // if (event.touches && event.touches.length > 1) {
        //     return;
        // }
        if ( !event.touches ) { event.preventDefault() }


        sliderRef.current.classList.add( "dragging" )
        document.body.classList.add( "dragging-global" )
        sliderContents.current.classList.add( "carousel-contents-dragging" )
        prevPageX.current = event.touches ? event.touches[ 0 ].pageX : event.pageX
        // prevScrollLeft.current = sliderRef.current.scrollLeft
        prevScrollLeft.current = trackedPos.current

        // console.log( 'vvv MOUSE vvv' )
        // originalSlideNeighborhood.current = Math.floor( sliderRef.current.scrollLeft / NET_ITEM_WIDTH.current )
        originalSlideNeighborhood.current = Math.floor( trackedPos.current / NET_ITEM_WIDTH.current )
        setDragState( true )
        // setTimeout(() => {

        //     setScrollBlocker(true)
        // }, 300);
    }

    function dragHandler( event ) {



        if ( carouselPosChangeBlocker.current === true ) {
            return
        }

        if ( pinchBlocker.current === true ) {
            return
        }



        // touch drag speed, mouse drag speed 
        // let dragSpeed = ( event.touches ? 1.0 : 1.2 )
        // let dragSpeed = ( event.touches ? 1.0 : 1.1 )
        let dragSpeed = ( event.touches ? 1.0 : 1.0 )

   

        //was 1.1 for mouse drag before expanding view

        // good
        let positionDiff = ( event.touches ? event.touches[ 0 ].pageX : event.pageX ) - prevPageX.current
        let neighborhood
        // if ( sliderRef.current.scrollLeft > MARGIN_WIDTH ) {
        console.log(`tracked pos`, trackedPos.current);
        console.log(`cap should be`, ((NET_ITEM_WIDTH.current * (itemRefArray.current.length-1 )  ) + ( 2 * MARGIN_WIDTH)) );

        if (  trackedPos.current > ((NET_ITEM_WIDTH.current * (itemRefArray.current.length-1 )  ) + ( 2 * MARGIN_WIDTH)) ){

// THIS MIGHT NEED FIXING

            neighborhood = (itemRefArray.current.length-1 )
            console.log(`exceeeds`)
        }
        else if ( trackedPos.current > MARGIN_WIDTH ) {
            
            // neighborhood = Math.floor( ( sliderRef.current.scrollLeft - MARGIN_WIDTH ) / NET_ITEM_WIDTH.current )
            neighborhood = Math.floor( ( trackedPos.current - MARGIN_WIDTH ) / NET_ITEM_WIDTH.current )
        }
        else {
            // console.log(`tracked pos ->`, trackedPos.current);
            neighborhood = 0
        }
        console.log(` neighborhood is is ${neighborhood}`)
        // let neighborhood = Math.floor( (sliderRef.current.scrollLeft ) / NET_ITEM_WIDTH.current )
        // console.log(` neighborhood is is ${neighborhood}`)
        // dragHeightFinder( sliderRef.current.scrollLeft, neighborhood )
        dragHeightFinder( trackedPos.current, neighborhood )
        // findClosestSlide( sliderRef.current.scrollLeft, neighborhood )
        findClosestSlide( trackedPos.current, neighborhood )
        // sliderRef.current.scrollLeft = prevScrollLeft.current - ( positionDiff * dragSpeed )
        // sliderRef.current.scrollLeft = prevScrollLeft.current - ( positionDiff * dragSpeed )
        // console.log(`prevScrollLeft.current - ( positionDiff))---`, prevScrollLeft.current - ( positionDiff));
    // if ((prevScrollLeft.current - ( positionDiff)) >  ((NET_ITEM_WIDTH.current * itemRefArray.current.length)  + MARGIN_WIDTH) ){
    // if ((prevScrollLeft.current - ( positionDiff)) >  ((NET_ITEM_WIDTH.current * (itemRefArray.current.length )  ))){
    // if ((prevScrollLeft.current - ( positionDiff)) >  ((NET_ITEM_WIDTH.current * (itemRefArray.current.length )  ))){
    if ((prevScrollLeft.current - ( positionDiff)) >  ((NET_ITEM_WIDTH.current * (itemRefArray.current.length-1 )  ) + ( 2 * MARGIN_WIDTH)) )  {
        // trackedPos.current = ((NET_ITEM_WIDTH.current * itemRefArray.current.length)  + MARGIN_WIDTH)
        // sliderContents.current.style.transform = `translateX(-${((NET_ITEM_WIDTH.current * itemRefArray.current.length)  + MARGIN_WIDTH)}px)`;
// console.log(`should fire`)
        return
        }

        trackedPos.current = prevScrollLeft.current - ( positionDiff * dragSpeed )
        sliderContents.current.style.transform = `translateX(-${prevScrollLeft.current - ( positionDiff * dragSpeed )}px)`;

    }

    // function dragHeightFinder( dragPosition, neighborhood ) {

    //     // doesnt need to take var dragPosition?
    //     //?? seems to be used?
    //     if ( dragPosition <= MARGIN_WIDTH ) { return itemRefArray.current[ 0 ].offsetHeight }
    //     if ( neighborhood == ( itemRefArray.current.length - 1 ) ) { return itemRefArray.current[ neighborhood ].offsetHeight }
    //     let neighborhoodProgress = ( dragPosition - ( neighborhood * NET_ITEM_WIDTH.current ) ) / NET_ITEM_WIDTH.current
    //     let heightDiff = itemRefArray.current[ ( neighborhood + 1 ) ].offsetHeight - itemRefArray.current[ neighborhood ].offsetHeight
    //     sliderRef.current.style.height = `${ itemRefArray.current[ neighborhood ].offsetHeight + ( neighborhoodProgress * heightDiff ) - 1 }px`
    // }


    function dragHeightFinder( dragPosition, neighborhood ) {

        // doesnt need to take var dragPosition?
        //?? seems to be used?
        if ( dragPosition <= MARGIN_WIDTH ) {
            sliderRef.current.style.height = itemRefArray.current[ 0 ].offsetHeight
            return
        }
        if ( neighborhood == ( itemRefArray.current.length - 1 ) ) {
            sliderRef.current.style.height = itemRefArray.current[ neighborhood ].offsetHeight
            return
        }
        let neighborhoodProgress = ( dragPosition - MARGIN_WIDTH - ( neighborhood * NET_ITEM_WIDTH.current ) ) / NET_ITEM_WIDTH.current
        // console.log(`dragHeightFinder -> neighborhoodProgress: ${neighborhoodProgress}` )
        let heightDiff = itemRefArray.current[ ( neighborhood + 1 ) ].offsetHeight - itemRefArray.current[ neighborhood ].offsetHeight
        // sliderRef.current.style.height = `${ itemRefArray.current[ neighborhood ].offsetHeight + ( neighborhoodProgress * heightDiff ) - 1 }px`
        sliderRef.current.style.height = `${ itemRefArray.current[ neighborhood ].offsetHeight + ( neighborhoodProgress * heightDiff ) }px`
    }


    // MOUSE UP ----------------------------------------------


    function mouseUpHandler( event ) {


        // if ( event.touches ) {

        //     if ( event.touches.length > 1 ) {
        //         // console.log(`----------> INTECEPTED`)
        //         return
        //     }
        // }

        if ( pinchBlocker.current == true ) {
            // pinchBlocker.current = false

            setTimeout( () => {

                pinchBlocker.current = false
            }, 1 )
            // pinchBlocker.current = false
            return
        }


        // if (event.touches.length > 1) {
        //     console.log(`----------> INTECEPTED`)
        //     return;
        // }
        // console.log(`----------> shoudlnt reach, event.touches.length: ${event.touches.length}`)



        sliderRef.current.classList.remove( "dragging" )
        document.body.classList.remove( "dragging-global" )
        sliderContents.current.classList.remove( "carousel-contents-dragging" )
        // console.log( '^^^ MOUSE ^^^' )
        setDragState( false )
        if ( carouselPosChangeBlocker.current === false ) {

            dragResolver( event )
        }

        pageScrollBlocker.current = false
        carouselPosChangeBlocker.current = false
        // moveTallyX.current = 0
        // console.log( `move tally reset --------------------> ${ moveTallyX.current }` )
        originalSlideNeighborhood.current = null


    }

    function dragResolver( event ) {

        // should take initial drag and final location
        // ^ ??
        // const THRESHOLD = .3 // original threshold .. updating for larger view?
        // const THRESHOLD = .1
        // const THRESHOLD = .1
        const THRESHOLD = .1
        // const THRESHOLD = .5

        if (trackedPos.current < MARGIN_WIDTH){
                     trackedPos.current = MARGIN_WIDTH
                     sliderContents.current.style.transform = `translateX(-${ MARGIN_WIDTH }px)`;
        } 

        if (trackedPos.current > ((itemRefArray.current.length * NET_ITEM_WIDTH.current) + (2 * MARGIN_WIDTH))){
            trackedPos.current = ( ( NET_ITEM_WIDTH.current * ( itemRefArray.current.length ) ) + MARGIN_WIDTH )
            sliderContents.current.style.transform = `translateX(-${( ( NET_ITEM_WIDTH.current * ( itemRefArray.current.length ) ) + MARGIN_WIDTH )}px)`;
        }

        // let neighborhood = Math.floor( sliderRef.current.scrollLeft / NET_ITEM_WIDTH.current )
        let neighborhood = Math.floor( trackedPos.current / NET_ITEM_WIDTH.current )
        let positionChange = -( ( event.changedTouches ? event.changedTouches[ 0 ].pageX : event.pageX ) - prevPageX.current )
        if ( Math.abs( positionChange ) > NET_ITEM_WIDTH.current ) {
            // const neighborhoodAnswer = findClosestSlide( sliderRef.current.scrollLeft, neighborhood )
            const neighborhoodAnswer = findClosestSlide( trackedPos.current, neighborhood )
            // sliderRef.current.scrollLeft = ( ( NET_ITEM_WIDTH.current * ( neighborhoodAnswer ) ) + MARGIN_WIDTH )
            trackedPos.current = ( ( NET_ITEM_WIDTH.current * ( neighborhoodAnswer ) ) + MARGIN_WIDTH )
            sliderContents.current.style.transform = `translateX(-${( ( NET_ITEM_WIDTH.current * ( neighborhoodAnswer ) ) + MARGIN_WIDTH )}px)`;

            sliderRef.current.style.height = `${ itemRefArray.current[ neighborhoodAnswer ].offsetHeight - 1 }px`
            return
        }
        if ( positionChange > ( THRESHOLD * NET_ITEM_WIDTH.current ) ) {
            if ( neighborhood + 1 === itemRefArray.current.length ) {
                // sliderRef.current.scrollLeft = ( ( NET_ITEM_WIDTH.current * ( neighborhood ) ) + MARGIN_WIDTH )
                trackedPos.current = ( ( NET_ITEM_WIDTH.current * ( neighborhood ) ) + MARGIN_WIDTH )
                sliderContents.current.style.transform = `translateX(-${( ( NET_ITEM_WIDTH.current * ( neighborhood ) ) + MARGIN_WIDTH )}px)`;
                
            }
            if ( neighborhood + 1 <= itemRefArray.current.length - 1 ) {
                // sliderRef.current.scrollLeft = ( ( NET_ITEM_WIDTH.current * ( neighborhood + 1 ) ) + MARGIN_WIDTH )
                trackedPos.current = ( ( NET_ITEM_WIDTH.current * ( neighborhood + 1 ) ) + MARGIN_WIDTH )
                sliderContents.current.style.transform = `translateX(-${( ( NET_ITEM_WIDTH.current * ( neighborhood + 1 ) ) + MARGIN_WIDTH )}px)`;
                sliderRef.current.style.height = `${ itemRefArray.current[ neighborhood + 1 ].offsetHeight - 1 }px`
                updateCarouselPosition( neighborhood + 1 )
                // sliderRef.current.style.transition =  `height 2s;`
            }
        }
        if ( positionChange < -( THRESHOLD * NET_ITEM_WIDTH.current ) ) {
            // sliderRef.current.scrollLeft = ( ( NET_ITEM_WIDTH.current * ( neighborhood ) ) + MARGIN_WIDTH )
            trackedPos.current = ( ( NET_ITEM_WIDTH.current * ( neighborhood ) ) + MARGIN_WIDTH )
            sliderContents.current.style.transform = `translateX(-${( ( NET_ITEM_WIDTH.current * ( neighborhood ) ) + MARGIN_WIDTH )}px)`;

            sliderRef.current.style.height = `${ itemRefArray.current[ neighborhood ].offsetHeight - 1 }px`
            updateCarouselPosition( neighborhood )
            // sliderRef.current.scrollLeft = ( ( NET_ITEM_WIDTH.current * ( originalSlideNeighborhood.current ) ) + MARGIN_WIDTH )
            // sliderRef.current.style.height = `${ itemRefArray.current[ originalSlideNeighborhood.current ].offsetHeight - 1 }px`
            // updateCarouselPosition( originalSlideNeighborhood.current )
        }
        if ( Math.abs( positionChange ) < THRESHOLD * NET_ITEM_WIDTH.current ) {
            if ( positionChange < 0 ) {
                //BUG HERE
                // sliderRef.current.scrollLeft = ( ( NET_ITEM_WIDTH.current * ( neighborhood + 0 ) ) + MARGIN_WIDTH )
                // sliderRef.current.style.height = `${ itemRefArray.current[ neighborhood + 0 ].offsetHeight - 1 }px`
                // updateCarouselPosition( neighborhood + 0 )
                // sliderRef.current.scrollLeft = ( ( NET_ITEM_WIDTH.current * ( originalSlideNeighborhood.current ) ) + MARGIN_WIDTH )
                trackedPos.current = ( ( NET_ITEM_WIDTH.current * ( originalSlideNeighborhood.current ) ) + MARGIN_WIDTH )
                sliderContents.current.style.transform = `translateX(-${( ( NET_ITEM_WIDTH.current * ( originalSlideNeighborhood.current ) ) + MARGIN_WIDTH )}px)`;

                sliderRef.current.style.height = `${ itemRefArray.current[ originalSlideNeighborhood.current ].offsetHeight - 1 }px`
                updateCarouselPosition( originalSlideNeighborhood.current )
            }
            else {
                // sliderRef.current.scrollLeft = ( ( NET_ITEM_WIDTH.current * ( neighborhood ) ) + MARGIN_WIDTH )
                trackedPos.current = ( ( NET_ITEM_WIDTH.current * ( neighborhood ) ) + MARGIN_WIDTH )
                sliderContents.current.style.transform = `translateX(-${( ( NET_ITEM_WIDTH.current * ( neighborhood ) ) + MARGIN_WIDTH )}px)`;

                sliderRef.current.style.height = `${ itemRefArray.current[ neighborhood ].offsetHeight }px`
                updateCarouselPosition( neighborhood )
            }
        }
    }

    // function mouseEnterHandler() {
    //     setIndicatorTransitionState( true )
    //     setActiveHoverState( true )
    //     // setTimeout(() => {
    //     //     setActiveHoverState( true )
    //     // }, 0);
    //     setTimeout( () => {
    //         setIndicatorTransitionState( false )
    //     }, 201 )

    // }
    // function mouseLeaveHandler() {
    //     setIndicatorTransitionState( true )
    //     setActiveHoverState( false )
    //     // setTimeout(() => {
    //     //     setActiveHoverState( false )
    //     // }, 0);
    //     setTimeout( () => {
    //         setIndicatorTransitionState( false )
    //     }, 201 )

    // }



    function descScrollerMouseDown( event ) {
        if ( window.innerWidth <= 1024 && window.innerHeight > window.innerWidth ) {
            mouseDownHandler( event )
        }
    }

    function descScrollerMouseUp( event ) {
        if ( window.innerWidth <= 1024 && window.innerHeight > window.innerWidth ) {
            mouseUpHandler( event )
        }
    }



    // observer

    const carouselContainerRef = useRef()
    const [ carouselVisible, setCarouselVisible ] = useState()
    // console.log(`carouselVis for ${carouselContent.sectionCategory} || ${carouselContent.sectionTitle}`, carouselVisible);
    useEffect( () => {
        // console.log( `container ref`, carouselContainerRef.current )

        const observer = new IntersectionObserver( ( entries ) => {
            const entry = entries[ 0 ]
            // console.log(`entry`, entry);
            setCarouselVisible( entry.isIntersecting )

        }

        )
        observer.observe( carouselContainerRef.current )

    }, [] )




    // OUTPUT ----------------------------------------------

    return (
        <div
            ref={ carouselContainerRef }
            // className='carousel-container'
            // className={index === 0 ? 'carousel-container first-carousel-container' : 'carousel-container'}
            // className={index === 0 ? 'carousel-container first-carousel-container' : index === length - 1 ? 'carousel-container last-carousel-container' : 'carousel-container'}

            className={
                index === 0
                    ? index === length - 1
                        ? 'carousel-container first-carousel-container last-carousel-container'
                        : 'carousel-container first-carousel-container'
                    : index === length - 1
                        ? 'carousel-container last-carousel-container'
                        : 'carousel-container'
            }



        // style={{
        //     paddingTop: index === 0 ? '0px' : ''
        // }}

        >
            <div className="pillars">
                <div className="left-side">
                    <div className="carousel-information">


                    <span className="mobile-content">



                    <div className='mobile-theme-div'>
                            {/* <div className="carousel-title">{ carouselContent.sectionTitle }</div> */ }


                         

{/* <div style={{backgroundColor:'blue', }}> */}

                            {/* <p style={{lineHeight:'1.8', margin:'0px'}}>      */}
                            <p style={{ margin:'0px'}}>     
                            


                            { carouselContent.sectionTitle && (
                                <span className="carousel-title">{ carouselContent.sectionTitle }</span>
                            ) }


                            {/* <span className='mobile-info-divider'> | </span> */ }


                            { carouselContent.sectionCategory && (
                                <span>
                                {/* <span className='mobile-info-divider'> &nbsp;|&nbsp;</span> */}
                                {/* <span className='mobile-info-divider'> &nbsp;|&nbsp;</span> */}
                                {/* <span className='mobile-info-divider'> | </span> */}
                                <span 
                                className='mobile-info-divider'
                                // > | </span>
                                >|</span>
                                {/* <span> </span> */}
                                </span>
                            ) }




                            {/* <div className="carousel-category">{ carouselContent.sectionCategory }</div> */ }
                            { carouselContent.sectionCategory && (
                                <
                                    span className="carousel-category"
                                    // style={{lineHeight:'1.4', margin:'0px'}}
                                    style={{lineHeight:'1.6', margin:'0px'}}
                                    >{ carouselContent.sectionCategory }</span>
                            ) }




                            </p>
                           

                            {/* </div> */}
                            {/* <div style={{backgroundColor:'yellow', display:'block'}}> */}

{ carouselContent.sectionDesc && (
                            <div 
                            className="carousel-desc"
                            // style={{lineHeight:'1.4', margin:'0px'}}
                            style={{lineHeight:'1.6', margin:'0px'}}
                            >{ renderText( carouselContent.sectionDesc ) }</div>
                        ) }


{/* </div> */}


                            </div>










                            </span>
















                            <span className="desktop-content">

                            <div className='mobile-theme-div'>

                               
                            { carouselContent.sectionTitle && (
                                <div className="carousel-title">{ carouselContent.sectionTitle }</div>
                            ) }


                            {/* <span className='mobile-info-divider'> | </span> */ }


                            {/* { carouselContent.sectionCategory && (
                                <span className='mobile-info-divider'> | </span>
                            ) } */}

                            {/* <div className="carousel-category">{ carouselContent.sectionCategory }</div> */ }
                            { carouselContent.sectionCategory && (
                                <div className="carousel-category">{ carouselContent.sectionCategory }</div>
                            ) }

{ carouselContent.sectionDesc && (
                            <div className="carousel-desc">{ renderText( carouselContent.sectionDesc ) }</div>
                        ) }


</div>
</span>

                        {/* </div> */}
                        {/* <div className="carousel-desc">{ renderText( carouselContent.sectionDesc ) }</div> */ }
                       







                        {/* <p className='mobile-carousel-information'>
                        <span className='mobile-carousel-title'>{ carouselContent.sectionTitle }</span>
                        <span className='mobile-info-divider'> | </span>
                        <span className='mobile-carousel-category'>{ carouselContent.sectionCategory }</span>
                        <span className='mobile-info-divider'> | </span>
                        { renderText( carouselContent.sectionDesc ) }
                        </p> */}

                        {/* <div className="carousel-desc">{ carouselContent.sectionDesc }</div> */ }
                    </div>

                    <div className="carousel-and-arrows">
                        {/* // turn off for single view? */ }

                        <ArrowContainer
                            arrowDirection={ 'left' }
                            moveFunction={ decCarouselPosition }
                            arrowPosition={ arrowPosition }
                            setArrowPosition={ setArrowPosition }
                            arrowMoveState={ arrowMoveState }
                            setArrowMoveState={ setArrowMoveState }
                            activeHoverState={ activeHoverState }
                            setActiveHoverState={ setActiveHoverState }
                            arrowSliding={ arrowSliding }
                            setArrowSliding={ setArrowSliding }
                            carouselPosition={ carouselPosition }
                            content={ carouselContent.content }
                            itemRefArray={ itemRefArray }
                            mouseIsOver={ mouseIsOver }
                            setIndicatorTransitionState={ setIndicatorTransitionState }
                        />
                        <CarouselView
                            content={ carouselContent.content }
                            setCarouselPosition={ setCarouselPosition }
                            carouselPosition={ carouselPosition }
                            // posTransition={ posTransition }
                            setActiveHoverState={ setActiveHoverState }
                            itemRefArray={ itemRefArray }
                            setIndicatorTransitionState={ setIndicatorTransitionState }
                            sliderRef={ sliderRef }
                            sliderContents={ sliderContents }
                            MARGIN_WIDTH={ MARGIN_WIDTH }
                            NET_ITEM_WIDTH={ NET_ITEM_WIDTH }
                            GAP_WIDTH={ GAP_WIDTH }
                            carouselBackup={ carouselBackup }
                            mouseDownHandler={ mouseDownHandler }
                            mouseUpHandler={ mouseUpHandler }
                            carouselVisible={ carouselVisible }
                        // sliderRef={ carouselRef } // Pass the ref here as 'sliderRef'

                        />
                        {/* // turn off for single view? */ }

                        <ArrowContainer
                            arrowDirection={ 'right' }
                            moveFunction={ incCarouselPosition }
                            arrowPosition={ arrowPosition }
                            setArrowPosition={ setArrowPosition }
                            arrowMoveState={ arrowMoveState }
                            setArrowMoveState={ setArrowMoveState }
                            activeHoverState={ activeHoverState }
                            setActiveHoverState={ setActiveHoverState }
                            arrowSliding={ arrowSliding }
                            setArrowSliding={ setArrowSliding }
                            carouselPosition={ carouselPosition }
                            content={ carouselContent.content }
                            itemRefArray={ itemRefArray }
                            mouseIsOver={ mouseIsOver }
                            setIndicatorTransitionState={ setIndicatorTransitionState }
                        />
                    </div>
                    {/* <div className="slide-information" style={ { width: `50%` } }> */ }
                    {/* <div className="slide-information" style={ { width: `${ frameWidth -( 10/100 * window.innerWidth)}` } }> */ }
                    {/* <div className="slide-information" style={ { width: `${ slideDescWidth.current }` } }> */ }
                    {/* <div className="slide-desc-container"> */ }
                    {/* {carouselVisible && ( */ }
                    <div className="slide-information"
                        style={ {
                            //  width: `${ slideDescWidth.current }px` 
                            width: `${ slideDescWidth }px`,

                        } }
                        // onMouseDown={ ( event ) => mouseDownHandler( event ) }
                        // onMouseUp={ ( event ) => mouseUpHandler( event ) }
                        // onTouchStart={ ( event ) => mouseDownHandler( event ) }
                        // onTouchEnd={ ( event ) => mouseUpHandler( event ) }
                        onMouseDown={ ( event ) => descScrollerMouseDown( event ) }
                        onMouseUp={ ( event ) => descScrollerMouseUp( event ) }
                        onTouchStart={ ( event ) => descScrollerMouseDown( event ) }
                        onTouchEnd={ ( event ) => descScrollerMouseUp( event ) }

                    >


                        <div className="left-mobile-arrow" onClick={ decCarouselPosition }>←</div>
                        {/* <div className="left-mobile-arrow"
                            onClick={ decCarouselPosition }
                            style={ { transform: `scale(.7)` } }
                        >

                            <LeftArrow />
                        </div> */}
                        {/* <div className="slide-information" style={ { width: `${ slideInfoWidthState }px,`, fontSize:`${slideDescTextSize}rem` } }> */ }
                        {/* <div className="slide-information" style={ { width: `${ slideDescWidth.current }px` , fontSize:`12px`} }> */ }
                        {/* {(carouselContent.content[carouselPosition].type === 'figmaInteractive') && <div style={{backgroundColor:'rgb(245, 245, 245)', padding:'6px', width:'640px', margin:'auto', borderRadius:"4px/4px", marginTop:'8px'}}><p style={{  }}>⚠️ Figma Interactive ⚠️</p><p style={{fontStyle:'italic'}}>press 'f' to toggle fullscreen, click anywhere to see interactable elements</p></div>} */ }
                        {/* {(carouselContent.content[carouselPosition].type === 'figmaInteractive') && <div style={{backgroundColor:'rgb(245, 245, 245)', padding:'6px', width:'640px', margin:'auto', borderRadius:"4px/4px", marginTop:'8px'}}><p style={{  }}>Figma Interactive</p><p style={{fontStyle:'italic'}}>press 'f' to toggle fullscreen, click anywhere to see interactable elements</p></div>} */ }
                        {/* {(carouselContent.content[carouselPosition].type === 'figmaInteractive') && <div style={{width:'25vw', margin:'auto', marginTop:'16px', border:'1.5px solid rgb(255, 229, 0)', borderRadius:'4px/4px', backgroundColor:'#FFF'}}><p style={{ fontWeight:'bold', }}> Figma Interactive</p><p style={{fontStyle:'italic'}}>press 'f' to toggle fullscreen, click anywhere to see interactable elements</p></div>} */ }
                        {/* {(carouselContent.content[carouselPosition].type === 'figmaInteractive') && <div style={{width:'25vw', margin:'auto', marginTop:'16px', border:'2px dashed rgb(255, 229, 0)', borderRadius:'4px/4px', backgroundColor:'#FFF'}}><p style={{ fontWeight:'bold', }}>Figma Interactive</p><p style={{fontStyle:'italic'}}>press 'f' to toggle fullscreen, click anywhere to see interactable elements</p></div>} */ }
                        {/* {(carouselContent.content[carouselPosition].type === 'figmaInteractive') && <p style={{  color:'rgb(150, 150, 150)', fontWeight:'normal', fontStyle:'italic'}}>press 'f' to toggle fullscreen, click anywhere to see interactable elements</p>} */ }
                        {/* {(carouselContent.content[carouselPosition].type === 'figmaInteractive') && <div style={{backgroundColor:'rgb(245, 245, 245)', padding:'6px',}}><p style={{  }}> Figma Interactive </p><p style={{fontStyle:'italic'}}>press 'f' to toggle fullscreen, click anywhere to see interactable elements</p></div>} */ }
                        {/* {(carouselContent.content[carouselPosition].type === 'figmaInteractive') && <div>  Figma Interactive press 'f' to toggle fullscreen, click anywhere to see interactable elements</div>} */ }

                        {/* <p style={ { display: 'flex', } }> */ }
                        <p
                            className='carousel-information-p'
                        >
                            { ( carouselContent.content[ carouselPosition ].type === 'figmaInteractive' ) &&
                                <p className='figma-instructions'>
                                    Interactive Instructions: press 'f' to toggle fullscreen, click anywhere to see interactable areas
                                </p> }
                 
                            { renderText( carouselContent.content[ carouselPosition ].desc ) }



                        </p>
{/* 
                        <span
                            className='carousel-information-p'
                        >
                            { ( carouselContent.content[ carouselPosition ].type === 'figmaInteractive' ) &&
                                <span className='figma-instructions'>
                                    Interactive Instructions: press 'f' to toggle fullscreen, click anywhere to see interactable areas
                                </span> }
                   
                            { renderText( carouselContent.content[ carouselPosition ].desc ) }




                        </span> */}
                        
                        <div className="right-mobile-arrow" onClick={ incCarouselPosition }>→</div>
                        {/* <div className="right-mobile-arrow"
                            onClick={ incCarouselPosition }
                            style={ { transform: `scale(.7)` } }
                            >
                            <RightArrow />
                        </div> */}
                    </div>



                    {/* </div> */ }
                    {/* // turn off for single view? */ }

                    <div className="slide-indicator-div">
                        <SlideIndicator
                            content={ carouselContent.content }
                            carouselPosition={ carouselPosition }
                            // activeHoverState={ activeHoverState }
                            // activeHoverState={ true }
                            indicatorTransitionState={ indicatorTransitionState }
                        />

                    </div>


                    {/* <div className="carousel-divider"></div> */ }


                </div>

                {/* // turn off for single view? */ }
                <div className="right-side">

                    <div className="thumb-container">
                        <CarouselThumbs
                            content={ carouselContent.content }
                            carouselPosition={ carouselPosition }
                            // instantCarouselPos={ instantCarouselPos }
                            documentScrollState={ documentScrollState }
                            updateCarousel={ updateCarousel }
                            posChangeOriginator={ posChangeOriginator }
                            hasDesc={ carouselContent.sectionDesc }

                        />
                    </div>
                    {/* <div className="page-indicator">

                        <p>{ carouselPosition + 1 } of { carouselContent.content.length }</p>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default Carousel
